import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import styled, { css } from 'styled-components';
import TextInput from '../../atoms/TextInput';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';
import sizes from '../../../js/styles/Sizes';
import { useSubmitContactFormMutation } from '../../../js/store/reducers/api-call';

const FormBlock = styled.div`
  && {
    max-width: ${sizes.sectionContentMaxWidth};
    background: var(--off-white-tint-50);
    border-radius: ${sizes.defaultBorderRadius};
    margin: 0 auto;
    padding: 1rem;

    @media ${devices.desktop} {
      padding: 2rem;
    }
  }
`;

const TextWrapper = styled.div`
  && {
    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '.';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const TextareaWrapper = styled.div`
  && {
    margin-bottom: 1rem;
  }
`;

const TextareaLabel = styled.div`
  && {
    padding-bottom: 0.5rem;
    text-align: left;
  }
`;

const Textarea = styled.textarea`
  && {
    width: 100%;
    border: 2px solid var(--dark-teal-20);
    border-radius: ${sizes.defaultBorderRadius};
    padding: 0.75rem;
  }
`;

const InputWrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;

    @media ${devices.desktop} {
      display: flex;
    }
  }
`;

const inputStyle = css`
  && {
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

const TextInputContactForm = styled(TextInput)`
  && {
    ${inputStyle}
  }
`;

const Button = styled.button`
  && {
    background-image: none;
    border: 0;
    border-radius: ${sizes.defaultBorderRadius};
    color: var(--dark-teal);
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    background: ${(prop) => prop.background};
    color: var(--white);
    font-size: 1rem;
    padding: 1rem;
    width: 100%;
  }
`;

export const ContactFormBlock = (props) => {
  const {
    messageLabel,
    messagePlaceholder,
    nameLabel,
    namePlaceholder,
    emailLabel,
    emailPlaceholder,
    phoneLabel,
    phonePlaceholder,
    buttonLabel,
    successMessage,
    toEmail,
    toName,
  } = props;
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [submitContactForm, {
    isLoading: submitting,
    isSuccess: submitSuccess,
  }] = useSubmitContactFormMutation();

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onPhoneChange = (event) => {
    setPhone(event.target.value);
  };
  // there is no validator and error handler for this
  const handleClick = () => {
    submitContactForm({
      message,
      name,
      email,
      phone,
      toEmail,
      toName,
    })
      .unwrap()
      .catch(({ errorMessage, response, payload }) => {
        Sentry.setTag('origin', 'contactForm');
        Sentry.captureException(errorMessage, {
          stacktrace: true,
          fingerprint: ['submit', 'submitContactForm'],
          extra: {
            error: response,
            payload,
          },
        });
      });
  };

  const submitIsDisabled = () => {
    const emailIsInvalid = emailLabel && !email;
    const phoneIsInvalid = phoneLabel && !phone;
    const nameIsInvalid = nameLabel && !name;
    const messageIsInvalid = messageLabel && !message;

    const fieldsAreInvalid = emailIsInvalid || phoneIsInvalid || nameIsInvalid || messageIsInvalid;

    return (submitting || fieldsAreInvalid);
  };

  return (
    <FormBlock>
      {submitSuccess ? (
        <TextWrapper
          dangerouslySetInnerHTML={createMarkup(successMessage)}
        />
      ) : (
        <>
          {messageLabel && (
            <TextareaWrapper>
              <TextareaLabel>
                {messageLabel}
              </TextareaLabel>
              <Textarea
                id="message"
                rows="6"
                cols="70"
                placeholder={messagePlaceholder}
                onChange={onMessageChange}
              />
            </TextareaWrapper>
          )}
          <InputWrapper>

            {nameLabel && (
              <TextInputContactForm
                id="name"
                type="text"
                label={nameLabel}
                placeholder={namePlaceholder}
                onChange={onNameChange}
                contactForm
              />
            )}
            {emailLabel && (
              <TextInputContactForm
                id="email"
                type="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                onChange={onEmailChange}
                contactForm
              />
            )}

            {phoneLabel && (
              <TextInputContactForm
                id="phonenumber"
                type="tel"
                label={phoneLabel}
                placeholder={phonePlaceholder}
                onChange={onPhoneChange}
                contactForm
              />
            )}
          </InputWrapper>
          <Button
            role="button"
            background={
              submitIsDisabled() ? 'var(--light-grey)' : 'var(--teal)'
            }
            type="submit"
            onClick={() => handleClick()}
            disabled={submitIsDisabled()}
          >
            {buttonLabel}
          </Button>
        </>
      )}
    </FormBlock>
  );
};

ContactFormBlock.propTypes = {
  messageLabel: PropTypes.string,
  messagePlaceholder: PropTypes,
  nameLabel: PropTypes.string,
  namePlaceholder: PropTypes,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  phoneLabel: PropTypes.string,
  phonePlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  successMessage: PropTypes.string,
  toEmail: PropTypes.string,
  toName: PropTypes.string,
};

ContactFormBlock.defaultProps = {
  messageLabel: '',
  messagePlaceholder: '',
  nameLabel: '',
  namePlaceholder: '',
  emailLabel: '',
  emailPlaceholder: '',
  phoneLabel: '',
  phonePlaceholder: '',
  buttonLabel: '',
  successMessage: '',
  toEmail: null,
  toName: null,
};

export default ContactFormBlock;
