import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import messageDescriptorSchema from '../../js/schemas/message-descriptor';
import InfoCircle from '../../js/images/info-circle.svg';

const Wrapper = styled.div`
border-bottom: 1px solid #fff;
margin-top: 1rem;
margin-bottom: 1rem;

${(props) => props.padding
  && `
    padding: 0.5rem;
  `}
`;

const Label = styled.dt`
  font-size: 14px;
`;

const Value = styled.dd`
  font-size: 16px;
`;

const InfoIcon = styled(InfoCircle)`
  fill: blue;
  font-size: 16px;
`;

const DefinitionList = styled.dl`
  display: flex;
`;

const TooltipWrapper = styled.span`
  position: relative;
  display: inline-block;

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 0.25rem;
    border-radius: 4px;
    font-size: 12px;
  }
`;

const LabelValueRow = ({
  label,
  infoTooltip,
  children,
  padding,
}) => (
  <Wrapper padding={padding}>

    <DefinitionList>
      <Label>
        {label}
        {infoTooltip && (
        <TooltipWrapper data-tooltip={infoTooltip}>
          <InfoIcon width="26" />
        </TooltipWrapper>
        )}
      </Label>
      <Value>{children}</Value>
    </DefinitionList>
  </Wrapper>
);

LabelValueRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  infoTooltip: messageDescriptorSchema,
  padding: PropTypes.bool,
};

LabelValueRow.defaultProps = {
  infoTooltip: null,
  padding: false,
};

export default LabelValueRow;
