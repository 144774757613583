import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import localeData from '../../../js/localizations/locale-data';
import ImageBlock from '../ImageBlock';
import devices from '../../../js/styles/Devices';

const Block = styled.div`
  && {
    text-align: center;

    @media ${devices.upFromTablet} {
      text-align: inherit;
    }
  }
`;

const BaseText = styled.div`
  && {
    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '.';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const Text = styled(BaseText)`
  && {
    text-align: inherit;

    @media ${devices.downFromSmallTablet} {
      display: none;
    }
  }
`;

const Image = styled.div`
  && {
    position: relative;
    width: calc(100% + 30px);
    margin-bottom: 1rem;
    margin-left: -1rem;

    @media ${devices.upFromTablet} {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const ImageSrc = styled.img`
  && {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const ImageBlockSrc = styled(ImageBlock)`
  && {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Cost = styled.span`
  && {
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, $black 0%, transparent 100%);
    color: var(--white);
    font-size: 1.3rem;
    line-height: 3rem;
    padding: 0 1rem;
  }
`;

const TextBelowImage = styled(BaseText)`
  && {
    margin: 1em 0 2em;

    img {
      max-width: 100%;
      height: auto;
    }

    @media ${devices.upFromTablet} {
      margin-bottom: 0;
    }
  }
`;

const PartnerProductBlock = ({
  text,
  image,
  textBelowImage,
  queryParams,
}) => {
  const {
    objTitle,
    objImg,
    objCost,
    objText,
  } = queryParams;

  return (
    <Block>
      {objTitle && (
        <h3>{objTitle}</h3>
      )}

      {objText && (
      <Text>{objText}</Text>
      )}

      {text && (
        <Text
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      )}

      <Image>
        {objImg ? (
          <ImageSrc
            src={objImg}
            alt={objTitle || ''}
          />
        ) : (
          <ImageBlockSrc
            imageObject={image}
          />
        )}
      </Image>

      {objCost && objImg && (
        <Cost>
          <FormattedMessage
            id="partnerProduct.price"
            defaultMessage="Price: <strong>{price, number, currency}</strong>"
            description="Text for price on partner product"
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
              price: objCost,
              currency: localeData.CURRENCY,
            }}
          />
        </Cost>
      )}

      {textBelowImage && (
        <TextBelowImage
          dangerouslySetInnerHTML={createMarkup(textBelowImage)}
        />
      )}
    </Block>

  );
};

PartnerProductBlock.propTypes = {
  text: PropTypes.string,
  image: ImageBlock.propTypes.imageObject.isRequired,
  textBelowImage: PropTypes.string,
  queryParams: PropTypes.shape({
    objTitle: PropTypes.string,
    objImg: PropTypes.string,
    objCost: PropTypes.string,
    objText: PropTypes.string,
  }),
};

PartnerProductBlock.defaultProps = {
  text: '',
  textBelowImage: '',
  queryParams: {},
};

export default PartnerProductBlock;

export const partnerProductBlockQuery = graphql`
  fragment partnerProductBlockFields on PartnerProduct {
    text
    media {
      ...imageBlockFieldsWithoutImage
      image {
        node {
          gatsbyImage(width: 800, quality: 90, layout: CONSTRAINED)
        }
      }
    }
    textBelowImage
  }
`;
