export default {
  fill: '%23df202d',
  uspJustifyContent: 'center',
  marginLeft: '0',
  lineHeight: '1.3rem',
  uspFontSize: 'var(--text-font-size)',
  uspFontWeight: '400',
  iconColor: 'var(--red)',
  samblaStyleUSPDisplay: 'none',
  prefixIconDisplay: 'block',
  suffixIconDisplay: 'none',
};
