import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';
import SvgIcon from '../../atoms/SvgIcon';

const CallUssButton = styled.a`
  && {
    color: ${(props) => props.phoneNumberColor};
    text-decoration: none;
  }
`;

const CallUsBlockStyled = styled.div`
  && {
    text-align: center;

    @media ${devices.upFromMobile} {
      text-align: left;
    }
  }
`;

const TextStyled = styled.p`
  && {
    max-width: 25rem;
    margin: 0;

    @media ${devices.upFromMobile} {
      margin: 0, auto;
    }
  }
`;

const Icon = styled(SvgIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const CallUsBlock = ({
  phoneNumber,
  text,
  phoneNumberColor,
}) => (
  <CallUsBlockStyled>
    <h2>
      <CallUssButton phoneNumberColor={phoneNumberColor} href={`tel:${phoneNumber}`}>
        <Icon
          icon={['fas', 'phone-alt']}
          size="sm"
        />
        {phoneNumber}
      </CallUssButton>
    </h2>
    {text && (
    <TextStyled>
      {text}
    </TextStyled>
    )}
  </CallUsBlockStyled>
);

CallUsBlock.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberColor: PropTypes.string.isRequired,
  text: PropTypes.string,
};

CallUsBlock.defaultProps = {
  text: null,
};

export default CallUsBlock;

export const callUsBlockQuery = graphql`
  fragment callUsBlockFields on CallUs {
    phoneNumber
    text
    phoneNumberColor
  }
`;
