import React from 'react';
import { SubmissionError } from 'redux-form';
import { region } from '../../localizations/current-locale';
import logger from '../../utils/logger';
import { dynamicBrand } from '../../utils/brand';

let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const submitApplicationError = () => (err) => {
  logger.info('Could not submit application', {
    response: err.response,
  });

  const {
    details,
  } = err;
  const error = details?.error || {};
  const errors = {};

  if (error.name === 'ApplicantHasRecentApplicationError') {
    const redirectUrl = `${process.env.REACT_APP_CUSTOMER_PORTAL_URL}/log-in`;
    // eslint-disable-next-line react/jsx-filename-extension
    const WebsiteLink = (chunks) => <a href={redirectUrl}>{chunks}</a>;
    // eslint-disable-next-line no-underscore-dangle
    errors._error = {
      message: messages.duplicateApplicationErrorMessage,
      errorName: error.name,
      values: {
        a: WebsiteLink,
      },
    };
  } else {
    // eslint-disable-next-line no-underscore-dangle
    errors._error = {
      message: messages.generalSubmitError,
      errorName: 'GeneralSubmitError',
    };
  }

  throw new SubmissionError(errors);
};

export default submitApplicationError;
