import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import HeroBlock from '../../blocks/HeroBlock';
import FormBlock from '../../blocks/FormBlock';
import withSquishing from '../../utils/withSquishing';
import devices from '../../../js/styles/Devices';

const Section = styled.section`
  && {
    min-height: 14rem;
  }
`;

const HideDesktop = styled.div`
  && {
    @media ${devices.upFromTablet} {
      display: none;
    }
  }
`;

const HideMobile = styled.div`
  && {
    @media ${devices.downFromSmallTablet} {
      display: none;
    }
  }
`;

const HeroFormWrapper = styled.div`
  && {
    margin-top: -6rem;
    padding-top: 3.5rem;

    @media ${devices.upFromTablet} {
      margin-top: -13.65rem;
    }
  }
`;

const Container = styled.div`
  && {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (min-width: 540px) {
      max-width: 576px;
    }

    @media (min-width: 720px) {
      max-width: 768px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1200px;
    }
  }
`;

const HeroSection = ({
  acf,
}) => {
  const heros = {
    desktop: {
      title: acf.desktopHero.title,
      subtitle: acf.desktopHero.subtitle,
      backgroundImage: acf.desktopHero.backgroundImage,
    },
    mobile: {
      title: acf.mobileHero.title,
      subtitle: acf.mobileHero.subtitle,
      backgroundImage: acf.mobileHero.backgroundImage,
    },
  };

  return (
    <Section>
      <HideDesktop>
        <HeroBlock
          mobile={heros.mobile}
          style={{ color: acf.textColor }}
        />
      </HideDesktop>
      <HideMobile>
        <HeroBlock
          desktop={heros.desktop}
          style={{ color: acf.textColor }}
        />
      </HideMobile>

      {acf.form.show && (
        <Container>
          <HeroFormWrapper>
            <FormBlock
              {...acf.form}
              floating
            />
          </HeroFormWrapper>
        </Container>
      )}
    </Section>
  );
};

HeroSection.propTypes = {
  acf: PropTypes.shape({
    mobileHero: PropTypes.shape(HeroBlock.propTypes).isRequired,
    desktopHero: PropTypes.shape(HeroBlock.propTypes).isRequired,
    textColor: PropTypes.string,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
  }).isRequired,
};

export default withSquishing(HeroSection);
