import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import TrustBox from './TrustBox';

const TrustpilotWidget = styled.div`
  background-color: #12273E;
  padding: 0.5rem 0;
  visibility: hidden;
  pointer-events: none;
`;

const TrustpilotBlock = ({
  acfTrustpilot,
}) => (
  <TrustBox
    acfTrustpilot={acfTrustpilot}
  />
);

TrustpilotBlock.propTypes = {
  acfTrustpilot: TrustBox.propTypes.acfTrustpilot.isRequired,
};

export const TrustpilotAdvisaSmallWidget = () => (
  <TrustpilotWidget
    data-widget-id="TP-test"
    data-locale="sv-SE"
    data-template-id="5419b6ffb0d04a076446a9af"
    data-businessunit-id="5310564d0000640005784d78"
    data-style-height="20px"
    data-style-width="100%"
    data-theme="dark"
    data-font-family="Lexend"
  />
);

export default TrustpilotBlock;

export const TrustpilotBlockQuery = graphql`
  fragment trustpilotBlockFields on Trustpilot {
    stars
    showRatingSchema
    productName
  }
`;
