import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import devices from '../../../js/styles/Devices';
import SvgIcon from '../../atoms/SvgIcon';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

const NotificationWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  max-height: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;

  @media ${devices.downFromTablet} {
    max-height: 14rem;
  }
`;

const CloseButtonWrapper = styled.button`
  position: absolute;
  right: 5rem;
  top: 0.3rem;
  cursor: pointer;
  border: none;
  background: transparent;
  color: var(--black);

  && svg {
    height: 1.3rem;
  }

  @media ${devices.downFromSmallTablet} {
    right: 1rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
`;

const DropdownToggleWrapper = styled.div`
  cursor: pointer;
`;

const NotificationText = styled.div`
  display: ${(props) => (props.dropdown ? 'none' : 'block')};
  max-width: 85%;
  text-align: center;
  line-height: 1.2;
  && p {
    font-size: 0.85rem;
  }

  @media ${devices.downFromLargeTablet} {
    max-width: 70%;
  }
`;

const NotificationSection = memo(({ acf }) => {
  const {
    title, text, dropdown, backgroundColor, customBackgroundColor,
  } = acf;
  const [isNotificationClosed, setIsNotificationClosed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(title !== null ? dropdown : false);
  const handleCloseNotification = () => setIsNotificationClosed(true);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  if (isNotificationClosed) return null;

  return (
    <NotificationWrapper backgroundColor={backgroundColor[0] !== 'other' ? backgroundColor[0] : customBackgroundColor}>
      <CloseButtonWrapper onClick={handleCloseNotification}>
        <SvgIcon icon={['fal', 'times']} />
      </CloseButtonWrapper>
      {title
      && (
      <TitleWrapper>
          {title}
        {dropdown
        && (
          <DropdownToggleWrapper onClick={toggleDropdown}>
            <SvgIcon icon={isDropdownOpen ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']} />
          </DropdownToggleWrapper>
        )}
      </TitleWrapper>
      )}
      <NotificationText
        dropdown={isDropdownOpen}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    </NotificationWrapper>

  );
});

NotificationSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    dropdown: PropTypes.bool,
    backgroundColor: PropTypes.arrayOf(PropTypes.string),
    customBackgroundColor: PropTypes.string,
  }).isRequired,
};

export default NotificationSection;
