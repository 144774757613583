import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';

import SectionWrapper from '../../common/SectionWrapper';
import LinkCardCardBlock from '../../blocks/LinkCardCardBlock';
import style from './style';
import useDevice from '../../../js/utils/use-device';

const Wrapper = styled(SectionWrapper)`
  && {
    @media ${devices.mobile} {
      padding-left:  1rem;
      padding-right:  1rem;
    }
  }
`;

const SuperTitle = styled.p`
  && {
    font-family: var(--font-family);
    color: ${(props) => props.titleColor};
    text-align: ${(props) => props.position};
    font-size: var(--h3-desktop-font-size);
    font-weight: var(--has-font-weight);
    opacity: .8;
    padding-bottom: 0;

    @media ${devices.downFromTablet} {
      font-size: var(--h3-mobile-font-size);
    }
  }
`;

const Title = styled.p`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h2-desktop-font-size);
    letter-spacing: var(--has-letter-spacing);
    color: ${(props) => props.titleColor};
    margin-bottom: 1.5rem;
    margin-top: 0;

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
      margin-bottom: 0.8rem;
    }
  }
`;

const GridWrapper = styled.div`
  && {
    max-width: ${style.gridMaxWidt};
    margin-left: ${style.gridMargin};
    margin-right: ${style.gridMargin};
    padding: 0 0rem 0;
  }
`;

const Grid = styled.div`
  && {
    display: grid;

    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: ${(props) => (props.centered ? 'repeat(auto-fill, minmax(49%, auto))' : style.gridTemplateDesktop)};
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};

    @media ${devices.downFromDesktop} {
      grid-template-columns: ${(props) => (props.centered ? 'repeat(auto-fill, minmax(100%, auto))' : style.gridTemplateMobile)};
    }

    @media ${devices.mobile} {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
  }
`;

const LinkCardsSection = ({
  acf,
}) => {
  const { isTablet } = useDevice();
  const { fontFamily, titlePosition, titleColor } = acf.sectionSettings;
  return (
    <Wrapper
      settings={acf.sectionSettings}
      padding={isTablet ? '1rem' : '2.5rem 4rem'}
      noContainer
    >
      <GridWrapper>
        {acf.superTitle && (
        <SuperTitle
          position={titlePosition}
          titleColor={titleColor}

        >
          {acf.superTitle}
        </SuperTitle>
        )}
        {acf.title && (
        <Title
          position={titlePosition}
          titleColor={titleColor}
        >
          {acf.title}
        </Title>
        )}
        <Grid centered={acf.linkCards.length < 3}>
          {acf.linkCards.map((card, key) => (
            <LinkCardCardBlock
              cardData={card}
              fontFamily={fontFamily}
              key={key}
            />
          ),
          )}
        </Grid>
      </GridWrapper>
    </Wrapper>
  );
};

LinkCardsSection.propTypes = {
  acf: PropTypes.shape({
    superTitle: PropTypes.string,
    title: PropTypes.string,
    linkCards: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ).isRequired,
    sectionSettings: PropTypes.shape(),
  }),
};

LinkCardsSection.defaultProps = {
  acf: {},
};

export default LinkCardsSection;
