import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';

import { useSelector } from 'react-redux';
import LOAN_LIMITS from '../../../js/localizations/config/SE/shared/loan-limits';
import { MERGE_LOAN_FORM_DEFAULT_VALUES } from '../../../js/localizations/config/SE/advisa/form-default-values';
import { loadState } from '../../../js/utils/form-state';
import FormTypes from '../../../libs/constants/form-types';
import submitApplicationErrorHandler from '../../../js/api/handlers/submit-application-error';
import stripNonNumeric from '../../../js/utils/strip-non-numeric';
import localeData, { LOAN } from '../../../js/localizations/locale-data';
import submitApplicationSuccessHandler from '../../../js/api/handlers/submit-application-success';
import { useOptions } from '../../../context/options';
import { useOnSubmitApplicationMutation, useOnSubmitMortgageApplicationMutation } from '../../../js/store/reducers/api-call';

const { ApplicationForm } = localeData.COMPONENTS;

const Form = ({
  variables,
  prefilledValues,
  type,
  location: windowLocation,
  campaigns,
  partnerForm,
  prefilledApplicationForm,
  ...rest
}) => {
  const queryParams = queryString.parse(windowLocation.search);

  const formState = useSelector((state) => state.form.application);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [onSubmitApplication] = useOnSubmitApplicationMutation();
  const [onSubmitMortgageApplication] = useOnSubmitMortgageApplicationMutation();

  const loan = formState?.values || {};

  const persistedFormData = loadState('application-form');

  let formDefaultValues;

  switch (type) {
    case FormTypes.MERGE_LOAN:
      formDefaultValues = localeData.MERGE_LOAN_FORM_DEFAULT_VALUES;
      break;

    case FormTypes.LOAN_CALCULATOR:
      formDefaultValues = localeData.LOAN_CALCULATOR_DEFAULT_VALUES;
      break;

    case FormTypes.MORTGAGE_LOAN:
      formDefaultValues = localeData.MORTGAGE_FORM_DEFAULT_VALUES;
      break;

    default:
      formDefaultValues = localeData.MERGE_LOAN_FORM_DEFAULT_VALUES;
  }

  const objCost = stripNonNumeric(queryParams.objCost) > LOAN.UP_TO
    ? LOAN.UP_TO
    : stripNonNumeric(queryParams.objCost);

  const prefillParams = queryParams && typeof queryParams.prefill !== 'undefined' && queryParams.prefill !== null ? JSON.parse(atob(queryParams?.prefill?.replaceAll('_', '/').replaceAll('-', '+'))) : null;

  const getRepaymentTime = () => {
    if (!!prefillParams && prefillParams?.repaymentTime && prefillParams.repaymentTime != null) {
      return prefillParams.repaymentTime / 12;
    }
    if (queryParams?.repaymentTime) {
      if (
        typeof queryParams.repaymentTime !== 'number'
        && typeof queryParams.repaymentTime !== 'string'
      ) {
        return MERGE_LOAN_FORM_DEFAULT_VALUES.repaymentTime;
      }
      const repaymentTime = parseInt(queryParams.repaymentTime, 10);
      if (repaymentTime > 20) {
        return 20;
      } if (repaymentTime < 1) {
        return 1;
      }
      return repaymentTime;
    }
    if (persistedFormData?.repaymentTime) {
      if (persistedFormData.repaymentTime > 20) {
        return persistedFormData.repaymentTime / 12;
      }
      return persistedFormData.repaymentTime;
    }
    return formDefaultValues.repaymentTime;
  };

  const getTotalAmount = () => {
    if (!!prefillParams && prefillParams?.loanAmount && prefillParams.loanAmount != null) {
      return prefillParams.loanAmount;
    }
    if (queryParams?.totalAmount) {
      if (
        typeof queryParams.totalAmount !== 'number'
        && typeof queryParams.totalAmount !== 'string'
      ) {
        return MERGE_LOAN_FORM_DEFAULT_VALUES.totalAmount;
      }
      const totalAmount = parseInt(queryParams.totalAmount, 10);
      if (totalAmount > LOAN_LIMITS.UP_TO) {
        return LOAN_LIMITS.UP_TO;
      } if (totalAmount < LOAN_LIMITS.FROM) {
        return LOAN_LIMITS.FROM;
      }
      return totalAmount;
    }

    return (prefilledValues || {}).totalAmount
    || objCost
    || (persistedFormData && persistedFormData.totalAmount)
    || formDefaultValues.totalAmount;
  };

  const getAcceptNewsletter = () => persistedFormData?.acceptNewsletter
    || formDefaultValues.acceptNewsletter;

  const getPhoneNumber = () => {
    if (!!prefillParams && prefillParams?.phone && prefillParams.phone != null) {
      return prefillParams.phone;
    }

    if (Object.keys(prefilledValues).length > 0 && prefilledValues?.applicant) {
      return prefilledValues.applicant.phoneNumber;
    }

    if (persistedFormData
      && Object.keys(persistedFormData).length > 0 && persistedFormData?.applicant) {
      return persistedFormData.applicant.phoneNumber;
    }

    return null;
  };

  const getEmail = () => {
    if (!!prefillParams && prefillParams?.email && prefillParams.email != null) {
      return prefillParams.email;
    }

    if (Object.keys(prefilledValues).length > 0 && prefilledValues?.applicant) {
      return prefilledValues.applicant.email;
    }

    if (persistedFormData
      && Object.keys(persistedFormData).length > 0 && persistedFormData?.applicant) {
      return persistedFormData.applicant.email;
    }
    return null;
  };

  const initialFormValues = {
    ...persistedFormData,
    ...deepmerge(formDefaultValues, prefilledValues),
    totalAmount: getTotalAmount(),
    repaymentTime: getRepaymentTime(),
    acceptNewsletter: getAcceptNewsletter(),
    applicant: {
      ...persistedFormData?.applicant,
      ...deepmerge(formDefaultValues, prefilledValues).applicant,
      phoneNumber: getPhoneNumber(),
      email: getEmail(),
    },
    emailZeroBounceStatus: 'READY',
  };

  if (typeof initialFormValues?.applicant !== 'undefined') {
    initialFormValues.applicant = {
      ...initialFormValues.applicant,
      nationalId: null,
    };
  }

  if (typeof initialFormValues?.coApplicant !== 'undefined') {
    initialFormValues.coApplicant = {
      ...initialFormValues.coApplicant,
      nationalId: null,
    };
  }

  const submit = (...args) => {
    const submitMethod = type === FormTypes.MORTGAGE_LOAN
      ? onSubmitMortgageApplication
      : onSubmitApplication;
    return submitMethod(args)
      .unwrap()
      .then(submitApplicationSuccessHandler({
        formType: type,
        loan,
        setSubmitSuccess,
      }))
      .catch(({ errorMessage, response, payload }) => {
        Sentry.setTag('origin', 'submitApplication');

        let fingerprint;

        if (errorMessage.includes('Applicant with nationalId')) {
          fingerprint = ['submit', 'previousApplication'];
        } else {
          fingerprint = ['submit', 'onSubmitApplication'];
        }

        Sentry.captureException(errorMessage, {
          stacktrace: true,
          fingerprint,
          extra: {
            error: response,
            payload,
          },
        });
        return Promise.reject(response.data);
      })
      .catch(submitApplicationErrorHandler({ formType: type, formState }));
  };

  return (
    <>
      <ApplicationForm
        form="application"
        submitSuccess={submitSuccess}
        initialValues={initialFormValues}
        destroyOnUnmount={false}
        onSubmit={submit}
        type={type}
        campaigns={campaigns}
        partnerForm={partnerForm}
        prefilledApplicationForm={prefilledApplicationForm}
        {...rest}
      />
    </>
  );
};

Form.propTypes = {
  formState: PropTypes.shape(),
  onSubmitApplication: PropTypes.func,
  variables: PropTypes.shape().isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      info: PropTypes.string,
    }),
  ),
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  type: PropTypes.string,
  location: PropTypes.shape().isRequired,
};

Form.defaultProps = {
  formState: {},
  onSubmitApplication: () => {},
  campaigns: undefined,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  type: FormTypes.MERGE_LOAN,
};

export default ((props) => {
  const {
    optionVariables,
    optionCampaigns,
  } = useOptions();

  return (
    <Form
      {...props}
      variables={optionVariables}
      campaigns={optionCampaigns.campaigns}
    />
  );
});

export { Form };
