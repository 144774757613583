import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import sizes from '../../../js/styles/Sizes';

import intlShape from '../../../js/schemas/intl';
import LabelValueRow from '../../atoms/LabelValueRow';

const Card = styled.div`
  && {
    cursor: move;
    margin: 5px 0.5rem;
    padding: 1rem;
    padding-top: 0;
    box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
    background: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: ${sizes.defaultBorderRadius};
    user-select: none;
  }
`;

const Row = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    font-weight: bold;
    white-space: nowrap;

    * {
      margin-bottom: 0;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }
`;
const Header = styled.div`
  && {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    font-weight: bold;
    padding: 0.5rem;
  }
`;

const baseDotStyle = css`
  && {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
`;

const DotRed = styled.div`
  && {
    background: #ff3926;
    ${baseDotStyle}
  }
`;
const DotGreen = styled.div`
  && {
    background: #76d788;
    ${baseDotStyle}
  }
`;

const messages = defineMessages({
  amount: {
    id: 'currentCompare.loanAmount',
    defaultMessage: 'Application:',
    description: 'Left side label for current compare card amount',
  },
  max: {
    id: 'currentCompare.max.interestRate',
    defaultMessage: 'Highest interest rate:',
    description: 'Left side label for current compare card max',
  },
  min: {
    id: 'currentCompare.min.interestRate',
    defaultMessage: 'Lowest interest rate:',
    description: 'Left side label for current compare card min',
  },
  savings: {
    id: 'currentCompare.amountToSave',
    defaultMessage: 'To save:',
    description: 'Left side label for current compare card savings',
  },
});

const CurrentCompareCardBlock = ({ cardData, intl: { formatMessage } }) => (
  <Card>
    <Header>{cardData.title}</Header>
    <Row>
      <LabelValueRow label={formatMessage(messages.amount)} />
      {cardData.amount}
    </Row>
    <Row>
      <LabelValueRow label={formatMessage(messages.max)} />
      <div>
        {cardData.max}
        <DotRed />
      </div>
    </Row>
    <Row>
      <LabelValueRow label={formatMessage(messages.min)} />
      <div>
        {cardData.min}
        <DotGreen />
      </div>
    </Row>
    <Row>
      <LabelValueRow label={formatMessage(messages.savings)} />
      {cardData.savings}
    </Row>
  </Card>
);

CurrentCompareCardBlock.propTypes = {
  cardData: PropTypes.shape({
    title: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
    amount: PropTypes.string,
    savings: PropTypes.string,
  }),
  intl: intlShape.isRequired,
};

CurrentCompareCardBlock.defaultProps = {
  cardData: {},
};

export default injectIntl(CurrentCompareCardBlock);

export const currentCompareCardBlockQuery = graphql`
  fragment CurrentCompareCardBlockFields on Card {
    title
    max
    min
    amount
    savings
  }
`;
