const hasViewportChanged = (prevProps, nextProps) => {
  const {
    windowWidth: oldUWindowWidth,
    windowLocationSlug: oldWindowLocationSlug,
    formHeight: oldFormHeight,
  } = prevProps;
  const {
    windowWidth,
    windowLocationSlug,
    formHeight,
  } = nextProps;
  if (
    oldUWindowWidth !== windowWidth
    || oldWindowLocationSlug !== windowLocationSlug
    || oldFormHeight !== formHeight
  ) {
    return true;
  }
  return false;
};

export default hasViewportChanged;
