/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import Sections from '../Sections/Sections';

const Templates = ({ acfTemplates, location }) => (
  <>
    {acfTemplates.map((template) => {
      const sortOrder = template.sortOrder.split(/\s*,\s*/);
      const sections = sortOrder.map((sectionName) => {
        if (!sectionName.startsWith('_')) {
          if (!template[sectionName]) {
            throw Error(`Could not find section ${sectionName}`);
          }
          return {
            ...template[sectionName],
            __typename: `WpAcfSections${sectionName[0].toUpperCase() + sectionName.slice(1)}`,
            id: `${template.__typename}_${sectionName}`,
          };
        }
        return null;
      }).filter(Boolean);

      return (
        <Sections
          acfGroups={sections}
          key={template.__typename}
          location={location}
        />
      );
    })}
  </>
);

Templates.propTypes = {
  acfTemplates: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.shape().isRequired,
};

Templates.defaultProps = {
  acfTemplates: [],
};

export default Templates;
