import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';
import SectionWrapper from '../../common/SectionWrapper';
import HappyCustomerBlock from '../../blocks/HappyCustomerBlock';
import withSquishing from '../../utils/withSquishing';
import style from './style';

const Container = styled.div`
  && {
    flex-wrap: wrap;

    @media ${devices.upFromSmallTablet} {
      position: ${style.positionCont};
      display: ${style.displayContainer};
      flex-wrap: wrap;
      justify-content:${style.justifyContent};
      grid-template-columns: ${style.gridTempCol};
      grid-gap: 1rem;

    }
    @media ${devices.downFromSmallTablet} {
      grid-template-columns: ${style.gridTempColTab};
      display: ${style.displayContainer};
      grid-gap: 1rem;
    }
    @media ${devices.mobile} {
      grid-template-columns: ${style.gridTempColMobile};
      grid-gap: 1rem;
      display: ${style.displayContainer};
    }
  }
`;

const Title = styled.p`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h2-desktop-font-size);
    width: 100%;
    margin-bottom: 0.5rem;

    @media ${devices.downFromTablet} {
      font-size: var(--h2-desktop-font-size);
    }
  }
`;

const HappyCustomerSection = ({ acf }) => {
  const {
    fontFamily,
    titlePosition,
  } = acf.sectionSettings;

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
    >
      <Container>
        {acf.title && (
        <Title
          position={titlePosition}
        >
          {acf.title}
        </Title>
        )}
        {acf.customers?.length > 0
        && acf.customers.map((customer, key) => (
          <HappyCustomerBlock
            key={`customer-${key}`}
            fontFamily={fontFamily}
            {...customer}
            happyCustomerTextColor={acf.happyCustomerTextColor}
            happyCustomerTextTitleColor={acf.happyCustomerTextTitleColor}
            happyCustomerTextBackgroundColor={acf.happyCustomerTextBackgroundColor}
            happyCustomerTitleBackgroundColor={acf.happyCustomerTitleBackgroundColor}
          />
        ))}
      </Container>
    </SectionWrapper>
  );
};

HappyCustomerSection.propTypes = {
  acf: PropTypes.shape({
    happyCustomerTextBackgroundColor: PropTypes.string,
    happyCustomerTextColor: PropTypes.string,
    happyCustomerTextTitleColor: PropTypes.string,
    happyCustomerTitleBackgroundColor: PropTypes.string,
    title: PropTypes.string,
    customers: PropTypes.arrayOf(PropTypes.shape(HappyCustomerBlock.propTypes)).isRequired,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

export default withSquishing(HappyCustomerSection);
