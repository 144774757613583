import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Whitelist is a way to load certain icons that should not "blink" above the fold
// so all icons in whitelist is simply always loaded.

// import iconWhitelist from './whitelist';

// Until loadable will be activated again, we are exporting all icons.
// Simply replace this import with whitelist and loadable instead.
import icons from './icons';

// iconWhitelist.forEach((icon) => {
//   import(`./icons/${icon}`);
// });

const SvgWrapper = styled.svg`
display: inline-block;
font-size: inherit;
overflow: visible;

${({ size }) => {
    switch (size) {
      case 'sm':
        return `
        width: 1em;
        height: 1em;
        font-size: 0.875em;
      `;
      case 'sm-md-mobile':
        return `
          width: 1.75em;
          height: 1.75em;
        `;
      case 'sm-md-desktop':
        return `
          width: 1.5em;
          height: 1.5em;
        `;
      case 'md':
        return `
        width: 2em;
        height: 2em;
      `;
      case 'lg':
        return `
        width: 1em
        height: 1em
      `;
      default:
        return '';
    }
  }}
`;

const SvgIcon = ({
  icon,
  size,
}) => {
  let iconName = icon;
  if (typeof icon === 'object') {
    iconName = icon.join('-');
  }
  // Temp solution while we don't have camelcase values returned from the CMS
  // i.e. replacing test-icon-stuff with testIconStuff
  iconName = iconName.replace(/[/-][a-z]/g, (match) => match.toUpperCase()[1]).replace(/[/-]/, '');

  if (!iconName) return null; // because sometimes iconName is empty string and that breaks loadable

  // Previous solution with loadable caused chunk-errors that gave users site-wide crash in some
  // cases so this is removed for now.

  // const IconPath = loadable((props) => import(`./icons/${props.icon}`), {
  //   fallback: <path />,
  // });

  return (
    <SvgWrapper
      xmlns="https://www.w3.org/2000/svg"
      size={size}
      preserveAspectRatio="xMinYMax slice"
      viewBox="0 0 640 512"
      aria-hidden="true"
      focusable="false"
      role="img"
      fill="yellow"
    >
      {icons[iconName]()}
    </SvgWrapper>
  );
};

SvgIcon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

SvgIcon.defaultProps = {
  size: 'sm',
};

export default SvgIcon;
