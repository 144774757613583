import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import { screenWidthLg } from '../../../libs/constants/Sizes';

import ImageBlock from '../ImageBlock';
import devices from '../../../js/styles/Devices';
import useDevice from '../../../js/utils/use-device';

const Block = styled.div`
  && {
    background-color: inherit;
    text-align: center;
  }
`;

const BackgroundImage = styled(ImageBlock)`
  && {
    position: absolute;
    width: 100%;
    height: 14rem;
    background-position: center bottom 0;
    background-size: auto;

    &:not(:first-child) {
      visibility: hidden;
    }

    @media ${devices.upFromTablet} {
      height: 35.5rem;
    }

    @media ${devices.tablet} {
      height: 20rem;
      margin-top: 3rem;
    }
  }
`;

const TitleOverlay = styled.h1`
  && {
    position: absolute;
    letter-spacing: -.02rem;
    bottom: 0;
    left: 0;
    max-width: 50%;
    color: var(--white);
    font-size: 4rem;
    text-align: left;
    text-indent: 0.5em;

    @media ${devices.upFromMobile} {
      max-width: 45%;
    }
    @media ${devices.downFromTablet}{
      display: none;
    }
  }
`;

const Container = styled.div`
  && {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    height: 14rem;
    padding-bottom: 2.5rem;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media ${devices.upFromTablet} {
      flex-direction: column;
      height: 35.5rem;
      padding-top: 4rem;
    }

    @media (min-width: 34rem) {
      max-width: 36rem;
    }

    @media (min-width: 45rem) {
      max-width: 48rem;
    }

    @media (min-width: 62rem) {
      max-width: 60rem;
    }

    @media (min-width: 75rem) {
      max-width: 75rem;
    }
  }
`;

const Title = styled.h1`
  && {
    color: var(--black);
    margin-top: 1rem;
    font-family: var(--title-font);

    @media ${devices.upFromTablet} {
      padding-top: 1.5em;
    }

    @media ${devices.downFromSmallTablet} {
      background: ${(props) => (props.fade ? 'linear-gradient(rgb(230, 239, 243), rgb(251, 252, 253), var(--white)' : 'none')};
    }
  }
`;

const Subtitle = styled.h2`
  && {
    display: none;
    font-weight: normal;

    @media ${devices.upFromTablet} {
      display: block;
    }
  }
`;

const OutsideTitle = styled.h1`
  && {
    background-color: var(--white);
    font-size: 2.5rem;
    line-height: 1;
    margin-top: 0;
    padding: 0.4em 0.5em 0.7em;
    text-align: center;
    @media ${devices.downFromSmallTablet} {
      background: var(--white);
    }
    @media ${devices.tablet} {
      margin-top: 9rem;
    }
  }
`;

const defaultGatsbyImageProps = {
  objectPosition: 'center',
  objectFit: 'cover',
  loading: 'eager',
};

const HeroBlock = ({
  desktop,
  mobile,
  style,
  titleOutside,
  fadingBackground,
}) => {
  const { isTablet } = useDevice();
  if (!desktop && !mobile) {
    return null;
  }

  const {
    title,
    subtitle,
  } = desktop || mobile;

  let blankOnePixelMedia = '(min-width: 0)';
  if (desktop && !mobile) {
    blankOnePixelMedia = `(max-width: ${screenWidthLg - 1}px)`;
  }
  if (mobile && !desktop) {
    blankOnePixelMedia = `(min-width: ${screenWidthLg}px)`;
  }

  const blankOnePixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  const backgroundImage = {
    mobile: mobile?.backgroundImage?.image?.node?.gatsbyImage,
    desktop: desktop?.backgroundImage?.image?.node?.gatsbyImage,
  };

  const sources = {
    background: [
      {
        srcSet: blankOnePixel,
        media: blankOnePixelMedia,
      },
    ],
  };

  let hasBackgroundImage = false;

  if (backgroundImage.mobile) {
    hasBackgroundImage = true;
    sources.background.push({
      ...backgroundImage.mobile,
      media: `(max-width: ${screenWidthLg - 1}px)`,
    });
  }

  if (backgroundImage.desktop) {
    hasBackgroundImage = true;
    sources.background.push({
      ...backgroundImage.desktop,
      media: `(min-width: ${screenWidthLg}px)`,
    });
  }

  return (
    <div key={title}>
      <Block
        style={style}
      >
        {hasBackgroundImage && (
          <BackgroundImage
            gatsbyImageProps={{
              ...defaultGatsbyImageProps,
              style: {
                position: 'absolute',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
              },
            }}
            svgImageProps={{
              top: 0,
              left: 0,
              objectFit: 'cover',
            }}
            sources={sources.background[1]}
          />
        )}
        <Container>
          {title && hasBackgroundImage && !titleOutside && (
            <TitleOverlay
              dangerouslySetInnerHTML={createMarkup(title)}
            />
          )}
          {title && !titleOutside && !hasBackgroundImage && (
            <Title
              fade={fadingBackground}
              dangerouslySetInnerHTML={createMarkup(title)}
            />
          )}
          {subtitle && !isTablet && (
            <Subtitle>{subtitle}</Subtitle>
          )}
        </Container>
      </Block>

      {title && titleOutside && (
        <OutsideTitle
          fade={fadingBackground}
          dangerouslySetInnerHTML={createMarkup(title)}
        />
      )}
    </div>
  );
};

HeroBlock.propTypes = {
  desktop: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundImage: PropTypes.any, //eslint-disable-line
  }),
  mobile: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundImage: PropTypes.any, //eslint-disable-line
  }),
  style: PropTypes.shape(),
  titleOutside: PropTypes.bool,
  fadingBackground: PropTypes.bool,
};

HeroBlock.defaultProps = {
  desktop: null,
  mobile: null,
  style: {},
  titleOutside: false,
  fadingBackground: false,
};

export default HeroBlock;
