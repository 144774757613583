import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SectionWrapper from '../../common/SectionWrapper';
import StepByStepGuideBlock from '../../blocks/StepByStepGuideBlock';
import withSquishing from '../../utils/withSquishing';
import { getTitleFontFamily } from '../../../js/utils/sectionHelper';
import devices from '../../../js/styles/Devices';

const Title = styled.h2`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h2-desktop-font-size);
    letter-spacing: var(--has-no-letter-spacing);
    color: ${(props) => props.titleColor};

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const SectionContainer = styled(SectionWrapper)`
  && {
    padding: 2.5rem 1rem;
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto 2rem;
    width: 7rem;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation:enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const StepByStepGuideSection = ({
  acf,
}) => {
  const { fontFamily, titlePosition, titleColor } = acf.sectionSettings;

  return (
    <SectionContainer
      settings={acf.sectionSettings}
      noContainer
    >
      {acf.title && (
      <Title
        fontFamily={getTitleFontFamily(fontFamily)}
        position={titlePosition}
        titleColor={titleColor}
      >
        {acf.title}
      </Title>
      )}
      {acf.sectionSettings?.titleLine && (
        <TitleLine />
      )}
      <StepByStepGuideBlock
        {...acf.guideSteps}
        fontFamily={acf.sectionSettings.fontFamily}
        isSection
      />
    </SectionContainer>
  );
};

StepByStepGuideSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    guideSteps: PropTypes.shape(StepByStepGuideBlock.propTypes),
    sectionSettings: PropTypes.shape(),
  }),
};

StepByStepGuideSection.defaultProps = {
  acf: {},
};

export default withSquishing(StepByStepGuideSection);
