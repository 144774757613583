import logger from '../../utils/logger';
import { sendEvent } from '../../utils/gtm';
import { clearState } from '../../utils/form-state';
import hash from '../../store/actions/api/application/hashFunction';
import { normalizePhone } from '../../utils/normalize-phone-number';
import { convertTelToE164 } from '../../utils/formatters/convert-tel-to-e164';
import { region } from '../../localizations/current-locale';
import FormTypes from '../../../libs/constants/form-types';
import { INTL_FORMATS } from '../../localizations/locale-data';

const sendTrackingEvent = ({ formType, applicationId, loan }) => {
  const product = formType === FormTypes.MORTGAGE_LOAN ? 'Mortgage' : 'PrivateLoan';
  return sendEvent({
    event: 'Submit',
    eventCategory: `${region} ${product}`,
    eventAction: `${region} ${product} Conversion`,
    eventLabel: 'Submit',
    formType,
    applicationId,
    applicationLoanCurrency: INTL_FORMATS.number.currency.currency,
    applicationLoanMergeBool: loan.merge,
    applicationLoanConsolidationAmount: loan.consolidationAmount,
    applicationExpanded: loan.expanded,
    applicationClientId: loan.clientId,
    applicationHasCoApplicant: loan.hasCoApplicant ? 'true' : 'false',
    application: {
      loanTotal: loan.totalAmount,
      loanPurpose: loan.purpose,
      loanType: loan.loanType,
      loanRepaymentTime: loan.repaymentTime,
      loanConsolidationAmount: loan.consolidationAmount,
      acceptNewsletter: loan.acceptNewsletter,
    },
    applicationApplicant: {
      applicantDependants: loan.applicant?.dependants,
      applicantCivilStatus: loan.applicant?.civilStatus,
      applicantHomeType: loan.applicant?.homeType,
      applicantHomeCost: loan.applicant?.homeCost,
      applicantOtherLivingCosts: loan.applicant?.otherLivingCost,
      applicantEmploymentType: loan.applicant?.employmentType,
      applicantEmployerName: loan.applicant?.employerName,
      applicantMonthlySalary: loan.applicant?.monthlySalary,
      applicantEmploymentSince: loan.applicant?.employmentSince,
      e164phonenumber: hash(convertTelToE164(normalizePhone(loan.applicant?.phoneNumber))),
      hashedPhoneNumber: hash(normalizePhone(loan.applicant?.phoneNumber)),
      hashedEmail: hash(loan.applicant?.email),
      hashedSsn: hash(loan.applicant?.nationalId),
      // eslint-disable-next-line
      applicantEmploymentSinceMonth: loan.applicant?.employmentSinceMonth + 1, // +1 so that months equal their respective values, Jan was previously 0
    },
    applicationCoApplicant: {
      coapplicantDependants: loan.coapplicant?.dependants,
      coapplicantCivilStatus: loan.coApplicant?.civilStatus,
      coapplicantHomeType: loan.coApplicant?.homeType,
      coapplicantHomeCost: loan.coApplicant?.homeCost,
      coapplicantHomeOtherLivingCosts: loan.coApplicant?.otherLivingCost,
      coapplicantEmploymentType: loan.coApplicant?.employmentType,
      coapplicantEmployerName: loan.coApplicant?.employerName,
      coapplicantMonthlySalary: loan.coApplicant?.monthlySalary,
      coapplicantEmploymentSince: loan.coApplicant?.employmentSince,
      // eslint-disable-next-line
      coapplicantEmploymentSinceMonth: loan.coApplicant?.employmentSinceMonth + 1, // +1 so that months equal their respective values, Jan was previously 0
    },
    applicationCars: {
      leasedTotalAmount: loan.cars?.leasedTotalAmount,
      leasedTotalMonthlyCost: loan.cars?.leasedTotalMonthlyCost,
      ownedTotalAmount: loan.cars?.ownedTotalAmount,
      ownedTotalMonthlyCost: loan.cars?.ownedTotalMonthlyCost,
    },
    applicationCurrentLoans: {
      consumerTotalAmount: loan.currentLoans?.consumerTotalAmount,
      carTotalAmount: loan.currentLoans?.carTotalAmount,
      creditCardTotalAmount: loan.currentLoans?.creditCardTotalAmount,
      otherTotalAmount: loan.currentLoans?.otherTotalAmount,
    },
    applicationProperty: {
      propertyType: loan.property?.type,
      estimatedValue: loan.property?.estimatedValue,
      downPayment: loan.property?.downPayment,
      monthlyFee: loan.property?.monthlyFee,
      livingSpace: loan.property?.livingSpace,
      address: loan.property?.address,
      zipCode: loan.property?.zipCode,
      cityName: loan.property?.cityName,
      municipalityName: loan.property?.municipalityName,
      numberOfRooms: loan.property?.numberOfRooms,
    },
    applicationPropertyMortgage: {
      amount: loan.propertyCurrentLoans?.amount,
      monthlyPayment: loan.propertyCurrentLoans?.monthlyPayment,
      creditor: loan.propertyCurrentLoans?.creditor,
      interestRate: loan.propertyCurrentLoans?.interest,
    },
  });
};

// eslint-disable-next-line consistent-return
const submitApplicationSuccess = ({ formType, loan, setSubmitSuccess }) => (response) => {
  if (response?.error) {
    return Promise.reject(response.error.data);
  }
  if (response) {
    const {
      applicationId,
      applicationToken,
      customerPortalUrl,
      webBaseApplicationToken,
    } = response;
    const token = applicationToken || webBaseApplicationToken;
    const redirectUrl = customerPortalUrl || process.env.REACT_APP_CUSTOMER_PORTAL_URL;

    const isLanaPengarnaSite = process.env.GATSBY_FRONTEND_BASE_URL === 'https://lana-pengarna.se';

    if (token) {
      logger.info('Successfully submitted application');
      logger.immediateFlush();

      setSubmitSuccess(true);

      if (typeof window.hj === 'function') {
        window.hj('tagRecording', ['form-submit-success']);
      }
      sendTrackingEvent({ formType, applicationId, loan });
      clearState('application-form');
      clearState('application-auto-submit-form');
      clearState('server-side-variant-id');
      if (isLanaPengarnaSite) {
        window.location.assign('/tack-for-ansokan');
      } else {
        setTimeout(
          () => window.redirectToCustomerPortal(redirectUrl, { t: token }),
          2000,
        );
      }
    } else {
      logger.error('Create application: missing authorization-token in response');
    }
  }
};

export default submitApplicationSuccess;
