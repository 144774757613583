import startOfDay from 'date-fns/startOfDay';
import getYear from 'date-fns/getYear';
import isValid from 'date-fns/isValid';
import differenceInYears from 'date-fns/differenceInYears';
import Config from './config';

import { valid, invalid } from './resolutions';

const luhn = (input) => {
  const digits = input.split('').map((it) => parseInt(it, 10));

  const checksum = digits.reverse().reduce((accumulator, value, index) => {
    let product;
    const multiplier = 1 + (index % 2);
    product = multiplier * value;
    if (product > 9) {
      product -= 9;
    }
    return accumulator + product;
  });

  return checksum % 10;
};

const validateNationalId = (input = '') => {
  const config = Config.getValues();

  if (!/\S+/.test(input || '')) {
    return invalid;
  }

  const today = startOfDay(Date.now());
  const matches = /^(\d\d)?(\d\d)(\d\d)(\d\d)([-+])?(\d\d\d)(\d)$/.exec(input);

  if (typeof matches === 'undefined' || matches === null) {
    return invalid;
  }

  let century = matches[1];
  const yy = matches[2];
  const mm = matches[3];
  const dd = matches[4];
  const separator = matches[5];
  const serial = matches[6];
  const checkDigit = matches[7];

  if (typeof century === 'undefined' || century === null) {
    const todayCentury = Math.floor(getYear(today) / 100);
    const isOverHundredYearsOld = separator === '+';

    if (getYear(today) < `${todayCentury}${yy}`) {
      century = todayCentury - 1;
    } else {
      century = todayCentury;
    }

    if (isOverHundredYearsOld) {
      century -= 1;
    }
  }

  const dateOfBirthString = `${century}${yy}-${mm}-${dd}`;
  const dateOfBirth = new Date(dateOfBirthString);

  if (!isValid(dateOfBirth)) {
    return invalid;
  }

  const age = differenceInYears(today, dateOfBirth);

  if (age < 0) {
    return invalid;
  }

  const minAge = config.RESTRICTIONS.MIN_AGE;

  if (age < minAge) {
    return invalid;
  }

  const maxAge = config.RESTRICTIONS.MAX_AGE;

  if (age > maxAge) {
    return invalid;
  }

  if (luhn(`${yy}${mm}${dd}${serial}${checkDigit}`) !== 0) {
    return invalid;
  }

  return valid();
};

export default validateNationalId;
