import React from 'react';
import PropTypes from 'prop-types';

import usePrevious from '../../js/hooks/use-previous';

import hasViewportChanged from './has-viewport-changed';
import { useFloatingForm } from '../../context/floating-form';
import { SquishingProps } from '../../models/Section';

const withSquishing = (Component) => {
  const SquishingComponent = ({
    initialSquished,
    ...rest
  }) => {
    const [squished, setSquished] = React.useState(initialSquished);
    const [hadInitialValidRender, setHadInitialValidRender] = React.useState(false);
    const ref = React.useRef();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const prevProps = usePrevious(rest) || {};
    const { floatingFormState, comparePositions } = useFloatingForm();

    React.useEffect(() => {
      if (process.env.REACT_APP_REGION !== 'SE'
        && floatingFormState.element
        && (
          hasViewportChanged(prevProps, rest)
          || !hadInitialValidRender
        )) {
        const squishedState = comparePositions(ref.current);

        if (!hadInitialValidRender) {
          setHadInitialValidRender(true);
        }

        if (squished !== squishedState) {
          setSquished(squishedState);
        }

        setTimeout(() => {
          const t = comparePositions(ref.current);
          setSquished(t);
        }, 500);
      }
    }, [
      comparePositions,
      floatingFormState.element,
      hadInitialValidRender,
      prevProps,
      ref,
      rest,
      squished,
    ]);

    return (
      <div ref={ref}>
        <Component
          {...rest}
          squished={squished}
        />
      </div>
    );
  };

  SquishingComponent.propTypes = {
    acf: PropTypes.shape().isRequired,
    ...SquishingProps,
  };

  return SquishingComponent;
};

export default withSquishing;
