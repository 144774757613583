import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';
import SectionWrapper from '../../common/SectionWrapper';
import LinkBlock from '../../blocks/LinkBlock';

const textColor = '#333';

const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: row;
  position: relative;

  @media ${devices.downFromTablet} {
    padding-bottom: 1rem;
  }
`;

const HomeLink = styled.a`
  color: ${textColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const List = styled.li`
  font-family: var(--font-family);
  color: ${textColor};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 0.4rem;
  }

  &:last-child {
    overflow: hidden;
  }
`;

const StyledLinkBlock = styled(LinkBlock)`
  text-decoration: none;
  display: flex;

  &:hover {
    text-decoration: underline;
  }

  &.is-overflowing {
    @media ${devices.downFromTablet} {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const Item = styled(StyledLinkBlock)`
  margin-right: 0.4rem;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.iconColor};
  display: flex;
  align-items: center;

  &:hover {
    text-decoration-color: ${textColor};
  }

  span {
    color: ${textColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${devices.downFromTablet} {
    &:last-child {
      max-width: 100%;
      display: flex;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const MenuItem = React.memo(({
  link, iconColor, isLastItem, icon,
}) => (
  <Item
    link={{ url: link?.url }}
    className={isLastItem ? 'is-overflowing' : ''}
    iconColor={iconColor}
    icon={icon}
  >
    <span>{link?.title}</span>
  </Item>
));

MenuItem.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  iconColor: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};

const getListItems = (menu) => menu.map((m, index) => ({
  '@type': 'ListItem',
  position: index + 1,
  item: {
    '@type': 'WebPage',
    '@id': m.link?.url,
    name: m.link?.title,
  },
}));

const BreadcrumbSection = ({ acf }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const listEl = listRef.current;
      if (listEl) {
        const listWidth = listEl.offsetWidth;
        // eslint-disable-next-line max-len
        const itemsWidth = Array.from(listEl.children).reduce((total, child) => total + child.offsetWidth, 0);
        setIsOverflowing(itemsWidth > listWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const breadcrumbStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: getListItems(acf.menu),
  };

  return (
    <Wrapper noWrapper settings={acf.sectionSettings}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbStructuredData) }}
      />
      <UnorderedList ref={listRef}>
        <List>
          <HomeLink href="/">{acf.rootName}</HomeLink>
        </List>
        {acf.menu.map((m, i) => (
          <List key={`menu-item-${i}`}>
            <MenuItem
              link={m.link}
              icon={m.icon}
              iconColor={acf.iconColor[0]}
              isLastItem={isOverflowing && i === acf.menu.length - 1}
            />
          </List>
        ))}
      </UnorderedList>
    </Wrapper>
  );
};

BreadcrumbSection.propTypes = {
  acf: PropTypes.shape({
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          url: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
        icon: PropTypes.node.isRequired,
      }),
    ).isRequired,
    iconColor: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootName: PropTypes.string.isRequired,
    sectionSettings: PropTypes.shape({}),
  }).isRequired,
};

export default BreadcrumbSection;
