import React from 'react';

import { TemplateProps } from '../../models/Section';
import Sections from '../common/Sections/Sections';
import Templates from '../common/Templates/Templates';

const Template = ({
  acfGroups,
  postID,
  acfTemplates,
  location,
  isPost,
}) => (
  <>
    <Sections
      acfGroups={acfGroups}
      postID={postID}
      location={location}
      isPost={isPost}
    />
    {acfTemplates && (
    <Templates
      acfTemplates={acfTemplates}
      location={location}
    />
    )}
  </>
);

Template.propTypes = TemplateProps;

Template.defaultProps = {
  acfGroups: [],
  postID: '',
  isRequired: {},
  isPost: undefined,
};

export default Template;
