/* eslint-disable */
export default (acf, variables) => {
  const replacePlaceholders = (str) => {
    if (str === null) return;

    const placeholder = str.replace(/\[acf field="([\w]+)" post_id="([\w]+)"\]/g, (match, fieldName) => {
      let value = variables;

      const fields = fieldName.split('_');

      fields.forEach((f) => {
        if (value === undefined) return;
        value = value[f];
      });
      return value !== undefined ? value : match;
    });
    return placeholder;
  };

  const replaceNestedKeyPlaceholders = (nestedObj, nestedKey) => {
    if (nestedKey === 'usp' && nestedObj.items !== null) {
      Object.keys(nestedObj.items).forEach((item) => {
        nestedObj.items[item].text = replacePlaceholders(nestedObj.items[item].text);
      });
    } else if (nestedKey === 'linkCards') {
      Object.keys(nestedObj).forEach((item) => {
        if (nestedObj[item].title !== null) {
          nestedObj[item].title = replacePlaceholders(nestedObj[item].title);
        }
        if (nestedObj[item].description !== null) {
          nestedObj[item].description = replacePlaceholders(nestedObj[item].description);
        }
      });
    } else if (nestedKey === 'guideSteps' && nestedObj.steps !== null) {
      Object.keys(nestedObj.steps).forEach((item) => {
        nestedObj.steps[item].text = replacePlaceholders(nestedObj.steps[item].text);
      });
    } else if (nestedKey === 'desktopHero') {
      if (nestedObj.title !== null) {
        nestedObj.title = replacePlaceholders(nestedObj.title);
      }
      if (nestedObj.subtitle !== null) {
        nestedObj.subtitle = replacePlaceholders(nestedObj.subtitle);
      }
    } else if (nestedKey === 'form') {
      if (nestedObj.title !== null) {
        nestedObj.title = replacePlaceholders(nestedObj.title);
      }
      if (nestedObj.subtitle !== null) {
        nestedObj.subtitle = replacePlaceholders(nestedObj.subtitle);
      }
    }
  };

  const processObject = (obj) => {
    const nestedKeysToReplace = ['linkCards', 'usp', 'guideSteps', 'desktopHero', 'form'];

    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        Object.keys(obj[key]).forEach((nestedKey) => {
          if (typeof obj[key][nestedKey] === 'string') {
            obj[key][nestedKey] = replacePlaceholders(obj[key][nestedKey]);
          } else if (nestedKeysToReplace.includes(nestedKey)) {
            replaceNestedKeyPlaceholders(obj[key][nestedKey], nestedKey);
          }
        });
      }
    });
  };

  const newACF = JSON.parse(JSON.stringify(acf));

  processObject(newACF);

  return newACF;
};
