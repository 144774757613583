import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import { getTitleFontFamily } from '../../../js/utils/sectionHelper';
import devices from '../../../js/styles/Devices';
import style from './style';
import { brand } from '../../../js/localizations/current-locale';

const TextBlockStyle = styled.div`
  && {
    flex: 1;
    column-gap: 2rem;
    column-width:  ${(props) => props.columnWidth};
    column-count: ${(props) => props.columnCount};

    @media ${devices.downFromSmallTablet} {
      column-count: 1;
    }

    ul li::before {
      color: ${style.bulletColor};
    }

    ul ::marker {
      color: ${style.bulletColor};
    }

    li > ul {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    ul {
      font-size: var(--text-font-size);
    }

    h1 {
      font-family: var(--title-font);
    }

    h2 {
      font-family: var(--font-family);
      font-weight: ${style.fontWeight};
      font-size: var(--h2-desktop-font-size);
      letter-spacing: ${style.letterSpacing};

      @media ${devices.downFromTablet} {
        font-size: var(--h2-mobile-font-size);
        font-weight: ${style.fontWeightMobile};
      }
    }

    h3 {
      font-size: var(--h3-desktop-font-size);
      font-weight: ${style.h3FontWeight};

      @media ${devices.downFromTablet} {
        font-size: var(--h3-mobile-font-size);
        font-weight: ${style.fontWeightMobile};
      }
    }

    h4 {
      font-weight: var(--has-font-weight);
    }

    p {
      font-size: var(--text-font-size);
      color: ${style.textColor};
    }

    @media ${devices.downFromSmallTablet} {
      p {
        word-break: break-word;
      }
    }

    table {
      display: ${style.tableDisplay};
      table-layout: fixed;
      width: 100%;

      @media ${devices.downFromMobile} {
        overflow: auto;
      }
    }

    tbody tr:nth-child(odd) {
      background: ${style.tableRowColorOne};
    }

    tbody tr:nth-child(even) {
      background: ${style.tableRowColorTwo};
    }

    thead {
      border: 1px solid var(--light-grey);
      border-bottom: none;
    }

    tbody {
      border: 1px solid var(--light-grey);
      border-top: none;
    }

    th {
      font-size: .9rem;
      text-align: center;
      font-weight: ${style.tableRowWeight};
      background: ${style.tableRowColorTwo};
      padding: ${style.tablePadding};
    }

    td {
      font-size: .9rem;
      text-align: ${(props) => props.tableAlign};
      word-break: break-word;
      padding: 0.5rem 0.8rem;
    }

    img {
      height: auto;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    a {
      color: var(--link-color);
    }

    button {
      border-radius: ${style.borderRadius};
      color: ${style.ctaTextColor};
      cursor: pointer;
      font-weight: bold;
      text-decoration: none;
      font-size: 0.9rem;
      padding: ${style.ctaButtonPadding};
      background-color: ${style.buttonBackgroundColor};
      border-color: ${style.borderColor};
      border-style: solid;
      border-width: ${style.borderWidth};
      text-align: center;
      margin: ${style.margin};

      @media ${devices.upFromTablet} {
        max-width: 30%;
      }

      a {
        text-decoration: none;
        color: ${style.ctaTextColor};
      }

      ${() => brand === 'rahalaitos' && css`
        &:hover {
          background-color: var(--white);
          color: #6bd100;
        }
      `}
    }
  }
`;

const TextBlock = ({
  text,
  numColumns,
  fontFamily,
}) => (
  <TextBlockStyle
    fontFamily={getTitleFontFamily(fontFamily)}
    columnWidth={(devices.desktop) ? 'auto' : '45rem'}
    columnCount={numColumns}
    dangerouslySetInnerHTML={createMarkup(text)}
  />
);

TextBlock.propTypes = {
  text: PropTypes.string.isRequired,
  numColumns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontFamily: PropTypes.string,
};

TextBlock.defaultProps = {
  numColumns: 1,
  fontFamily: '',
};

export default TextBlock;

export const textBlockQuery = graphql`
  fragment textBlockFields on Text {
    text
    numColumns
  }
`;
