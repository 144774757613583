import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SectionWrapper from '../../common/SectionWrapper';
import TextBlock from '../../blocks/TextBlock';

const SectionContainer = styled(SectionWrapper)`
  && {
    background-color: ${(props) => props.backgroundColor};
  }
`;

const TextSection = ({ acf }) => {
  const {
    backgroundColor,
  } = acf.sectionSettings;

  return (
    <SectionContainer
      backgroundColor={backgroundColor}
      settings={acf.sectionSettings}
    >
      {acf.texts.map((text) => (
        <TextBlock
          {...text}
        />
      ))}
    </SectionContainer>
  );
};

TextSection.propTypes = {
  acf: PropTypes.shape({
    texts: PropTypes.arrayOf(
      PropTypes.shape(TextBlock.propTypes),
    ).isRequired,
    sectionSettings: PropTypes.shape({
      backgroundColor: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default TextSection;
