import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import styled from 'styled-components';
import stripNonNumeric from '../../../js/utils/strip-non-numeric';

import SectionWrapper from '../../common/SectionWrapper';
import PartnerProductBlock from '../../blocks/PartnerProductBlock';
import FormBlock from '../../blocks/FormBlock';
import devices from '../../../js/styles/Devices';

const Wrapper = styled.div`
  && {
    display: flex;
    padding-top: ${(props) => props.topPaddingDesktop};

    @media ${devices.downFromTablet} {
      flex-wrap: wrap;
      padding-top: ${(props) => props.topPaddingMobile};
    }
  }
`;

const ContentBlock = styled.div`
  && {
    width: 100%;

    @media ${devices.upFromTablet} {
      display: flex;
      position: relative;
      justify-content: space-between;
      margin-top: 5rem;
    }

    @media ${devices.downFromTablet} {
      padding-top: 4rem;
    }

    @media ${devices.downFromSmallTablet} {
      padding-top: 3rem;
    }
  }
`;

const Title = styled.h2`
  && {
    margin-bottom: 1.2rem;
    text-align: left;

    @media ${devices.downFromSmallTablet} {
      display: none;
    }
  }
`;

const PartnerProductSectionWrapper = styled(SectionWrapper)`
  && {
    @media ${devices.downFromSmallTablet} {
      padding-top: 0;
    }
  }
`;

const Description = styled.div`
  && {
    @media ${devices.upFromTablet} {
      width: calc(50% - 2rem);
    }
  }
`;

const PartnerProductSection = ({ acf, location }) => {
  const queryParams = queryString.parse(location.search);
  const downPaymentFactor = 1 - ((acf.downPayment || 0) / 100);
  const {
    objHeader,
  } = queryParams;

  return (
    <PartnerProductSectionWrapper
      settings={acf.sectionSettings}
    >
      <Wrapper>
        <ContentBlock>
          <Description>
            {acf.title && (
              <Title>
                {acf.title}
              </Title>
            )}
            {objHeader && (
              <Title>
                {objHeader}
              </Title>
            )}
            <PartnerProductBlock
              {...acf.partnerProduct}
              queryParams={queryParams}
            />
          </Description>
          {acf.form.show && (
            <FormBlock
              {...acf.form}
              prefilledValues={
                {
                  ...(queryParams.objCost
                    ? {
                      totalAmount: String(
                        Number(stripNonNumeric(queryParams.objCost)) * downPaymentFactor,
                      ),
                    }
                    : null
                  ),
                }
              }
              floating={false}
            />
          )}
        </ContentBlock>
      </Wrapper>
    </PartnerProductSectionWrapper>
  );
};

PartnerProductSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    partnerProduct: PropTypes.shape(PartnerProductBlock.propTypes).isRequired,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
    downPayment: PropTypes.number,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  location: PropTypes.shape().isRequired,
};

export default PartnerProductSection;
