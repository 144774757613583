import fabFacebook from './fabFacebook';
import fabGoogle from './fabGoogle';
import fabLinkedin from './fabLinkedin';
import fabTwitter from './fabTwitter';
import falAngleDown from './falAngleDown';
import falAngleUp from './falAngleUp';
import falArrowDown from './falArrowDown';
import falArrowLeft from './falArrowLeft';
import falArrowRight from './falArrowRight';
import falArrowUp from './falArrowUp';
import falBalanceScale from './falBalanceScale';
import falBars from './falBars';
import falCars from './falCars';
import falChevronDoubleDown from './falChevronDoubleDown';
import falChevronDoubleLeft from './falChevronDoubleLeft';
import falChevronDoubleRight from './falChevronDoubleRight';
import falChevronDoubleUp from './falChevronDoubleUp';
import falChevronDown from './falChevronDown';
import falChevronLeft from './falChevronLeft';
import falChevronRight from './falChevronRight';
import falChevronUp from './falChevronUp';
import falCoins from './falCoins';
import falComments from './falComments';
import falDesktop from './falDesktop';
import falEnvelope from './falEnvelope';
import falFileSignature from './falFileSignature';
import falHistory from './falHistory';
import falIdCard from './falIdCard';
import falListOl from './falListOl';
import falListUl from './falListUl';
import falMobile from './falMobile';
import falMoneyBill from './falMoneyBill';
import falMoneyBillWave from './falMoneyBillWave';
import falMotorcycle from './falMotorcycle';
import falPause from './falPause';
import falPiggyBank from './falPiggyBank';
import falPlay from './falPlay';
import falPlayCircle from './falPlayCircle';
import falSearch from './falSearch';
import falTablet from './falTablet';
import falTimes from './falTimes';
import falWallet from './falWallet';
import farPhone from './farPhone';
import fasArrowAltRight from './fasArrowAltRight';
import fasCheck from './fasCheck';
import fasEnvelope from './fasEnvelope';
import fasInfoCircle from './fasInfoCircle';
import fasMinus from './fasMinus';
import fasPhoneAlt from './fasPhoneAlt';
import fasPhoneSambla from './fasPhoneSambla';
import fasPlus from './fasPlus';
import fasUserLock from './fasUserLock';
import phone from './phone';
import userLogin from './userLogin';
import lock from './lock';
import solidSlash from './solidSlash';
import angleRight from './angleRight';

export default {
  fabFacebook,
  fabGoogle,
  fabLinkedin,
  fabTwitter,
  falAngleDown,
  falAngleUp,
  falArrowDown,
  falArrowLeft,
  falArrowRight,
  falArrowUp,
  falBalanceScale,
  falBars,
  falCars,
  falChevronDoubleDown,
  falChevronDoubleLeft,
  falChevronDoubleRight,
  falChevronDoubleUp,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falCoins,
  falComments,
  falDesktop,
  falEnvelope,
  falFileSignature,
  falHistory,
  falIdCard,
  falListOl,
  falListUl,
  falMobile,
  falMoneyBill,
  falMoneyBillWave,
  falMotorcycle,
  falPause,
  falPiggyBank,
  falPlay,
  falPlayCircle,
  falSearch,
  falTablet,
  falTimes,
  falWallet,
  farPhone,
  fasArrowAltRight,
  fasCheck,
  fasEnvelope,
  fasInfoCircle,
  fasMinus,
  fasPhoneAlt,
  fasPhoneSambla,
  fasPlus,
  fasUserLock,
  phone,
  userLogin,
  lock,
  solidSlash,
  angleRight,
};
