/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import styled from 'styled-components';
import SvgIcon from '../../atoms/SvgIcon';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import LanguageContext from '../../../context/language';
import formatLink from '../../../libs/formatters/format-link';
import style from '../../common/Header/style';

const Icon = styled(SvgIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const BankIDLogin = styled.a`
  && {
    background-color: ${style.loginButtonBackgroundColor};
  }
`;

const LinkBlock = ({
  children,
  link,
  icon,
  className,
  borderColor,
  borderWidth,
  borderRadius,
  type,
  ...rest
}) => {
  if (!link || (link && !link.url)) {
    return '';
  }
  const { title, target, url } = link;

  const match = (url || '').match(/^(?:(https?:\/\/|tel:|mailto:|#).+?(?=\/|$))?(.*)$/);

  if (!match[1]) {
    throw new Error(`${url} is not a valid url`);
  }

  const determineInternalLink = (baseUrl, urls) => {
    if (urls[2] === '/') {
      return urls[0].replace(/\/$/, '') === baseUrl;
    }
    return urls[0].replace(urls[2], '') === baseUrl;
  };

  const isInternalLink = determineInternalLink(process.env.GATSBY_FRONTEND_BASE_URL, match);

  const linkContent = (
    <>
      {icon && (
        <Icon
          icon={icon ? icon[0].split('/') : null}
        />
      )}
      {title && (
        <span dangerouslySetInnerHTML={createMarkup(title)} />
      )}
      {children}
    </>
  );

  if (type === 'bankID') {
    return (
      <BankIDLogin
        href={url}
      >
        {linkContent}
      </BankIDLogin>
    );
  }

  if (isInternalLink) {
    return (
      <LanguageContext.Consumer>
        {({ lang }) => (
          <Link
            to={formatLink(match[2], lang)}
            className={className}
            partiallyActive
          >
            {linkContent}
          </Link>
        )}
      </LanguageContext.Consumer>
    );
  } if (url) {
    return (
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        href={url}
        className={className}
        target={target ? '_blank' : null}
        rel={target ? 'noopener noreferrer' : null}
        {...rest}
      >
        {linkContent}
      </a>
    );
  }
  return null;
};

LinkBlock.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
  }),
  className: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  type: PropTypes.string,
};

LinkBlock.defaultProps = {
  children: null,
  icon: null,
  className: '',
  borderColor: '',
  borderWidth: '',
  borderRadius: '',
  link: PropTypes.shape({
    url: '',
    title: '',
    target: '',
  }),
  type: '',
};

export default LinkBlock;

export const linkBlockQuery = graphql`
  fragment linkBlockFields on Link {
    link {
      url
      title
      target
    }
    icon
  }
`;
