import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import LanguageSelect from '../LanguageSelect/LanguageSelect';

import LanguageContext from '../../../context/language';
import switchLanguage from '../../utils/switch-language';

const LanguageSwitch = ({
  languages,
  label,
  translations,
  selectInHeader,
  className,
}) => {
  const languageOptions = languages.map((language) => ({
    label: language.native_name,
    value: language.code,
    flag: `https://static-assets.advisa.se/flags/${language.code}.png`,
  }));

  return (
    <LanguageContext.Consumer>
      {({ lang }) => (
        <LanguageSelect
          options={languageOptions}
          label={label}
          value={lang}
          onChange={(e) => switchLanguage(e.target.value, lang, translations)}
          selectInHeader={selectInHeader}
          className={className}
        />
      )}
    </LanguageContext.Consumer>
  );
};

LanguageSwitch.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      native_name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  translations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectInHeader: PropTypes.bool,
  className: PropTypes.string,
};

LanguageSwitch.defaultProps = {
  languages: [],
  label: '',
  selectInHeader: false,
  className: '',
};

export { LanguageSwitch };

const LanguageSwitchWrappedWithStaticQuery = (props) => {
  const data = useStaticQuery(graphql`
    {
      allWp {
        edges {
          node {
            languages {
              code
              native_name
            }
          }
        }
      }
    }
  `);

  const { languages } = data.allWp.edges[0].node;

  return (
    <LanguageSwitch
      {...props}
      languages={languages}
    />
  );
};

export default LanguageSwitchWrappedWithStaticQuery;
