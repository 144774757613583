export default {
  flexDirection: 'row',
  mobileFlexDirection: 'column',
  imageWidth: '7.5rem',
  imageHeight: '7.5rem',
  imageMargin: '0 0.75rem 0 1rem',
  imageWrapper: '0 auto 1em',
  aboveFoldImageWrapper: '1rem 0 2rem',
  mobileImageMargin: 'inherit',
  stepFontWeight: '400',
  stepMargin: '0 1rem 3rem',
  stepByStepMaxWidth: '80%%',
  pColor: 'var(--black)',
  marginStepImage: '0 auto',
  aboveFoldStepMargin: '0 auto 1rem',
  imageSize: '3rem',
  textWrapperWidth: '10rem',
  textWrapperPadding: '0.5rem 0rem 1rem 1.5rem',
  titleAboveFold: '2rem',
  aboveFoldTextAlign: 'left',
  aboveFoldFontWeight: '500',
  lastStepWidth: '12rem',
  lastStepMarginLeft: '.5rem',
  lastStepLineHeight: '1.5rem',
  lastStepFontSize: '1rem !important',
};

