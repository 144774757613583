import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import intlShape from '../../../js/schemas/intl';
import { brand } from '../../../js/localizations/current-locale';

import SvgIcon from '../../atoms/SvgIcon';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';

import SectionWrapper from '../../common/SectionWrapper';
import replaceACFVariables from '../../../libs/utils/replace-acf-variables';

import style from './style';
import { useOptions } from '../../../context/options';

const Wrapper = styled(SectionWrapper)`
  && {
    background-color: var(--lightest-grey);
    position: ${(props) => props.position};
    padding-bottom: ${(props) => props.paddingBottom};
  }
`;

const Title = styled.h2`
  && {
    margin-bottom: ${style.titleMarginBottom};
    font-family: var(--font-family);
    text-align: ${(props) => props.position} !important;
    font-size: var(--h2-desktop-font-size);
    font-weight: var(--has-font-weight);

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const Posts = styled.div`
  && {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: ${style.postsMargin};

    ${() => brand === 'rahalaitos' && css`
      gap: 1.5rem;

      @media ${devices.downFromSmallTablet} {
        flex-direction: column;
      }
    `}
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto;
    width: 7rem;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation: enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const linkStyle = css`
  && {
    &:not(:first-child) {
        margin-top: 1rem;
    }
  }
`;

const Link = styled.a`
  && {
    ${linkStyle};
    display: block;
    background: ${style.linkBG};
    border: ${style.border};
    border-radius: ${style.cardBorderRadius};
    text-decoration: none;
    position: ${(props) => props.position};
    padding-bottom: ${style.paddingBottom};
    margin: 1rem 0;
    position: relative;

    ${() => brand === 'rahalaitos' && css`
      flex: 1 1 0px;
      min-width: 30%;
      & div:nth-child(1) {
        &:before {
          background-color: var(--black);
          content: "";
          display: block;
          height: 100%;
          left: 0;
          opacity: .5;
          position: absolute;
          top: 0;
          transition-timing-function: ease-in-out;
          transition: opacity .8s;
          width: 100%;
        }
      }
      &:hover {
        & div:nth-child(1) {
          &:before {
            opacity: 0;
          }
        }
          > div {
            color: var(--green);
        }
      }
    `}

    @media ${devices.upFromTablet} {
      width: calc(33% - 1.5rem);
      margin: 1rem 0;
    }

    @media ${devices.downFromTablet} {
      width: 100%;
    }
  }
`;

const ImageContainer = styled.div`
  && {
    width: 100%;
    height: ${style.height};
    background: var(--lighter-grey);
    border-radius: ${style.borderRadius} ${style.borderRadius} 0 0;
    overflow: hidden;
    position: ${style.imagePosition};
  }
`;

const Image = styled.img`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostTitle = styled.p`
  && {
    font-weight: bold;
    color: ${style.postTitleColor};
    position: ${style.postTitlePosition};
    height: ${style.postTitleHeight};
    margin: ${style.postTitleMargin};
    text-align: ${style.textAlign};
    display: ${style.displayPostTitle};
    align-items: ${style.alignItems};
    justify-content: ${style.justifyContent};
    padding: ${style.postTitlePadding};
  }
`;

const PostDescription = styled.div`
  && {
    font-weight: ${style.fontWeight};
    color: var(--dark-teal);
    text-align: ${style.textAlign};
    display: ${style.display};
    align-items: ${style.alignItems};
    justify-content: ${style.justifyContent};
    height: ${style.postDescriptionHeight};
    background-color: ${style.postDescriptionBackgroundColor};
    padding: ${style.postDescriptionPadding};
    margin: ${style.postDescMargin};
  }
`;

const Button = styled.div`
  && {
    width: ${style.expandButtonWidth};
    display: block;
    margin: ${style.expandButtonMargin};
    margin-top: 2.5rem;
    padding: 0.8rem;
    background-color: ${style.buttonBackgroundColor};
    color: ${style.buttonColor};
    background-image: none;
    border: ${style.expandButtonBorder};
    border-radius: ${style.expandButtonBorderRadius};
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9rem;
    text-decoration: none;
    text-align: center;

    @media ${devices.desktop} {
      max-width: 30%;
    }

    &:focus {
      outline: 0;
      border-color: var(--dark-teal-20);
      outline: none;
    }
  }
`;

const Icon = styled(SvgIcon)`
  && {
    height: 0.8rem;
    font-size: 1rem;
    margin-left: 0.2rem;
  }
`;

const SimpleButton = styled.div`
  && {
    margin-top: 2.5rem;

    &:focus {
      outline: 0;
    }
  }
`;

const ActionsWrapper = styled.div`
  && {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    bottom: ${style.bottom};
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: ${style.displayActionsWrapper};
  }
`;

const MockLink = styled.div`
  && {
    font-weight:bold;
    color: ${style.mockLinkMainColor};

    &:after{
      transform: ${style.transform};
      display: ${style.mockLinkDisplay};
      content:" >";
      color: ${style.mockLinkColor};
      padding-left: ${style.paddingIcon};
    }
  }
`;

const RelatedPostsSection = ({ acf, intl: { formatMessage } }) => {
  const { optionVariables } = useOptions();
  const {
    expandButtonLabel,
    collapseButtonLabel,
  } = acf.buttonAcf;

  const { visiblePosts } = acf;
  const { titlePosition } = acf.sectionSettings;
  const relatedPosts = acf.categories.nodes[0] ? acf.categories.nodes[0].posts.nodes : [];
  /*
    Show all posts - showAll
    default show 3 - showThree
    Show latestst 3 posts and view all button - loadAllShowThree
  */
  const visiblePostsMap = {
    showAll: {
      defaultLimit: 1000,
      showToggleButton: false,
    },
    showThree: {
      defaultLimit: 3,
      showToggleButton: false,
    },
    loadAllShowThree: {
      defaultLimit: 3,
      showToggleButton: true,
    },
  };

  const [limit, setLimit] = useState(visiblePostsMap[visiblePosts].defaultLimit);
  const { showToggleButton } = visiblePostsMap[visiblePosts];

  const messages = defineMessages({
    readMoreText: {
      id: 'readMoreButton',
      defaultMessage: 'Read more',
      description: 'read more button message',
    },
  });

  const toggleShowAll = useCallback(() => {
    setLimit(limit === 3 ? 1000 : 3);
  }, [limit]);

  const parseDesc = (post) => {
    const excerpt = post.excerpt.replace(/(<br \/>)|(<\/?p>)/g, '');
    const fullDescription = replaceACFVariables(
      excerpt || post.seo.description,
      optionVariables,
    );
    const description = fullDescription.length > 140
      ? fullDescription.slice(0, 140).concat('...')
      : fullDescription;

    return description;
  };

  return (
    <Wrapper
      settings={acf.sectionSettings}
    >
      {acf.title && (
        <Title
          position={titlePosition}
        >
          {acf.title}
        </Title>
      )}
      {acf.sectionSettings?.titleLine && (
        <TitleLine />
      )}
      <Posts>
        {relatedPosts.slice(0, limit).map((item, key) => (
          <Link
            key={`related-post-${item.id}-${key}`}
            href={item.link}
          >
            <ImageContainer>
              {item.featuredImage && (
              <Image
                src={item.featuredImage.node.sourceUrl}
                alt={item.featuredImage.node.altText}
                loading="lazy"
              />
              )}
            </ImageContainer>
            <PostTitle
              dangerouslySetInnerHTML={createMarkup(item.title)}
            />
            <PostDescription
              dangerouslySetInnerHTML={createMarkup(parseDesc(item))}
            />
            <ActionsWrapper>
              <span>{/* <CategoryPill></CategoryPill> */}</span>
              {/* cant find readable label for each category */}
              <MockLink>{formatMessage(messages.readMoreText)}</MockLink>
            </ActionsWrapper>
          </Link>
        ),
        )}
      </Posts>
      {showToggleButton && (
        <SimpleButton>
          <Button
            role="button"
            tabIndex={0}
            onClick={() => toggleShowAll()}
          >
            <Icon
              icon={limit > 3 ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']}
            />
            {limit > 3 ? collapseButtonLabel : expandButtonLabel}
          </Button>
        </SimpleButton>
      )}
    </Wrapper>
  );
};

RelatedPostsSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    categories: PropTypes.shape(
    ).isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.string,
    ),
    showDate: PropTypes.bool.isRequired,
    visiblePosts: PropTypes.string.isRequired,
    buttonAcf: PropTypes.shape({
      expandButtonLabel: PropTypes.string,
      collapseButtonLabel: PropTypes.string,
    }),
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  intl: intlShape.isRequired,
};

const RelatedPostsSectionWithIntl = injectIntl(RelatedPostsSection);

export { RelatedPostsSectionWithIntl as RelatedPostsSection };

export default RelatedPostsSectionWithIntl;
