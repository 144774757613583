import React from 'react';
import PropTypes from 'prop-types';

import { useViewport } from '../../../js/utils/viewport';

import useDevice from '../../../js/utils/use-device';
import { shouldHavePadding, gradiantBackground } from '../../../js/utils/sectionHelper';
import { useFloatingForm } from '../../../context/floating-form';
import PageTitleSection from '../../sections/PageTitleSection';
import StepByStepGuideSection from '../../sections/StepByStepGuideSection';
import AboveFoldWithFormSection from '../../sections/AboveFoldWithFormSection';
import AboveFoldWithFormWithoutHeroSection from '../../sections/AboveFoldWithFormWithoutHeroSection/AboveFoldWithFormWithoutHeroSection';
import AuthorSection from '../../sections/AuthorSection/AuthorSection';
import FAQSection from '../../sections/FAQSection';
import CreditorsSection from '../../sections/CreditorsSection';
import CreditorsCarouselSection from '../../sections/CreditorsCarouselSection';
import CTASection from '../../sections/CTASection';
import ContactInfoSection from '../../sections/ContactInfoSection';
import FormSection from '../../sections/FormSection';
import FormWithInfoSection from '../../sections/FormWithInfoSection';
import HeroSection from '../../sections/HeroSection';
import TextSection from '../../sections/TextSection';
import WordlistSection from '../../sections/WordlistSection';
import EmailSubmissionSection from '../../sections/EmailSubmissionSection';
import ContactFormSection from '../../sections/ContactFormSection';
import TrustpilotSection from '../../sections/TrustpilotSection';
import HappyCustomerSection from '../../sections/HappyCustomerSection';
import RelatedPostsSection from '../../sections/RelatedPostsSection';
import PartnerProductSection from '../../sections/PartnerProductSection';
import PrefilledApplicationSection from '../../sections/PrefilledApplicationSection';
import CurrentCompareSection from '../../sections/CurrentCompareSection';
import LinkCardsSection from '../../sections/LinkCardsSection';
import HREFLANGSection from '../../sections/HREFLANGSection';
import StipendiumFormSection from '../../sections/StipendiumFormSection';
import BreadcrumbSection from '../../sections/BreadcrumbSection';
import LocalBusinessDataSection from '../../sections/LocalBusinessDataSection';
import FinancialServiceDataSection from '../../sections/FinancialServiceDataSection';
import PartnerSection from '../../sections/PartnerLinkSection';
import UnsubscribeEmailSection from '../../sections/UnsubscribeEmail/UnsubscribeEmailSection';
import UnsubscribePostSection from '../../sections/UnsubscribePostSection/UnsubscribePostSection';
import UnsubscribeSmsSection from '../../sections/UnsubscribeSmsSection/UnsubscribeSmsSection';
import NotificationSection from '../../sections/Notification';
import IndexSection from '../../sections/IndexSection';

const PureSections = ({
  acfGroups,
  postID,
  location,
}) => {
  const { width } = useViewport();
  const { isTablet } = useDevice();
  const {
    floatingFormState: {
      dimension = {},
    },
  } = useFloatingForm();

  const hasFormInSection = acfGroups
    && acfGroups.length
    && acfGroups.findIndex(((acf) => (
      // eslint-disable-next-line no-underscore-dangle
      acf.__typename === 'WpAcfSectionsAboveFoldWithForm'
      || (acf.form && acf.form.show)
    )));
  const renderedSections = (acfGroups || []).map((acf, i) => {
    const initialSquished = process.env.REACT_APP_REGION !== 'SE'
      ? (hasFormInSection !== -1 && hasFormInSection <= i)
      : false;
    const squishingProps = {
      initialSquished,
      windowWidth: width,
      windowLocationSlug: location.href,
      formHeight: dimension.height,
    };

    if (shouldHavePadding(acfGroups)) {
      acf = { ...acf, paddingAtTop: shouldHavePadding(acfGroups) }; //eslint-disable-line
    }

    if (gradiantBackground(acfGroups)) {
      acf = { ...acf, fadingBackground: gradiantBackground(acfGroups) }; //eslint-disable-line
    }

    const uniqueKey = `${acf.__typename}_${i}`; //eslint-disable-line

    switch (acf.__typename) { //eslint-disable-line
      case 'WpAcfSectionsPageTitle':
        return (
          <PageTitleSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsStepByStepGuide':
        return (
          <StepByStepGuideSection
            key={uniqueKey}
            acf={acf}
            windowWidth={width}
            initialSquished={initialSquished}
            windowLocationSlug={squishingProps.windowLocationSlug}
            isTablet={isTablet}
            formHeight={squishingProps.formHeight}
            location={location}
          />
        );

      case 'WpAcfSectionsAboveFoldWithForm':
        return (
          <AboveFoldWithFormSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsAboveFoldWithFormWithoutHero':
        return (
          <AboveFoldWithFormWithoutHeroSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsAuthor':
        return (
          <AuthorSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsFaq':
        return (
          <FAQSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsBreadcrumb':
        return (
          <BreadcrumbSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsUnsubscribeEmail':
        return (
          <UnsubscribeEmailSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsUnsubscribePost':
        return (
          <UnsubscribePostSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsUnsubscribeSms':
        return (
          <UnsubscribeSmsSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsCreditors':
        return (
          <CreditorsSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsCreditorsCarousel':
        return (
          <CreditorsCarouselSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsCta':
        return (
          <CTASection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsLinkCards':
        return (
          <LinkCardsSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsHreflang':
        return (
          <HREFLANGSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsFinancialServiceData':
        return (
          <FinancialServiceDataSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsContactInfo':
        return (
          <ContactInfoSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsForm':
        return (
          <FormSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsFormWithInfo':
        return (
          <FormWithInfoSection
            key={uniqueKey}
            acf={acf}
            location={location}
            topSection={acfGroups[0].__typename === 'WpAcfSectionsFormWithInfo'} //eslint-disable-line
          />
        );

      case 'WpAcfSectionsHero':
        return (
          <HeroSection
            key={uniqueKey}
            acf={acf}
            isTablet={isTablet}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsCurrentCompare':
        return (
          <CurrentCompareSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsText':
        return (
          <TextSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsWordlist':
        return (
          <WordlistSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsLocalBusinessData':
        return (
          <LocalBusinessDataSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsEmailSubmission':
        return (
          <EmailSubmissionSection
            key={uniqueKey}
            acf={acf}
            location={location}
            isTablet={isTablet}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsContactForm':
        return (
          <ContactFormSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsTrustpilot':
        return (
          <TrustpilotSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsStipendiumForm':
        return (
          <StipendiumFormSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsHappyCustomer':
        return (
          <HappyCustomerSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsRelatedPosts':
        return (
          <RelatedPostsSection
            key={uniqueKey}
            acf={acf}
            postID={postID}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsPartners':
        return (
          <PartnerSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsPartnerProduct':
        return (
          <PartnerProductSection
            key={uniqueKey}
            acf={acf}
            location={location}
            {...squishingProps}
          />
        );

      case 'WpAcfSectionsPrefilledApplicationProduct':
        return (
          <PrefilledApplicationSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsNotification':
        return (
          <NotificationSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      case 'WpAcfSectionsIndex':
        return (
          <IndexSection
            key={uniqueKey}
            acf={acf}
            location={location}
          />
        );

      default:
        return <div key={i.toString()} />;
    }
  });

  return renderedSections;
};

const Sections = PureSections;

Sections.propTypes = {
  acfGroups: PropTypes.arrayOf(PropTypes.shape()),
  postID: PropTypes.string,
  location: PropTypes.shape().isRequired,
  isPost: PropTypes.bool,
};

Sections.defaultProps = {
  acfGroups: [],
  postID: '',
  isPost: false,
};

export default Sections;
