import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { region } from '../../../js/localizations/current-locale';
import SectionWrapper from '../../common/SectionWrapper';
import ImageBlock from '../../blocks/ImageBlock';
import TextBlock from '../../blocks/TextBlock';
import { ImageProp, SVGImageProp } from '../../../models/Section';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import { useOptions } from '../../../context/options';
import replaceHostName from '../../utils/replace-host-name';
import style from './style';

const Wrapper = styled.div`
  && {
    background-color: ${style.authorBackground};
    padding: 2rem 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: ${style.flexAlign};
    z-index: 1;

    > *{
      display: inline-block;
      text-align: ${style.alignment};
      width: ${style.childWidth};
    }
  }
`;

const AuthorText = styled.div`
  && {
    font-family: var(--font-family);
    flex-direction: row;
    font-size: 1rem;
    z-index: 2;
  }
`;

const ImageWrap = styled.div`
  && {
    margin-left: ${style.marginLeft};
    z-index: 3;
  }
`;

const Image = styled(ImageBlock)`
  && {
    height: auto;
    width: ${style.size};
    clip-path: ${style.clipPath};
    margin: 0 auto;
    z-index: 4;
  }
`;

const Publishing = styled.div`
  && {
    text-align: ${style.alignment};
    z-index: 5;
  }
`;

const Title = styled.a`
  && {
    text-decoration: none;
    font-size: 1rem;
    margin-bottom: 0;
    color: ${style.linkColor};
    z-index: 6;
  }
`;

const Date = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: ${style.alignment};
    z-index: 7;
    color: ${style.dateColor}
  }
`;

const Text = styled.div`
  && {
    font-size: 1rem;
    margin-top: 0.5rem;
    width: 100%;
    z-index: 8;

    & p {
      font-size: 1rem;
    }

    a {
      color: var(--sambla-new-red);
      text-decoration: none;
      padding-left: 1rem;
      cursor: pointer;
      font-size: .9rem;
    }

    a::before {
      display: inline-block;
      font-size: inherit;
      overflow: visible;
      width: 1rem;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23222' d='M16 0H8C6.9 0 6 .9 6 2V18C6 19.1 6.9 20 8 20H20C21.1 20 22 19.1 22 18V6L16 0M20 18H8V2H15V7H20V18M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M10 10V12H18V10H10M10 14V16H15V14H10Z'%3E%3C/path%3E%3C/svg%3E");
      margin-right: 0.1rem;
    }
  }
`;

const SectionContainer = styled(SectionWrapper)`
  && {
    background-color: ${(props) => props.backgroundColor};
    position: relative;
  }
`;

const updatedText = {
  SE: 'Uppdaterad: ',
  FI: 'Päivitetty: ',
};

const PureAuthorSection = ({ acf, variables, header }) => {
  const {
    logo = {
      link: {},
      image: {},
    },
  } = header;

  const { companyName } = variables;

  const imageUrl = ({ image }) => (
    image.svgImage
      ? replaceHostName(image.svgImage.source_url,
        process.env.GATSBY_FRONTEND_BASE_URL,
      ) : '');

  const modifiedDate = () => (acf.dateModified !== '' ? acf.dateModified : acf.datePublished);

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const AuthorStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: acf.headline,
    image: '',
    author: {
      '@type': 'Person',
      name: acf.title,
      url: acf.imageLink,
    },
    publisher: {
      '@type': 'Organization',
      name: companyName,
      logo: {
        '@type': 'ImageObject',
        url: imageUrl(logo),
      },
    },
    datePublished: acf.datePublished,
    dateModified: modifiedDate(),
  };

  return (
    <SectionContainer
      settings={acf.sectionSettings}
      backgroundColor={acf.sectionSettings.backgroundColor}
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(AuthorStructuredData) }}
      />

      <AuthorText fontFamily={getFontFamily(acf.sectionSettings.fontFamily)}>
        <Wrapper>
          <ImageWrap>
            {acf.imageLink ? (
              <a href={acf.imageLink}>
                <Image
                  imageObject={acf.media}
                />
              </a>
            ) : (
              <Image
                imageObject={acf.media}
              />
            )}
          </ImageWrap>

          <Publishing>
            {acf.titleLink ? (
              <Title href={acf.titleLink}>{acf.title}</Title>
            ) : (
              <Title>{acf.title}</Title>
            )}
            <Date>
              {updatedText[region]}
              {modifiedDate()}
            </Date>
          </Publishing>

          <Text>
            <TextBlock text={acf.text} />
          </Text>

        </Wrapper>
      </AuthorText>
    </SectionContainer>
  );
};

PureAuthorSection.propTypes = {
  acf: PropTypes.shape({
    text: PropTypes.string,
    media: PropTypes.shape({
      altText: PropTypes.string,
      image: ImageProp,
      svgImage: SVGImageProp,
    }),
    imageLink: PropTypes.string,
    title: PropTypes.string,
    titleLink: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    headline: PropTypes.string,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  variables: PropTypes.shape(),
  header: PropTypes.shape(),
};

PureAuthorSection.defaultProps = {
  variables: {},
  header: {},
};

const AuthorSection = (props) => {
  const { optionVariables, optionHeader } = useOptions();

  return (
    <PureAuthorSection
      {...props}
      variables={optionVariables}
      header={optionHeader}
    />
  );
};

export { PureAuthorSection };

export default AuthorSection;
