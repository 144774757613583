import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import LanguageContext from '../../../../context/language';
import { getFontFamily } from '../../../utils/sectionHelper';
import { brand } from '../../../localizations/current-locale';
import BankIdButton from '../../../../components/atoms/BankIdButton';
import showBankID from '../../../utils/bankid-button-conditions';

const QuickReapplyContainer = styled.div`
  && {
    font-family: var(--font-family);
    padding: 2rem 0 0;
    margin-bottom: -1rem;
    font-size: var(--text-font-size);
    display: flex;
    justify-content: center;
    text-align:  center;
  }
`;

const Wrapper = styled.div`
  && {
    overflow-x: hidden;
    background: var(--white);
    text-align: left;
    border-radius: 4px;
    box-shadow: var(--form-shadow);
    padding: 1rem 0 3rem 0;
    max-width: 36rem;
    margin: -1rem auto;
    z-index: 9999;
  }
`;

const ReapplyText = styled.div`
  && {
    margin: auto;
  }
`;

const quickReapplyLinks = {
  quickReapply: '/returning-customer/',
  quickReapplyEng: '/en/returning-customer/',
};

const Reapply = ({
  prefilledApplicationForm,
  partnerForm,
}) => {
  const validatedBrandColor = '#173C4D';

  const { lang } = useContext(LanguageContext);

  const [language, setLanguage] = useState('sv');

  const formTypeWithoutReapply = partnerForm
    || prefilledApplicationForm;

  useEffect(() => {
    if (process.env.REACT_APP_LANGUAGE) { setLanguage(process.env.REACT_APP_LANGUAGE); } else { setLanguage('sv'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (language !== lang) {
      setLanguage(lang);
    }
  }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    (showBankID() && !formTypeWithoutReapply && (
      <Wrapper>
        <QuickReapplyContainer
          fontFamily={getFontFamily(
            brand === 'sambla' ? 'samblaBeVietnamPro' : 'advisaRoboto',
          )}
        >
          <FormattedMessage
            id="applicationForm.returningCustomer.message"
            defaultMessage="Have you previously made an application? {quickReapply}"
            values={{
              quickReapply: (
                <BankIdButton
                  disabled={false}
                  validatedBrandColor={validatedBrandColor}
                  onClick={() => navigate(language === 'en'
                    ? quickReapplyLinks.quickReapplyEng
                    : quickReapplyLinks.quickReapply)}
                >
                  <ReapplyText>
                    <FormattedMessage
                      id="applicationForm.quickReapply.link"
                      defaultMessage="Re-apply"
                    />
                  </ReapplyText>
                </BankIdButton>
              ),
            }}
          />
        </QuickReapplyContainer>
      </Wrapper>
    )
    )
  );
};

Reapply.propTypes = {
  prefilledApplicationForm: PropTypes.bool,
  partnerForm: PropTypes.bool,
};

Reapply.defaultProps = {
  prefilledApplicationForm: false,
  partnerForm: false,
};

export default Reapply;
