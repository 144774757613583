import React from 'react';

export default () => (
  <svg viewBox="0 0 32 32" height="40em">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M3.625 27.094l9.563-26.688h2.406l-9.563 26.688h-2.406z"
    />
  </svg>
);
