export default {
  questionColor: 'black',
  faqIconColor: 'var(--mpc)',
  questionPadding: '0.5rem',
  questionJustification: 'flex-start',
  answerPadding: '0.5rem 1rem 1rem',
  questionFontWeight: 'var(--has-font-weight)',
  bulletColor: '#000',
  order: '0',
  FAQAnswerMargin: '0',
  fontSize: '1.5rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  expandButtonBorderRadius: '0',
  borderWidth: '0',
  expandButtonMargin: 'auto',
  paddingTop: '-2rem',
  titleSizeDownLaptop: '1.6rem',
  originalSvgTransForm: '-90deg',
  buttonBackground: 'var(--transparent)',
  borderStyle: 'solid',
  svgLeft: '0',
  svgHeight: '1.5rem',
  svgColor: 'var(--mpc)',
  svgFontSize: '1rem',
  svgMarginRight: '1rem',
  svgOrder: '0',
  svgTransform: 'rotate(-90deg)',
  svgTransformOrigin: 'center center',
  svgTransition: '0.2s ease 0s',
  svgTransformOpen: 'rotate(0deg)',
  answerFontSize: '1rem',
};
