import React from 'react';
import PropTypes from 'prop-types';

import { useOptions } from '../../../context/options';
import replaceHostName from '../../utils/replace-host-name';

const PureFinancialServiceDataSection = ({ acf, variables, header }) => {
  const {
    logo = {
      link: {},
      image: {},
    },
  } = header;

  const {
    companyName, phoneNumber, email, postAddress, openingHours, priceRange,
  } = variables;

  const imageUrl = ({ image }) => (
    image.svgImage
    ? replaceHostName( //eslint-disable-line
      image.svgImage.source_url, //eslint-disable-line
      process.env.GATSBY_FRONTEND_BASE_URL, //eslint-disable-line
    ) : ''); //eslint-disable-line

  const financialServiceStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'FinancialService',
    legalName: companyName,
    name: acf.name,
    logo: imageUrl(logo),
    image: imageUrl(logo),
    telephone: phoneNumber,
    url: process.env.GATSBY_FRONTEND_BASE_URL,
    email,
    address: {
      '@type': 'PostalAddress',
      streetAddress: postAddress?.box,
      addressLocality: postAddress?.city,
      postalCode: postAddress?.zip,
    },
    openingHours: [
      openingHours,
    ],
    priceRange,
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(financialServiceStructuredData) }}
    />
  );
};

PureFinancialServiceDataSection.propTypes = {
  acf: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  variables: PropTypes.shape(),
  header: PropTypes.shape(),
};

PureFinancialServiceDataSection.defaultProps = {
  variables: {},
  header: {},
};

const FinancialServiceDataSection = (props) => {
  const { optionVariables, optionHeader } = useOptions();
  return (
    <PureFinancialServiceDataSection
      {...props}
      variables={optionVariables}
      header={optionHeader}
    />
  );
};

export { PureFinancialServiceDataSection };
export default FinancialServiceDataSection;
