import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';

import SectionWrapper from '../../common/SectionWrapper';
import StipendiumFormBlock from '../../blocks/StipendiumFormBlock';
import { getTitleFontFamily } from '../../../js/utils/sectionHelper';

const Wrapper = styled(SectionWrapper)`
  && {
    padding-bottom: 1rem;
  }
`;

const Title = styled.h1`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h1-desktop-font-size);
    letter-spacing: var(--has-no-letter-spacing);
    color: ${(props) => props.titleColor};

    @media ${devices.downFromTablet} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const Text = styled.div`
  && {
    margin-bottom: 1rem;

    p {
      font-family: 'BeVietnamPro', sans-serif;
      font-size: 1em;
      font-weight: bold;
    }
    h1 {
      font-family: 'BeVietnamPro', sans-serif;
      font-size: 1.5em;
      font-weight: bold;
    }
    h2 {
      font-family: 'BeVietnamPro', sans-serif;
      font-size: 1.5em;
      font-weight: bold;
    }
    h3 {
      font-family: 'BeVietnamPro', sans-serif;
      font-size: 1.5em;
      font-weight: bold;
    }
  }
`;

const StipendiumFormSection = ({
  acf,
}) => {
  const { fontFamily, titlePosition, titleColor } = acf.sectionSettings;

  return (
    <Wrapper
      settings={acf.sectionSettings}
    >
      {acf.title && (
        <Title
          fontFamily={getTitleFontFamily(fontFamily)}
          position={titlePosition}
          titleColor={titleColor}
        >
          {acf.title}
        </Title>
      )}
      {acf.stipendiumText && (
        <Text
          className="c-wysiwyg-editor"
          dangerouslySetInnerHTML={createMarkup(acf.stipendiumText)}
        />
      )}
      <StipendiumFormBlock
        namePlaceholder={acf.namePlaceholder}
        emailPlaceholder={acf.emailPlaceholder}
        schoolPlaceholder={acf.schoolPlaceholder}
        messagePlaceholder={acf.messagePlaceholder}
        country={acf.country}
        reDirectUrl={acf.reDirectUrl}
        stipendiumDisclaimer={acf.stipendiumDisclaimer}
        buttonLabel={acf.buttonLabel}
      />
    </Wrapper>
  );
};

StipendiumFormSection.propTypes = {
  acf: PropTypes.shape(
    StipendiumFormBlock.propTypes,
    {
      title: PropTypes.string,
      stipendiumText: PropTypes.string,
      sectionSettings: SectionWrapper.propTypes.settings,
    },
  ).isRequired,
};

export default StipendiumFormSection;
