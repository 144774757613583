export default function switchLanguage(
  chosenLang, currentLang, translations, location = window.location) {
  let lang = chosenLang;
  const translatedPage = translations.find((page) => page.locale === lang);

  if (lang === process.env.REACT_APP_LANGUAGE) {
    lang = '';
  }

  const langPrefix = lang !== '' ? `/${lang}` : '';

  let redirectURL = `${location.origin}${langPrefix}`;

  if (translatedPage && translatedPage.href) {
    const isHomepage = /home\/$/.test(translatedPage.href);
    const is404Page = /page-not-found\/$/.test(translatedPage.href);

    if (isHomepage) {
      redirectURL = `${location.origin}${langPrefix}`;
    } else if (is404Page) {
      redirectURL = `${location.origin}${langPrefix}/404`;
    } else {
      redirectURL = translatedPage.href;
    }
  }
  // eslint-disable-next-line no-param-reassign
  location.href = redirectURL;
}
