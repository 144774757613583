import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import style from './style';

const Card = styled.div`
  && {
    font-family: var(--font-family);
    background: ${style.cardBackground};
    padding: 2.5rem 1rem;
    color: ${style.textColor};
    transition: box-shadow 600ms cubic-bezier(.33,.11,.02,.99);
    border-radius: ${style.borderRadius};

    &:hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  }
`;

const NavigationLink = styled.a`
  && {
    text-decoration: none;
  }
`;

const TopRow = styled.span`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: ${style.fontWeight};
    font-size: var(--text-font-size);
    color: ${style.cardTitleColor};
  }
`;

const Arrow = styled.span`
  && {
    &:after {
      content: '';
      display: inline-block;
      transform: rotate(-45deg);
      min-height: ${style.linkArrowSize}rem;
      min-width: ${style.linkArrowSize}rem;
      max-height: 1.5rem;
      max-width: 1.5rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-right: 3px solid ${style.linkCardArrowColor};
      border-bottom: 3px solid ${style.linkCardArrowColor};
      transition: 0.3s all cubic-bezier(.33,.11,.02,.99);
    }
  }
`;

const Text = styled.p`
  && {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 25rem;
    white-space: pre-line;
    color: ${style.textColor};
    font-weight: ${style.textFontWeight};
    font-size: 1rem;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

const LinkCardCardBlock = ({
  cardData,
  fontFamily,
}) => (
  <Card
    fontFamily={getFontFamily(fontFamily)}
  >
    <NavigationLink href={cardData.link}>
      <TopRow>
        {cardData.title}
        <Arrow />
      </TopRow>
      {cardData.description ? (
        <Text>
          {cardData.description}
        </Text>
      ) : null}
    </NavigationLink>
  </Card>
);

LinkCardCardBlock.propTypes = {
  cardData: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  fontFamily: PropTypes.string,
};

LinkCardCardBlock.defaultProps = {
  cardData: {},
  fontFamily: '',
};

export default LinkCardCardBlock;

export const linkCardCardBlockQuery = graphql`
  fragment LinkCardCardBlockFields on LinkCard {
    link
    title
    description
  }
`;
