import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import devices from '../../../js/styles/Devices';
import sizes from '../../../js/styles/Sizes';
import ImageBlock from '../ImageBlock';
import { brand } from '../../../js/localizations/current-locale';
import { ImageProp, SVGImageProp } from '../../../models/Section';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import style from './style';

const wrapperStyle = css`
  && {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const Wrapper = styled.a`
  && {
    ${wrapperStyle};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    width: ${style.mainWidth};
    background-color: ${(props) => props.happyCustomerTextBackgroundColor};
    border-radius: ${style.wrapperBorderRadius};
    margin-bottom: ${style.marginBottomWrapper};

    img{
      border-top-left-radius: ${style.wrapperBorderRadius};
      border-top-right-radius: ${style.wrapperBorderRadius};
    }

    a {
      text-decoration: none;
    }

    @media ${devices.upFromTablet} {
      width: ${(props) => props.width};
      flex-direction: ${(props) => props.flex};
      overflow: ${(props) => props.overflow};
    }
  }
`;

const Image = styled.div`
  && {
    display: flex;
    align-self: center;
    width: 100%;
    height: ${style.heightImg};

    @media ${devices.upFromTablet} {
      border-radius: ${style.imageBorderRadius};
      overflow: hidden;
      width: ${(props) => props.widthUpFromTablet};
      height: ${(props) => props.height};

      & {
        display: flex;
        flex-shrink: 0;
        align-items: center;
      }
    }

    @media ${devices.desktop} {
      width: ${(props) => props.widthDesktop};
    }
  }
`;

const ImageSrc = styled(ImageBlock)`
  && {
    width: 100%;
    height: 100%;
  }
`;

const TextWrapper = styled.div`
  && {
    font-family: var(--font-family);
    color: ${(props) => props.happyCustomerTextColor};

    @media ${devices.upFromTablet} {
      height: 100%;

      & {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
`;

const Customer = styled.div`
  && {
    background: ${style.backgroundColor};
    padding: 1rem 1.5rem;
  }
`;

const Name = styled.span`
  && {
    text-transform: ${(props) => props.textTransform};
    margin-bottom: 0.25rem;
    font-size: ${(props) => props.fontSize};
    opacity: ${style.opacityName};
    font-family: var(--font-family);
    color: ${(props) => props.happyCustomerTextTitleColor};
    background-color: ${(props) => props.happyCustomerTitleBackgroundColor};
    padding: ${style.paddingName};
    font-weight: ${style.fontWeight} ;
    text-shadow: ${style.textShadowName};
    border: ${style.borderName};
    box-shadow: ${style.boxShadowName};
    transform: ${style.transformCustomer};
    position: ${style.namePosition};
    width: ${(props) => props.widthUpFromTablet};

    @media ${devices.downFromMobile}{
      width: ${style.nameWidthMobile};
    }
  }
`;

const TriangleCorner = styled.div`
  && {
    ::after {
      color : transparent;
      border-radius: 1px;
      border-width: 7px;
      border-right-color: rgba(102, 178, 18, 0.7);
      border-bottom-color: rgba(102, 178, 18, 0.7);
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-style: solid;
    }
  }
`;

const Heading = styled.h5`
  && {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const Meta = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem;

    p img {
      height: 100%;
    }

    @media ${devices.upFromTablet} {
      height: ${(props) => props.height};
      flex-direction: ${(props) => props.flex}
    }
  }
`;

const Quote = styled.div`
  && {
    width: 100%;

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '·';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }

    p{
      font-size: ${style.quoteFontSizeP};
      font-family: var(--font-family);
    }
  }
`;

const Button = styled.span`
  && {
    height: 3.6rem;
    flex-shrink: 0;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: ${sizes.defaultBorderRadius};
    color: var(--dark-teal);
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem;
    display: block;
    width: 100%;
    border-color: var(--dark-teal-20);
    border-style: solid;
    border-width: 1px;
    text-align: center;
    margin: 1rem 0;

    @media ${devices.desktop} {
      max-width: 50%;
    }

    @media ${devices.mobile} {
      max-width: 100%;
    }

    @media ${devices.upFromTablet} {
      width: auto;
      margin-left: 1.5rem;
    }

    @media ${devices.desktop} {
      & {
        margin-left: ${(props) => props.marginLeft};
        margin-top: ${(props) => props.marginTop};
      }
    }
  }
`;

const isOmalaina = brand === 'omalaina';

const HappyCustomerBlock = ({
  link,
  media,
  name,
  heading,
  quote,
  buttonText,
  large,
  fontFamily,
  happyCustomerTextColor,
  happyCustomerTextTitleColor,
  happyCustomerTextBackgroundColor,
  happyCustomerTitleBackgroundColor,
}) => (
  <Wrapper
    href={link?.url}
    // eslint-disable-next-line no-nested-ternary
    width={(large && !isOmalaina) ? '100%' : `${style.wrapperWidth}`}
    flex={large ? 'row' : 'column'}
    overflow={(large || isOmalaina) ? 'hidden' : 'scroll'}
    happyCustomerTextBackgroundColor={happyCustomerTextBackgroundColor}
  >
    <Image
      href={link?.url}
      widthDesktop={large ? '65%' : '100%'}
      widthUpFromTablet={large ? '50%' : '100%'}
      height={large ? '100%' : `${style.imageHeight}`}
    >
      <ImageSrc
        imageObject={media}
      />
    </Image>
    {isOmalaina && name && (
    <>
      <Name
        widthUpFromTablet={!large ? '30%' : '30%'}
        fontSize={style.fontSize}
        fontFamily={getFontFamily(fontFamily)}
        happyCustomerTextTitleColor={happyCustomerTextTitleColor}
        happyCustomerTitleBackgroundColor={happyCustomerTitleBackgroundColor}
      >
        {name}
        <TriangleCorner />
      </Name>
    </>
    )}
    <TextWrapper
      fontFamily={getFontFamily(fontFamily)}
      happyCustomerTextColor={happyCustomerTextColor}
    >
      {!isOmalaina && name && (
      <Customer>
        {name && (
        <Name
          textTransform={style.textTransform}
        >
          {name}
        </Name>
        )}

        {heading && (
        <Heading>
          {heading}
        </Heading>
        )}
      </Customer>
      )}
      <Meta
        height={large ? '' : '100%'}
        flex={large ? 'column' : 'row'}
      >
        {quote && (
        <Quote
          happyCustomerTextColor={happyCustomerTextColor}
          dangerouslySetInnerHTML={{ __html: quote }}
        />
        )}
        {buttonText && link && (
        <Button
          marginLeft={large ? '' : '1.5rem'}
          marginTop={large ? '1.5rem' : '0'}
        >
          {buttonText}
        </Button>
        )}
      </Meta>
    </TextWrapper>
  </Wrapper>
);

HappyCustomerBlock.propTypes = {
  link: PropTypes.string,
  media: PropTypes.shape({
    altText: PropTypes.string,
    image: ImageProp,
    svgImage: SVGImageProp,
  }).isRequired,
  name: PropTypes.string,
  heading: PropTypes.string,
  quote: PropTypes.string,
  buttonText: PropTypes.string,
  large: PropTypes.bool,
  fontFamily: PropTypes.string,
  happyCustomerTextColor: PropTypes.string,
  happyCustomerTextTitleColor: PropTypes.string,
  happyCustomerTextBackgroundColor: PropTypes.string,
  happyCustomerTitleBackgroundColor: PropTypes.string,
};

HappyCustomerBlock.defaultProps = {
  link: null,
  name: '',
  heading: '',
  quote: '',
  buttonText: '',
  large: false,
  fontFamily: '',
  happyCustomerTextColor: '',
  happyCustomerTextTitleColor: '',
  happyCustomerTextBackgroundColor: '',
  happyCustomerTitleBackgroundColor: '',
};

export default HappyCustomerBlock;

export const happyCustomerBlockQuery = graphql`
  fragment happyCustomerBlockFields on HappyCustomer {
    link {
      url
      title
      target
    }
    media {
      ...imageBlockFieldsWithoutImage
      image {
        node {
          gatsbyImage(width: 800, quality: 90, layout: CONSTRAINED)
        }
      }
    }
    name
    heading
    quote
    buttonText
    large
  }
`;
