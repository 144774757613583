import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';
import style from './style';
import { brand } from '../../../js/localizations/current-locale';

import SectionWrapper from '../../common/SectionWrapper';
import LinkBlock from '../../blocks/LinkBlock';

const Title = styled.div`
  && {
    h1 {
      font-family: var(--title-font);
      font-size: var(--h1-desktop-font-size);
      text-align: ${(props) => props.position};
      margin: 0 auto 0.5em;

      @media ${devices.downFromTablet} {
        font-size: var(--h1-mobile-font-size);
      }
    }

    h2 {
      font-family: var(--font-family);
      font-size: var(--h2-desktop-font-size);
      font-weight: var(--has-font-weight);
      text-align: ${(props) => props.position};
      margin: 0 auto 0.5em;

      @media ${devices.downFromTablet} {
        font-size: var(--h2-mobile-font-size);
      }
    }

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4em 0;

        &::before {
          color: var(--teal);
          content: '·';
          font-weight: var(--has-font-weight);
          margin-right: 0.6em;
        }
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    tr {
      &:nth-child(odd) {
        background: var(--lightest-grey);
      }
    }

    td {
      padding: 0.5em 0.8em;
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

const ButtonsWrapper = styled.div`
  && {
    margin: 0.5rem;
    display: flex;
  }
`;

const CtaButton = styled(LinkBlock)`
  && {
    border-radius: ${(props) => props.borderRadius};
    color: ${style.ctaTextColor};
    cursor: pointer;
    font-weight: var(--has-font-weight);
    text-decoration: none;
    font-size: var(--text-font-size);
    padding: ${style.ctaButtonPadding};
    background-color: var(--transparent);
    border-color: ${(props) => props.borderColor};
    border-style: solid;
    border-width: ${(props) => props.borderWidth};
    text-align: center;
    margin: ${(props) => props.margin};

    @media ${devices.upFromTablet} {
      max-width: 30%;
    }

    a {
      text-decoration: none;
      color: ${style.ctaTextColor};
    }

    svg {
      font-size: 0.875em;
      margin-right: 0.5rem;
    }

    ${() => brand === 'rahalaitos' && css`
      &:hover {
        background-color: var(--white);
        color: #6bd100;
      }
    `}
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto;
    width: 7em;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation: enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const CTASection = ({ acf }) => {
  const { titlePosition } = acf.sectionSettings;

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
    >
      {acf.text && (
      <Title
        position={titlePosition}
        dangerouslySetInnerHTML={createMarkup(acf.text)}
      />
      )}
      {acf.sectionSettings?.titleLine && (
      <TitleLine />
      )}

      <ButtonsWrapper>
        {acf.buttons.map((button, i) => (
          <CtaButton
            borderRadius={style.borderRadius}
            borderWidth={style.borderWidth}
            borderColor={style.borderColor}
            margin={style.margin}
            {...button}
            // eslint-disable-next-line react/no-array-index-key
            key={`button-${i}`}
          />
        ))}
      </ButtonsWrapper>
    </SectionWrapper>
  );
};

CTASection.propTypes = {
  acf: PropTypes.shape({
    text: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.shape(LinkBlock.propTypes)).isRequired,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

export default CTASection;
