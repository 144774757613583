import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SectionWrapper from '../../common/SectionWrapper';
import CallUsBlock from '../../blocks/CallUsBlock';
import OpeningHoursBlock from '../../blocks/OpeningHoursBlock';

import devices from '../../../js/styles/Devices';

const ContactWrapper = styled(SectionWrapper)`
  && {
    h2, h4 {
      font-family: var(--font-family);
      font-weight: var(--has-font-weight);
    }
  }
`;

const InfoSectionBlock = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin:  auto;
    flex: 1;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0
    }

    @media ${devices.upFromMobile} {
      flex-direction: row;
    }
  }
`;

const ContactInfoSection = ({ acf }) => (
  <ContactWrapper
    settings={acf.sectionSettings}
  >
    <InfoSectionBlock>
      <CallUsBlock
        phoneNumber={acf.phoneNumber}
        text={acf.text}
        phoneNumberColor={acf.phoneNumberColor}
      />
      <OpeningHoursBlock
        title={acf.title}
        calendar={acf.calendar}
      />
    </InfoSectionBlock>
  </ContactWrapper>
);

ContactInfoSection.propTypes = {
  acf: PropTypes.shape(
    CallUsBlock.propTypes,
    OpeningHoursBlock.propTypes,
    {
      sectionSettings: SectionWrapper.propTypes.settings,
    },
  ).isRequired,
};

export default ContactInfoSection;
