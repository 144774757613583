import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import SectionWrapper from '../../common/SectionWrapper';
import ContactFormBlock from '../../blocks/ContactFormBlock';

import devices from '../../../js/styles/Devices';

const Title = styled.h2`
  && {
    font-family: var(--font-family);
    font-size: var(--h2-desktop-font-size);

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;
const Wrapper = styled(SectionWrapper)`
  && {
    text-align: center;
  }
`;

const Text = styled.div`
  && {
    margin-bottom: 2rem;
  }
`;

const TermsAndPoliciesWrapper = styled.div`
  && {
    p {
      font-size: 0.8rem;
    }
  }
`;

const MortgageDisclaimerText = styled.div`
  && {
    font-size: 0.8rem;
    line-height: 1.5;
    margin-top: 1rem;
    padding: 0 0.5rem 0 0.7rem;
  }
`;

const ContactFormSection = ({
  acf,
}) => (
  <Wrapper
    settings={acf.sectionSettings}
  >
    {acf.title && (
      <Title>
        {acf.title}
      </Title>
    )}
    {acf.text && (
      <Text
        className="c-wysiwyg-editor"
        dangerouslySetInnerHTML={createMarkup(acf.text)}
      />
    )}
    <ContactFormBlock
      {...acf}
    />
    {acf.mortgageDisclaimer && (
      <MortgageDisclaimerText
        key="contact-form-disclaimer"
        color="var(--transparent-dark-grey-90)"
        dangerouslySetInnerHTML={createMarkup(acf.mortgageDisclaimer)}
      />
    )}
    <TermsAndPoliciesWrapper
      dangerouslySetInnerHTML={createMarkup(acf.termsAndPolicies)}
    />
  </Wrapper>
);

ContactFormSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    sectionSettings: SectionWrapper.propTypes.settings,
    mortgageDisclaimer: PropTypes.string,
    termsAndPolicies: PropTypes.string,
  }).isRequired,
};

export default ContactFormSection;
