import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOptions } from '../../../context/options';
import SectionWrapper from '../../common/SectionWrapper';
import { ImageProp, SVGImageProp } from '../../../models/Section';
import devices from '../../../js/styles/Devices';

const Title = styled.p`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h2-desktop-font-size);
    letter-spacing: var(--has-letter-spacing);
    color: ${(props) => props.titleColor};
    margin-bottom: 0.5rem;

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto;
    width: 7rem;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation:enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const Subtitle = styled.p`
  && {
    max-width: 35rem;
    font-size: 1.3rem;
    margin: 0 auto 2rem 0;
  }
`;

const PartnerGrid = styled.div`
  && {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    @media ${devices.downFromTablet} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${devices.mobile} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const PartnerCard = styled.div`
  && {
    display:flex;
    align-items: center;
    background: var(--white);
    padding: 0.5rem;
    text-align:center;
    min-height: 5rem;
  }
`;

const PartnerImg = styled.img`
  && {
    width: 100%;
    height:auto;
    max-height: 2rem;
  }
`;

const renderPartner = (partner, i) => {
  if (partner.svgLogo || partner.logo) {
    return (
      <a href={partner.link || '#'} key={`partner-${i}`}>
        <PartnerCard>
          <PartnerImg src={partner.logo?.svgImage?.node?.sourceUrl || ''} />
        </PartnerCard>
      </a>
    );
  }

  return null;
};

export const PartnerLinkSection = ({ acf, partners }) => {
  const { titlePosition, titleColor } = acf.sectionSettings;

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
    >
      {acf.title && (
        <Title
          position={titlePosition}
          titleColor={titleColor}
        >
          {acf.title}
        </Title>
      )}
      {acf.sectionSettings?.titleLine && (
      <TitleLine />
      )}
      {acf.subtitle && (
        <Subtitle>{acf.subtitle}</Subtitle>
      )}
      <PartnerGrid>
        {partners.map((partner, i) => (
          renderPartner(partner, i)
        ))}
      </PartnerGrid>
    </SectionWrapper>
  );
};

PartnerLinkSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    logo: PropTypes.shape({
      altText: PropTypes.string,
      image: ImageProp,
      svgImage: SVGImageProp,
    }),
  })).isRequired,
};

const PartnerSection = (props) => {
  const { optionCreditors } = useOptions();
  return (
    <PartnerLinkSection
      {...props}
      partners={optionCreditors.creditorsAcf}
    />
  );
};

export default PartnerSection;
