import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import devices from '../../../js/styles/Devices';

import intlShape from '../../../js/schemas/intl';
import LabelValueRow from '../../atoms/LabelValueRow';
import style from './style';

const Card = styled.div`
  cursor: move;
  border-radius: 1.5rem;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  background: var(--white);
  margin: 5px 2rem;
  color: ${style.cardColor};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  >div:nth-of-type(2) {
    position: relative;

    ::before, ::after {
      content: " ";
      width: 1px;
      position: absolute;
      background: #e0e0e0;
      top: 0;
      bottom: 0;
    }
    ::before {
      left: -1rem;
    }
    ::after {
      right: -1rem;
    }
  }

  @media ${devices.downFromTablet} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: flex-start;

    >div:nth-of-type(2) {
      ::before, ::after {
        content: unset;
      }
    }
  }

  @media ${devices.downFromTablet} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Header = styled.h3`
  font-size: var(--h3-desktop-font-size);

  @media ${devices.downFromTablet} {
    font-size: var(--h3-mobile-font-size);
  }
`;

const LabelWrap = styled.div`
  font-weight: 1000;

  * {
    opacity: 0.8;
    margin: 0!important;
  }

  b {
    opacity: 1;
    display: block;
    font-size: ${style.cardDataFontSize};
  }

  @media ${devices.downFromTablet} {
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid #e0e0e0;

    :nth-of-type(1) {
      border:none;
    }
  }
`;

const messages = defineMessages({
  amount: {
    id: 'currentCompare.loanAmount',
    defaultMessage: 'Application:',
    description: 'Left side label for current compare card amount',
  },
  max: {
    id: 'currentCompare.max.interestRate',
    defaultMessage: 'Highest interest rate:',
    description: 'Left side label for current compare card max',
  },
  min: {
    id: 'currentCompare.min.interestRate',
    defaultMessage: 'Lowest interest rate:',
    description: 'Left side label for current compare card min',
  },
  savings: {
    id: 'currentCompare.amountToSave',
    defaultMessage: 'To save:',
    description: 'Left side label for current compare card savings',
  },
});

const CurrentCompareCardListBlock = ({ cardData, intl: { formatMessage } }) => (
  <Card>
    <Header>{cardData.title}</Header>
    <Row>
      <LabelWrap>
        <LabelValueRow label={formatMessage(messages.amount)} />
        <b>{cardData.amount}</b>
      </LabelWrap>
      <LabelWrap>
        <LabelValueRow label={formatMessage(messages.max)} />
        <b>{cardData.max}</b>
      </LabelWrap>

      <LabelWrap>
        <LabelValueRow label={formatMessage(messages.min)} />
        <b>{cardData.min}</b>
      </LabelWrap>
    </Row>

    <LabelWrap>
      <LabelValueRow label={formatMessage(messages.savings)} />
      <b>{cardData.savings}</b>
    </LabelWrap>
  </Card>
);

CurrentCompareCardListBlock.propTypes = {

  cardData: PropTypes.shape({
    title: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
    amount: PropTypes.string,
    savings: PropTypes.string,
  }),
  intl: intlShape.isRequired,
};

CurrentCompareCardListBlock.defaultProps = {
  cardData: {},
};

export default injectIntl(CurrentCompareCardListBlock);

export const currentCompareCardBlockQuery = graphql`
  fragment CurrentCompareCardBlockFields on Card {
    title
    max
    min
    amount
    savings
  }
`;
