export default {
  paddingTop: '1.2em',
  paddingTopDesktop: '4em',
  marginTop: '0',
  linkPadding: '0.1rem 0',
  fontSize: '0.9rem',
  titleFontSize: '0.9rem',
  infoPaddingTop: '2rem',
  textDecoration: 'none',
  pTagColor: 'var(--white)',
  columnMarginTop: '2rem',
  footerTextMarginTop: '1.5rem',
  fontWeightTitle: 'bold',
  backgroundColor: 'var(--dark-teal)',
  linkColor: 'var(--white)',
  textColor: 'var(--header-temp-active-color)',
  fontFamily: 'var(--font-family)',
  logoSize: '9.4rem',
  columnsPaddingTopMobile: '3rem',
};
