import PropTypes from 'prop-types';

const YoastImageMetaProps = PropTypes.shape({
  mediaItemUrl: PropTypes.string,
});

const YoastMetaProps = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  socialImage: YoastImageMetaProps,
  openGraphTitle: PropTypes.string,
  openGraphDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
});

export default YoastMetaProps;
