import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'ie-array-find-polyfill';
import styled from 'styled-components';
import style from './style';
import devices from '../../../js/styles/Devices';

const Label = styled.div`
  && {
    color: var(--white);
    font-weight: bold;
    margin-right: 1rem;
    text-transform: uppercase;
    border: ${(props) => (props.selectInHeader ? '0' : '')};
    clip: ${(props) => (props.selectInHeader ? 'rect(0 0 0 0)' : '')};
    height: ${(props) => (props.selectInHeader ? '1px' : '')};
    margin: ${(props) => (props.selectInHeader ? '-1px' : '')};
    overflow: ${(props) => (props.selectInHeader ? 'hidden' : '')};
    padding: ${(props) => (props.selectInHeader ? '0' : '')};
    position: ${(props) => (props.selectInHeader ? 'absolut' : '')};
    width: ${(props) => (props.selectInHeader ? '1px' : '')};
  }
`;

const LabelWrapper = styled.label`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  && {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    max-width: 18rem;
    height: 3rem;
    border: ${(props) => (props.border)};
    border-radius: 5px;

    @media ${devices.upFromTablet} {
      max-width: 12rem;
    }

    @media ${devices.downFromTablet} {
      flex-grow: 0;
    }
  }
`;

const Flag = styled.div`
  && {
    position: absolute;
    width: 2.5rem;
    text-align: center;
  }
`;

const LanguageSelector = styled.select`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: ${style.selectColor};
    cursor: pointer;
    outline: none;
    padding: 0 1.85rem 0 2.25rem;
    -webkit-appearance: none;
    appearance: none;
    z-index: 1;
  }
`;

const ArrowIcon = styled.div`
  && {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid ${style.selectColor};
    pointer-events: none;
    z-index: 2;
  }
`;

const LanguageIcon = styled.img`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LanguageSelect = ({
  label,
  options,
  value,
  selectInHeader,
  className,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState();
  const [currentLanguageFlag, setCurrentLanguageFlag] = useState();

  const currentLanguage = options.find((option) => option.value === value);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    if (currentLanguage && currentLanguage.flag !== currentLanguageFlag) {
      setCurrentLanguageFlag(currentLanguage.flag);
    }
  }, [value, currentLanguage, currentValue, currentLanguageFlag]);

  const selectOptions = options.map((option) => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.value === currentValue}
    >
      {option.value.toUpperCase()}
    </option>
  ));

  return (
    <LabelWrapper
      htmlFor="language-select"
      className={className}
    >
      {label && (
        <Label selectInHeader={selectInHeader}>
          {label}
        </Label>
      )}
      <Wrapper border={selectInHeader ? '0' : '1px solid var(--white)'}>
        {currentLanguage?.value !== 'ar'
        && (
        <Flag>
          <LanguageIcon
            src={currentLanguageFlag}
            alt=""
            width="18px"
            height="12px"
          />
        </Flag>
        )}
        <LanguageSelector
          id="language-select"
          value={value}
          {...rest}
        >
          {selectOptions}
        </LanguageSelector>
        <ArrowIcon />
      </Wrapper>
    </LabelWrapper>
  );
};

const optionSchema = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
});

LanguageSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionSchema),
  value: PropTypes.string,
  selectInHeader: PropTypes.bool,
  className: PropTypes.string,
};

LanguageSelect.defaultProps = {
  label: '',
  options: [],
  value: '',
  selectInHeader: false,
  className: '',
};

export default LanguageSelect;
