import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { brand, region } from '../../../js/localizations/current-locale';
import getTrustpilotMapping from './trustpilotMapping';
import LanguageContext from '../../../context/language';

const TrustBoxWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 1rem 0;
  visibility: visible;
  display: block;
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

const TrustBox = ({ acfTrustpilot }) => {
  const ref = useRef(null);
  const { templateId, businessUnitId } = getTrustpilotMapping(brand, region);
  const { lang } = useContext(LanguageContext);

  const mapLanguageToLocale = (language) => {
    switch (language) {
      case 'sv':
        return 'sv-SE';
      case 'fi':
        return 'fi-FI';
      case 'no':
        return 'nb-NO';
      case 'en':
        return 'en-GB';
      default:
        return 'sv-SE';
    }
  };

  const selectedLocale = mapLanguageToLocale(lang);

  useEffect(() => {
    let intervalId;

    const checkTrustpilotAvailability = () => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(checkTrustpilotAvailability, 100);

    return () => clearInterval(intervalId);
  }, []);
  if (!templateId) return null;

  return (
    <TrustBoxWrapper
      ref={ref}
      data-locale={selectedLocale}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-width="100%"
      data-style-height="140px"
      data-theme="light"
      data-stars={acfTrustpilot.stars}
    />
  );
};

TrustBox.propTypes = {
  acfTrustpilot: PropTypes.shape({
    stars: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

TrustBox.defaultProps = {
  acfTrustpilot: {
    stars: ['4', '5'],
  },
};

export default TrustBox;
