import useDevice from '../../js/utils/use-device';

const dynamicEvaluation = (componentVisibility, componentSpecificConditions) => {
  const {
    isDesktop, isTabletWidth, isLargeTabletAndUp, isLargeTabletAndDown, isLargeMobileAndUp, isLaptop, //eslint-disable-line
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useDevice();

  let evaluationValues = {
    desktop: isDesktop,
    isTabletWidth,
    isLargeTabletAndUp,
    isLargeTabletAndDown,
    isLargeMobileAndUp,
    mobile: isLaptop,
  };

  if (componentSpecificConditions) {
    evaluationValues = { ...evaluationValues, ...componentSpecificConditions };
  }

  if (componentVisibility) {
    const evaluateCondition = new Function('evaluationValues', componentVisibility);  //eslint-disable-line
    return evaluateCondition(evaluationValues);
  }

  return true;
};

export default dynamicEvaluation;
