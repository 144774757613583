export default {
  contentWrapperBgOverlay: 'linear-gradient(0deg, #2A3C4A 15%, rgba(29, 39, 49, 0.00) 15%)',
  mobileTitleColor: '#2A3C4A',
  buttonRadius: '8px',
  secondaryButtonBorder: '1px solid #2A3C4A',
  primaryButtonBg: '#6BD100',
  buttonTextColor: '#2A3C4A',
  secondaryButtonMobileBg: 'var(--white)',
  trustBoxBg: '#F6FFEB',
};
