import React, { useState, Suspense, useEffect } from 'react';

const LoadableForm = React.lazy(() => import('@advisa/onboarding-form'));

const NewFormBlock = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const initEnv = {
    pre: {},
    site: 'FI',
    brand: 'rahlaitos',
    lang: 'fi',
  };

  return (
    <>
      {isMounted && (
        <Suspense fallback={<div>Loading...</div>}>
          <LoadableForm {...initEnv} />
        </Suspense>
      )}
    </>
  );
};

export default NewFormBlock;
