export default {
  h1FontFamily: 'CoreSansC',
  desktopTitlePadding: '5%',
  textBlockMobileDisplay: 'block',
  textBlockMobileFontWeight: 'bold',
  textBlockDesktopFontWeight: 'bold',
  campaignWrapperBackground: 'var(--white)',
  campaignTextColor: 'var(--black)',
  formBlockColor: 'var(--white)',
  mobileAspectRatio: '17 / 9',
  tabletAspectRatio: '20 / 9',
  desktopAspectRatio: '26 / 9',
  layoutPadding: '1rem 5% 1rem',
  maxWrapperWidth: '85rem',
  maxWrapperWidthDownFromDesktop: '50rem',
  topTitlePadding: '2.5rem',
  foregroundTranslate: 'translateX(35vw)',
  foregroundMobileTranslate: 'unset',
  mobileForegroundHeight: '10rem',
  mobileForegroundWidth: 'auto',
  mobileForegroundDisplay: 'none',
  textShadow: '1px 1px 1px #2a3c4a',
  bgLayerHeight: '100%',
};
