import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { LocationProvider } from '@reach/router';
import styled from 'styled-components';
import devices from '../../../../../js/styles/Devices';

import ExtraFormFunctionalityWrapper from '../../ExtraFormFunctionalityWrapper';
import Form from '../../../../common/Form';
import Disclaimer from '../../../../utils/Disclaimer/Disclaimer';

const Wrapper = styled.div`
  max-width: 45rem;
`;

const FormBlockWrap = styled.div`
  && {
    position: relative;
    background-color: var(--white-opacity);
    text-align: left;
    max-width: 50rem;
    box-shadow: var(--form-shadow);
    padding-top: 0;
    margin: auto;
  }
`;

const FormFrame = styled.div`
  && {
    margin: auto;
    margin-top: auto;
    width: 48rem;
    max-width: 48rem;
    background-color: var(--white);
    border-radius: 1rem;

    @media ${devices.downFromTablet} {
      margin-top: auto;
      width: 100%;
    }
  }
`;

const FormBlock = ({
  show,
  disclaimer,
  forwardRef,
  exampleLoan,
  title,
  partnerForm,
  prefilledApplicationForm,
  prefilledValues,
  subtitle,
  type,
  aboveFold,
  ...rest
}) => {
  if (!show) return null;

  return (
    <>
      <Wrapper>
        <FormBlockWrap
          ref={forwardRef}
          id="form"
        >
          <FormFrame>
            <ExtraFormFunctionalityWrapper>
              <LocationProvider>
                {(context) => (
                  <>
                    <Form
                      key="form-block"
                      exampleLoan={exampleLoan}
                      title={title}
                      subtitle={subtitle}
                      location={context.location}
                      partnerForm={partnerForm}
                      prefilledApplicationForm={prefilledApplicationForm}
                      prefilledValues={prefilledValues}
                      type={type[0]}
                      {...rest}
                    />
                  </>
                )}
              </LocationProvider>
            </ExtraFormFunctionalityWrapper>
          </FormFrame>
        </FormBlockWrap>
        <Disclaimer isAboveFold={aboveFold} />
      </Wrapper>
    </>
  );
};

FormBlock.propTypes = {
  show: PropTypes.bool,
  disclaimer: PropTypes.string,
  inputSource: PropTypes.string,
  advisaSource: PropTypes.string,
  forwardRef: PropTypes.shape(),
  exampleLoan: PropTypes.shape(),
  title: PropTypes.string,
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  subtitle: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  aboveFold: PropTypes.bool,
};

FormBlock.defaultProps = {
  show: true,
  type: [''],
  disclaimer: null,
  inputSource: '',
  advisaSource: '',
  forwardRef: null,
  exampleLoan: null,
  aboveFold: false,
  title: null,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  subtitle: null,
};

export default FormBlock;

export const formBlockQuery = graphql`
  fragment formBlockFields on Form {
    show
    type
    title
    subtitle
    type
    inputSource
    advisaSource
  }
`;
