import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { brand } from '../../../js/localizations/current-locale';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import SectionWrapper from '../../common/SectionWrapper';
import StepByStepGuideBlock from '../../blocks/StepByStepGuideBlock';
import FormBlock from '../../blocks/FormBlock';
import { useOptions } from '../../../context/options';
import ImageBlock from '../../blocks/ImageBlock';

import devices from '../../../js/styles/Devices';

import { getTitleFontFamily, getFontFamily } from '../../../js/utils/sectionHelper';
import style from './style';

const Text = styled.div`
  && {
    font-size: var(--text-font-size);

    h1, h2, h2.span {
      font-family: ${style.h1FontFamily};
    }

    p {
      font-size: var(--text-font-size);
    }

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '.';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const PitchBlock = styled.div`
  && {
    display: grid;
    grid-template-columns: ${(props) => props.gridColumns};
    grid-template-rows: 1fr;
    grid-gap: ${(props) => props.gridGap};
    margin: 0 auto;

    @media ${devices.downFromTablet} {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem 0;
      display: block;
      padding: ${(props) => props.paddingDownLaptop};
      text-align: center;
    }

    @media ${devices.laptop} {
      padding: ${(props) => props.paddingLaptop};
    }

    @media ${devices.upFromLargeDesktop} {
      padding: ${(props) => props.paddingUpLaptop};
    }
  }
`;

const TextBlockContainer = styled.div`
  && {
    font-family: var(--font-family);

    h1, h2, h2.span  {
      font-family: ${style.h1FontFamily};
    }
  }
`;

const TextBlockMobile = styled(Text)`
  && {
    font-family: var(--font-family);
    display: none;

    @media ${devices.downFromTablet} {
      display: ${style.textBlockMobileDisplay};
      padding-bottom: 2rem;

      h1, h2, h2.span {
        font-family: ${style.h1FontFamily};
        font-weight: var(--has-no-font-weight);
        font-size: ${(props) => props.fontSizeTitle};
        letter-spacing: normal;
      }
    }
  }
`;

const TextBlockDesktop = styled(Text)`
  && {
    font-family: var(--font-family);
    display: block;

    h1, h2, h2.span {
      font-family: ${style.h1FontFamily};
      font-weight: var(--has-font-weight);
      font-size: ${(props) => props.fontSizeTitle};
      letter-spacing: 0.03em;
    }

    > div {
      font-size: var(--text-font-size) !important;
    }

    @media ${devices.downFromTablet} {
      display: none;
    }
  }
`;

const StepByStepGuide = styled(StepByStepGuideBlock)`
  && {
    margin-top: 2rem;
    font-family: var(--font-family);
    @media ${devices.downFromTablet} {
      display: none;
    }
  }
`;

const CampaignWrapper = styled.div`
  && {
    width: 12rem;
    height: 12rem;
    clip-path: circle(50% at 50% 50%);
    background-color: ${(props) => props.background};
    margin: 0 auto;

    @media ${devices.downFromTablet} {
      margin: 1rem auto 1rem;
    }
  }
`;

const CampaignText = styled.div`
  && {
    color: ${(props) => props.color};
    text-align: center;
    margin: auto;
    padding: 30% 1rem 1rem;
    font-family: var(--font-family);
    p {
      font-size: 1rem;
    }
    h1, h2, h2.span {
      font-size: 2rem;
      font-family: ${style.h1FontFamily};
    }
  }
`;

const CampaignLink = styled.a`
  && {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Image = styled(ImageBlock)`
  && {
    height: 100%;
    width: 100%;
  }
`;

const AboveFoldWithFormWithoutHeroSection = ({ acf }) => {
  const { fontFamily } = acf.sectionSettings;
  const { optionCampaigns } = useOptions();

  const campaign = optionCampaigns?.campaigns?.find((c) => c.name === acf.campaignName);

  const isSambla = brand === 'sambla';
  const desktopPitch = acf.pitchText && acf.pitchText;
  const mobilePitch = acf.pitchTextMobile ? acf.pitchTextMobile : desktopPitch;
  const paddingAdvisa = {
    paddingDownLaptop: '5rem 3% 1rem',
    paddingLaptop: '10rem 5% 4rem',
    paddingUpLaptop: '10rem 15% 1rem',
  };

  const paddingSambla = {
    paddingDownLaptop: '3.2rem 3% 1rem',
    paddingLaptop: '10rem 5% 4rem',
    paddingUpLaptop: '10rem 15% 1rem',
  };

  const PitchTextComponent = (
    <TextBlockContainer
      fontFamily={getTitleFontFamily(fontFamily)}
    >
      {mobilePitch
      && (
      <TextBlockMobile
        fontFamily={getFontFamily(fontFamily)}
        fontWeight={style.textBlockMobileFontWeight}
        fontSizeTitle={style.textBlockMobileFontSizeTitle}
        dangerouslySetInnerHTML={createMarkup(mobilePitch)}
      />
      )}
      {desktopPitch
      && (
      <TextBlockDesktop
        fontFamily={getFontFamily(fontFamily)}
        fontWeight={style.textBlockDesktopFontWeight}
        fontSizeTitle={style.textBlockDesktopFontSizeTitle}
        dangerouslySetInnerHTML={createMarkup(desktopPitch)}
      />
      )}
      <StepByStepGuide
        fontFamily={getFontFamily(fontFamily)}
        {...acf.guideSteps}
        small
      />
    </TextBlockContainer>
  );

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
      noContainer
      className={acf.sectionSettings.backgroundColor === 'samblaSwirlyM' ? 'o-page-section__swirly-m' : ''}
      disablePadding
    >
      <PitchBlock
        paddingDownLaptop={isSambla
          ? paddingSambla.paddingDownLaptop : paddingAdvisa.paddingDownLaptop}
        paddingLaptop={isSambla
          ? paddingSambla.paddingLaptop : paddingAdvisa.paddingLaptop}
        paddingUpLaptop={isSambla
          ? paddingSambla.paddingUpLaptop : paddingAdvisa.paddingUpLaptop}
        gridGap={style.gridGap}
        gridColumns={campaign ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}
      >
        {PitchTextComponent}
        {campaign && (
          <CampaignWrapper background={style.campaignWrapperBackground}>
            <CampaignLink href={`#${campaign.name}`}>
              {campaign?.media?.svgImage
                ? <Image imageObject={campaign?.media} />
                : (
                  <CampaignText
                    fontFamily={getFontFamily(fontFamily)}
                    dangerouslySetInnerHTML={createMarkup(campaign?.info)}
                    color={style.campaignTextColor}
                  />
                )}
            </CampaignLink>
          </CampaignWrapper>
        )}
        <FormBlock
          {...acf.form}
          aboveFold
          color={style.formBlockColor}
        />
      </PitchBlock>
    </SectionWrapper>
  );
};

AboveFoldWithFormWithoutHeroSection.propTypes = {
  acf: PropTypes.shape({
    pitchText: PropTypes.string,
    pitchTextMobile: PropTypes.string,
    guideSteps: PropTypes.shape(StepByStepGuideBlock.propTypes),
    sectionSettings: SectionWrapper.propTypes.settings,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
    fadingBackground: PropTypes.bool,
    campaignName: PropTypes.string,
  }).isRequired,
};

export default AboveFoldWithFormWithoutHeroSection;
