export default {
  expandButtonWidth: '18rem',
  expandButtonMargin: '0 auto',
  expandButtonBorderRadius: '1000px',
  expandButtonBorder: '2px solid var(--green)',
  h2FontSize: '2rem',
  paddingBottom: 0,
  height: '10rem',
  imagePosition: 'absolute',
  fontWeight: 'bold',
  titleFontWeight: 'bold',
  postTitleColor: 'var(--white)',
  postTitlePosition: 'relative',
  textAlign: 'center',
  postTitleHeight: '10rem',
  postTitleMargin: 0,
  display: 'flex',
  displayPostTitle: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  postDescriptionHeight: '8.4rem',
  postDescriptionBackgroundColor: 'var(--soft-grey:)',
  buttonBackgroundColor: 'var(--green)',
  buttonColor: 'var(--white)',
  bottom: '9rem',
  transform: 'scale(2.0, 3.25)',
  mockLinkDisplay: 'inline-block',
  mockLinkColor: 'var(--green)',
  mockLinkMainColor: 'var(--mbc)',
  paddingIcon: '0.3rem',
  titleMarginBottom: '2.5rem',
  postsMargin: '-1rem 0',
  linkBG: 'var(--white)',
};
