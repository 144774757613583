const createConfig = (acf, style) => (
  {
    general: {
      wrapperPosition: 'relative',
      hero: {
        backgroundColor: 'url("https://rahalaitos-assets.s3.eu-west-1.amazonaws.com/images/rahalaitos-hero-background-image.webp")', // eslint-disable-line
        foregroundImage: 'https://rahalaitos-assets.s3.eu-west-1.amazonaws.com/images/rahalaitos-hero-foreground-image.webp', // eslint-disable-line
        // New image
        // foregroundImage: 'https://rahalaitos-assets.s3.eu-west-1.amazonaws.com/images/rahalaitos-hero-foreground-image-new-crop.webp', // eslint-disable-line
      },
    },
    columns: [
      {
        flexSize: '40%',
        components: [
          {
            // desktop StepByStep
            component: 'StepByStepGuideBlock',
            acf: { ...acf.guideSteps },
            props: {
              showComponent: 'return evaluationValues.desktop', // if screen size is from 80 rem
              aboveFold: true,
            },
          },
        ],
      },
      {
        components: [
          {
            component: 'FormBlock',
            acf: { ...acf.form },
            props: {
              color: style.formBlockColor,
              aboveFold: true,
            },
          },
          {
            // desktop USP
            component: 'USPBlock',
            acf: { ...acf.stepper },
            props: {
              showComponent: 'return evaluationValues.desktop',
            },
          },
          {
            // mobile USP
            component: 'USPBlock',
            acf: { ...acf.stepper },
            props: {
              showComponent: 'return evaluationValues.mobile',
            },
          },
        ],
      },
      {
        flexSize: '35%',
        components: [],
      },
    ],
  }
);

export default createConfig;
