// internal
import { region } from '../../localizations/current-locale';
import { valid, invalid } from '../../utils/validators/resolutions';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';

import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateUnsubPhoneNumber = (input = '', ...props) => {
  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.phoneNumber'
      ? invalid(messages.phoneNumberRequired)
      : invalid(messages.coApplicantPhoneNumberRequired));
  }
  const normalizedInput = normalizePhoneNumber(input);

  if (normalizedInput.length > 17) {
    return invalid(messages?.phoneNumberTooLong);
  }

  if (normalizedInput.length < 10) {
    return invalid(messages?.phoneNumberTooLong);
  }

  if (!/^\d+$/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberWrongNumber);
  }

  return valid();
};

export default validateUnsubPhoneNumber;

