import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { useOptions } from '../../../context/options';
import devices from '../../../js/styles/Devices';

const ImageBlock = React.lazy(() => import('../../blocks/ImageBlock'));

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7))
  }
`;

const CarouselTrack = styled.div`
  animation: ${scroll} 50s linear infinite;
  display: flex;
  width: calc(250px * 14);
  align-items: center;
`;

const CreditorCard = styled(ImageBlock)`
  width: 20vw;
  height: auto;
  margin: 0 2rem;
  aspect-ratio: 3/2;
  object-fit: contain;

  @media ${devices.upFromMobile} {
    width: 8vw;
    margin: 0 4rem;
  }
`;

const Carousel = styled.div`
  background: white;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  padding: 1rem 0;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
`;

const creditorList = (creditors) => creditors.map((c) => (
  <CreditorCard
    key={`creditor-${c.title}`}
    imageObject={c.logo}
  />
),
);

const CreditorsCarouselSection = ({
  acf,
}) => {
  const { optionCreditors, optionMortgageCreditors } = useOptions();

  const items = acf.mortgage
    ? creditorList(optionMortgageCreditors.mortgageCreditorsAcf)
    : creditorList(optionCreditors.creditorsAcf);

  return (
    <Carousel>
      <CarouselTrack>
        {items}
      </CarouselTrack>
    </Carousel>
  );
};

CreditorsCarouselSection.propTypes = {
  acf: PropTypes.shape({
    mortgage: PropTypes.bool,
  }).isRequired,
};

export default CreditorsCarouselSection;
