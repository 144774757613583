import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import useEffectOnce from '../../../js/hooks/useEffectOnce';
import {
  setCookie,
  isCookieSet,
  getCookie,
} from '../../../js/utils/cookies';

import { useOptions } from '../../../context/options';
import LanguageContext from '../../../context/language';
import LinkBlock from '../../blocks/LinkBlock';
import devices from '../../../js/styles/Devices';
import style from './style';

const FooterWrapper = styled.footer`
  && {
    display: ${style.displayWrapper};
    background-color: ${style.backgroundColor};
    padding: 0 1rem 3rem;
  }
`;

const FooterContainer = styled.div`
  && {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media ${devices.upFromTablet} {
      max-width: 60rem;
      display: flex;
      flex-direction: column;
    }

    @media ${devices.downFromTablet} {
      max-width: 40rem;
    }
  }
`;

const LinkBlockWrapper = styled(LinkBlock)`
  && {
    display: block;
    padding: ${style.linkPadding};
    text-decoration: ${style.textDecoration};
    color: ${style.linkColor};
    font-size:  ${style.fontSize};

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Columns = styled.div`
  && {
    padding-top: ${style.paddingTop};

    @media ${devices.upFromTablet} {
      display: flex;
      order: 1;
      column-gap: 4rem;
      padding-top: ${style.paddingTopDesktop};
    }

    @media ${devices.downFromTablet} {
      padding-top: ${style.columnsPaddingTopMobile};
    }
  }
`;

const Column = styled.div`
  && {
    &:not(:first-of-type) {
      margin-top: ${style.columnMarginTop};
    }

    p {
      color: ${style.textColor};
    }

    a {
      color: ${style.linkColor};
    }

    @media ${devices.upFromTablet} {
      flex: 1;

      &:not(:first-of-type) {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const ColumnTitle = styled.p`
  && {
    font-size: ${style.titleFontSize};
    margin-bottom: 0.2rem;
    font-weight: ${style.fontWeightTitle};
  }
`;

const Info = styled.div`
  && {
    padding-top: ${style.infoPaddingTop};
    font-size: var(--text-font-size);

    @media ${devices.upFromTablet} {
      order: 3;
      padding-top: ${style.paddingTop};
    }

    & p {
      color: ${style.pTagColor};
      font-size: ${style.fontSize};

      &:not(:first-child) {
        margin-top: 0.5em;
      }
    }

    & a {
      color: ${style.linkColor};
      font-size: ${style.fontSize};
      padding: 5px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const CookiebotRenewalContainer = styled.div`
  && {
    padding-top: 2rem;
    text-align: center;

    @media ${devices.upFromTablet} {
      order: 3;
      padding-top: ${style.paddingTop};
    }
  }
`;

const CookiebotRenewalButton = styled.a`
  && {
    cursor: pointer;
    font-size: ${style.fontSize};
    text-decoration: none;
    color: ${style.pTagColor};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterText = styled.div`
  && {

    a {
      color: ${style.linkColor};
      font-size: var(--text-font-size);
    }

    p {
      color: ${style.linkColor};
      font-size: ${style.fontSize};
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }
    }

    img {
      height: auto;
      width: ${style.logoSize};
    }
  }
`;

const ColumnsWrapper = ({ columns }) => (
  <Columns>
    {columns.map((column, i) => (
      <Column
        key={`footer-column-${i}`}
      >
        <ColumnTitle>
          {column.title}
        </ColumnTitle>

        {column.links && (
          <div>
            {column.links.map((link, j) => (
              <LinkBlockWrapper
                {...link}
                key={`footer-link-${i}-${j}`}
              />
            ))}
          </div>
        )}

        {column.text && (
          <FooterText
            dangerouslySetInnerHTML={createMarkup(column.text)}
          />
        )}
      </Column>
    ))}
  </Columns>
);

const InfoWrapper = ({ info }) => (
  <Info
    dangerouslySetInnerHTML={createMarkup(info)}
  />
);

const renewCookiebot = () => {
  if (typeof window !== 'undefined' && window?.Cookiebot) window.Cookiebot.renew();
};

export const Cookiebot = ({ cookiebotButtonText }) => (
  <CookiebotRenewalContainer>
    <CookiebotRenewalButton onClick={() => renewCookiebot()}>
      {cookiebotButtonText}
    </CookiebotRenewalButton>
  </CookiebotRenewalContainer>
);

const Footer = () => {
  const { lang } = useContext(LanguageContext);
  const { optionFooter } = useOptions();

  const {
    columns = [],
    info = '',
    cookiebotButtonText = '',
  } = optionFooter || {};

  useEffectOnce(() => {
    if ((process.env.REACT_APP_LANGUAGE !== lang && !isCookieSet('language'))
    || (isCookieSet('language') && getCookie('language') !== lang)) {
      setCookie('language', lang, process.env.REACT_APP_LANGUAGE === lang ? 0 : 300);
    }
  });

  const renderConditions = useMemo(() => ({
    hasColumns: columns.length,
    hasInfo: info,
    showCookiebot: cookiebotButtonText?.length > 0,
  }), [columns, info, cookiebotButtonText]);

  return (
    <FooterWrapper>
      <FooterContainer>
        {renderConditions.hasColumns && (
          <ColumnsWrapper columns={columns} />
        )}
        {renderConditions.hasInfo && (
        <InfoWrapper info={info} />
        )}
        {renderConditions.showCookiebot && (
          <Cookiebot cookiebotButtonText={cookiebotButtonText} />
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

ColumnsWrapper.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.shape(LinkBlock.propTypes)),
      text: PropTypes.string,
      textFirst: PropTypes.bool,
    }),
  ).isRequired,
};

InfoWrapper.propTypes = {
  info: PropTypes.string.isRequired,
};

Cookiebot.propTypes = {
  cookiebotButtonText: PropTypes.string.isRequired,
};

export default Footer;
