import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import SvgIcon from '../../atoms/SvgIcon';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import devices from '../../../js/styles/Devices';
import style from './style';

const USPSamblaForm = styled.div`
  && {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    display: ${(props) => props.display};
    @media ${devices.downFromTablet} {
      padding: 0.3rem 0;
      align-items: center;
      text-align: center;
    }
  }
`;

const Item = styled.div`
  && {
    padding-bottom: 1rem;
    font-family: var(--font-family);
    @media ${devices.downFromTablet} {
      padding-bottom: 0.2rem;
    }
  }
`;

const Text = styled.div`
  && {
    font-size: var(--text-font-size);
    line-height: 1.3rem;
    &:before {
      position: relative;
      top: 0.15rem;
      margin-right: 0.2rem;
      content: ${(props) => `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:v='https://vecta.io/nano' width='15' height='15'%3e%3cpath d='M14.444 1.304L13.963 0c-3.491 2.038-6.739 5.583-9.148 9.13L1.444 5.87 0 7.826 4.815 15c2.197-5.258 5.176-10.516 9.63-13.696z' fill='${props.fill}' fill-rule='evenodd'/%3e%3c/svg%3e")`};
    }
  }
`;

const itemStyle = css`
  && {
    &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      @media ${devices.upFromTablet} {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
  }
`;

const Block = styled.div`
  && {
    margin: ${style.uspMargin};
    padding: ${style.uspPadding};
    background-color: ${style.backgroundColor};
    max-width: 55rem;
    &>div:last-child {
      >svg {
        display: none;
      }
    }
    @media ${devices.upFromTablet} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @media ${devices.downFromDesktop} {
      border-top: ${style.borderTop};
      margin: ${style.mobileUspMargin};
      max-width: 58rem;
    }
  }
`;

const BlockItem = styled.div`
  && {
    ${itemStyle}
    display: inline-flex;
    align-items: center;
    @media ${devices.upFromTablet} {
      flex-grow: 1;
      justify-content: ${style.uspJustifyContent};
      padding: 0 1rem;
    }
    @media ${devices.downFromSmallTablet} {
      justify-content: space-between;
    }
  }
`;

const RegularUSPWrapper = styled.div`
  display: ${(props) => props.display};
`;

const BlockItemIcon = styled(SvgIcon)`
  && {
    display: ${(props) => props.display};
    color: ${style.iconColor};
    margin-right: 0.5em;
  }
`;

const BlockItemText = styled.div`
  && {
    line-height: ${style.lineHeight};
    font-size: ${style.uspFontSize};
    font-weight: ${style.uspFontWeight};
    color: ${style.uspFontColor};
    margin-left: ${style.marginLeft};
    padding-right: 1rem;
  }
`;

const renderSamblaUSP = (items, fontFamily) => items?.map((item, i) => (
  <Item
    fontFamily={getFontFamily(fontFamily)}
    key={`usp-item-${i}`}
  >
    <Text
      fill={style.fill}
    >
      {item.text}
    </Text>
  </Item>
));

const USPBlock = ({
  items,
  sectionSettings,
}) => {
  const { fontFamily } = sectionSettings;

  return (
    <>
      <USPSamblaForm display={style.samblaStyleUSPDisplay}>
        {renderSamblaUSP(items, fontFamily)}
      </USPSamblaForm>
      <RegularUSPWrapper display={style.samblaStyleUSPDisplay === 'block' ? 'none' : 'block'}>
        {items && (
          <Block>
            {items?.map((item, i) => (
              <BlockItem key={`usp-item-${i}`}>
                <BlockItemIcon
                  display={style.prefixIconDisplay}
                  icon={['fas', 'check']}
                  size="lg"
                />
                <BlockItemText>{item.text}</BlockItemText>
                <BlockItemIcon
                  display={style.suffixIconDisplay}
                  icon={['fal', 'chevron-double-right']}
                  size="sm"
                />
              </BlockItem>
            ))}
          </Block>
        )}
      </RegularUSPWrapper>
    </>
  );
};

USPBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ).isRequired,
  sectionSettings: PropTypes.shape(),
};

USPBlock.defaultProps = {
  sectionSettings: {},
};

export default USPBlock;

export const uspBlockQuery = graphql`
  fragment uspBlockFields on USP {
    items {
      text
    }
  }
`;
