import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';
import sizes from '../../../js/styles/Sizes';
import SectionWrapper from '../../common/SectionWrapper';

const Wrapper = styled(SectionWrapper)`
  && {
    padding: ${sizes.sectionPadding} 1rem;
  }
`;

const NavContainer = styled.div`
  && {
    position: sticky;
    top: ${sizes.desktopHeaderHeight};
    background: var(--off-white);
    z-index: 1;
    box-shadow: 0px 2px 12px -1px rgb(0 0 0 / 20%);

    @media ${devices.mobile} {
      display: none
    }
  }
`;

const Nav = styled.nav`
  && {
    width: ${sizes.screenDesktopWidth};
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
`;

const linkStyle = css`
  && {
    &:hover {
      background: var(--transparent-dark-grey-05);
    }
  }
`;

const Link = styled.a`
  && {
    ${linkStyle}
    flex: 1;
    font-size: var(--text-font-size);
    padding: 1rem 0;
    text-align: center;
    text-decoration: none;
    color: ${(props) => props.color};
    pointer-events: ${(props) => props.pointer};
  }
`;

const Container = styled.div`
  && {
    max-width: ${sizes.screenDesktopContain};
    margin: auto;
    padding-top: 3.5rem;

    @media ${devices.downFromMobile} {
      padding-top: 1.5rem;
    }
  }
`;

const HeadingOne = styled.h1`
  && {
    font-size: var(--h1-desktop-font-size);
    font-weight: 500;
    letter-spacing: 0.03em;

    @media ${devices.downFromTablet} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const Subtitle = styled.p`
  && {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const headerStyle = css`
  && {
    &:first-of-type {
        margin-top: 0;
      }

    &::before {
      display: block;
      height: 5rem;
      content: '';
      margin-top: -5rem;
      visibility: hidden;

      @media ${devices.desktop} {
        height: 10rem;
        margin-top: -10rem;
      }
    }
  }
`;

const Header = styled.h2`
  && {
    ${headerStyle}
    color: var(--advisa-blue);
    margin-top: 2rem;
  }
`;

const Words = styled.dl`
  && {
    background: var(--transparent-dark-grey-05);
    border-radius: ${sizes.defaultBorderRadius};
    padding: 2rem 1rem;

    @media ${devices.upFromTablet} {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
`;

const WordTitle = styled.dt`
  && {
    font-size: var(--h4-desktop-font-size);
    font-weight: bold;
    margin-bottom: 0.5em;
  }
`;

const wordDescriptionStyle = css`
  && {
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
  }
`;

const WordDescription = styled.dd`
  && {
    ${wordDescriptionStyle}
    margin-left: 0;

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--advisa-blue);
          content: '·';
          font-weight: var(--has-font-weight);
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const group = (words) => (
  (words || []).reduce((resolvedGroups, word) => {
    const firstLetter = word.title.charAt(0).toUpperCase();
    return {
      ...resolvedGroups,
      [firstLetter]: (resolvedGroups[firstLetter] || [])
        .concat(word)
        .sort((a, b) => a.title.localeCompare(b.title)),
    };
  }, {})
);

const WordlistSection = ({ acf }) => {
  const sortedGroups = group(acf.words);
  const headers = Object.keys(sortedGroups).sort();

  const renderWords = (header, i) => (
    <div key={i}>
      <Header id={header}>
        {header}
      </Header>
      <Words>
        {sortedGroups[header].map(({ title, description }) => (
          <React.Fragment key={title}>
            <WordTitle>{title}</WordTitle>
            <WordDescription
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          </React.Fragment>
        ))}
      </Words>
    </div>
  );

  return (
    <Wrapper
      settings={acf.sectionSettings}
    >
      <NavContainer>
        <Nav>
          {ALPHABET.map((letter) => (
            <Link
              key={`wordlist-link-${letter}`}
              color={(!sortedGroups[letter]) ? 'var(--transparent-black-20)' : 'var(--advisa-blue)'}
              pointer={(!sortedGroups[letter]) ? 'none' : ''}
              href={sortedGroups[letter] ? `#${letter}` : null}
            >
              {letter}
            </Link>
          ))}
        </Nav>
      </NavContainer>

      <Container>
        {acf.title && (
          <HeadingOne>{acf.title}</HeadingOne>
        )}
        {acf.subtitle && (
          <Subtitle>{acf.subtitle}</Subtitle>
        )}

        {headers.map((header, i) => renderWords(header, i)) }

      </Container>
    </Wrapper>
  );
};

WordlistSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    words: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ),
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

export default WordlistSection;
