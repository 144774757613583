// Condtions to display BankID button:
// -> showBankdIdSign (true)
// -> prefilledApplicationForm(false)
// -> partnerForm(false)
// -> currentSlide === 0
// -> brand === 'advisa'
// -> isPartnerPage(false)

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import originCheck from './origin-check';

const baseUrl = process.env.GATSBY_FRONTEND_BASE_URL;

const showBankID = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentSlide = useSelector((state) => state.form.genericFormValues.currentSlide);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showBankIdSign, setShowBankIdSign] = useState();

  const isPartnerPage = () => {
    if (typeof window === 'undefined') return null;
    return (
      ['https://advisa.se/partner/'].includes(window.location.href));
  };

  if (isPartnerPage()) {
    setShowBankIdSign(false);
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    currentSlide === 0 ? setShowBankIdSign(true) : setShowBankIdSign(false);
    if (originCheck(baseUrl) === 'advisa.se') {
      setShowBankIdSign(false);
    }
  }, [currentSlide]);

  return showBankIdSign || null;
};

export default showBankID;
