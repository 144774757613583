import React, { useEffect, useState } from 'react';

const withTwoPassRendering = (WrappedComponent) => ({
  /* eslint-disable-next-line react/prop-types */
  children,
  ...rest
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  return (
    <WrappedComponent
      {...rest}
      key={isClient}
    >
      {children}
    </WrappedComponent>
  );
};

export default withTwoPassRendering;
