import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import style from './style';
import devices from '../../../js/styles/Devices';

import SectionWrapper from '../../common/SectionWrapper';
import withSquishing from '../../utils/withSquishing';

const Wrapper = styled(SectionWrapper)`
  && {
    display: flex;
    align-items: center;
    min-height: 7rem;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'green')};
    width: ${(props) => props.textSectionWidth}%;
    margin: 0 auto;
  }
`;

const SectionWrapContainer = styled.div`
  && {
    background-color: ${(props) => props.textFrameBackgroundColor};
  }
`;

const SuperTitle = styled.p`
  && {
    font-family: var(--title-font);
    text-align: ${(props) => props.position};
    color: var(--transparent-dark-grey-50);
    margin-bottom: 0.4em;
    padding-top: ${(props) => props.paddingTop};
  }
`;

const Title = styled.h1`
  && {
    font-family: var(--title-font);
    text-align: ${(props) => props.position};
    font-size: var(--h1-desktop-font-size);
    letter-spacing: var(--has-letter-spacing);
    line-height: 1.1;
    margin: 0;
    padding-top: ${(props) => props.paddingTop};
    color: ${(props) => props.color};

    @media ${devices.downFromDesktop} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const Subtitle = styled.p`
  && {
    font-family: var(--font-family);
    text-align: ${(props) => props.position};
    font-size: var(--h4-desktop-font-size);
    font-weight: normal;
    margin: ${style.margin};
  }
`;

const PageTitleSection = ({ acf }) => {
  const {
    titlePosition,
    titleColor,
    backgroundColor,
  } = acf.sectionSettings;

  return (
    <SectionWrapContainer
      textFrameBackgroundColor={acf.textFrameBackgroundColor}
    >
      <Wrapper
        settings={acf.sectionSettings}
        backgroundColor={backgroundColor}
        textSectionWidth={acf.textSectionWidth}
      >
        {acf.supertitle && (
        <SuperTitle
          paddingTop={acf.paddingAtTop === 'supertitle' ? '3rem' : 0}
          position={titlePosition}
        >
          {acf.supertitle}
        </SuperTitle>
        )}
        <Title
          position={titlePosition}
          paddingTop={acf.paddingAtTop === 'pagetitle' ? '3rem' : 0}
          color={titleColor}
        >
          {acf.title}
        </Title>
        {acf.subtitle && (
        <Subtitle
          position={titlePosition}
        >
          {acf.subtitle}
        </Subtitle>
        )}
      </Wrapper>
    </SectionWrapContainer>
  );
};

PageTitleSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string.isRequired,
    supertitle: PropTypes.string,
    paddingAtTop: PropTypes.bool,
    subtitle: PropTypes.string,
    textFrameBackgroundColor: PropTypes.string,
    textSectionWidth: PropTypes.string,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

export default withSquishing(PageTitleSection);
