import React from 'react';
import PropTypes from 'prop-types';

import { useOptions } from '../../../context/options';
import replaceHostName from '../../utils/replace-host-name';

const PureLocalBusinessDataSection = ({ acf, variables, header }) => {
  const {
    logo = {
      link: {},
      image: {},
    },
  } = header;

  const {
    postAddress, phoneNumber, openingHours, priceRange,
  } = variables;

  const imageUrl = ({ image }) => (
    image.svgImage
    ? replaceHostName( //eslint-disable-line
      image.svgImage.url.source_url, //eslint-disable-line
      process.env.GATSBY_FRONTEND_BASE_URL, //eslint-disable-line
    ) : ''); //eslint-disable-line

  const localBusinessStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: acf.name,
    url: process.env.GATSBY_FRONTEND_BASE_URL,
    image: imageUrl(logo),
    sameAs: [
      acf.trustpilotUrl,
      acf.facebookUrl,
      acf.linkedinUrl,
      acf.twitterUrl,
      acf.youtubeUrl,
      acf.googlePlusUrl,
    ],
    logo: imageUrl(logo),
    address: {
      '@type': 'PostalAddress',
      streetAddress: postAddress?.box,
      addressRegion: postAddress?.city,
      postalCode: postAddress?.zip,
      addressCountry: postAddress?.country,
    },
    openingHours: [
      openingHours,
    ],
    telephone: phoneNumber,
    priceRange,
  };

  return (
    <script type="application/ld+json">
      {`${JSON.stringify(localBusinessStructuredData)}`}
    </script>
  );
};

PureLocalBusinessDataSection.propTypes = {
  acf: PropTypes.shape({
    name: PropTypes.string,
    trustpilotUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    googlePlusUrl: PropTypes.string,
  }).isRequired,
  variables: PropTypes.shape(),
  header: PropTypes.shape(),
};

PureLocalBusinessDataSection.defaultProps = {
  variables: {},
  header: {},
};

const LocalBusinessDataSection = (props) => {
  const { optionVariables, optionHeader } = useOptions();
  return (
    <PureLocalBusinessDataSection
      {...props}
      variables={optionVariables}
      header={optionHeader}
    />
  );
};

export { PureLocalBusinessDataSection };
export default LocalBusinessDataSection;
