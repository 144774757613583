import { valid, invalid } from './resolutions';

const validateEmail = (input = '') => {
  if (!/\S+/.test(input || '')) {
    return invalid;
  }

  if (/\s/.test(input || '')) {
    return invalid;
  }

  if (input.indexOf('@') === -1) {
    return invalid;
  }

  if (/@.*@/.test(input)) {
    return invalid;
  }

  if (!/.@/.test(input)) {
    return invalid;
  }

  if (!/@./.test(input)) {
    return invalid;
  }

  if (!/@.+\.[^.]{2,}$/.test(input)) {
    return invalid;
  }

  if (/@.+\.\./.test(input)) {
    return invalid;
  }

  if (/\.@/.test(input)) {
    return invalid;
  }

  return valid();
};

export default validateEmail;
