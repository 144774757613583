export default {
  mobileMenuBg: 'var(--white)',
  buttonSvgWidthHeight: '1.5rem',
  mobileMenuButtonColor: 'var(--white)',
  textBgColor: 'var(--white)',
  backgroundColor: 'var(--rahalaitos-dark-blue)',
  linkColor: 'var(--white)',
  linkPosition: 'right',
  fontFamily: 'var(--fontFamily)',
  overlayBackgroundColor: 'var(--header-background)',
  activeLinkColor: 'var(--header-temp-active-color)',
  loginButtonBackgroundColor: 'var(--mbc)',
  loginButtonTextColor: 'var(--tooltip-color)',
  loginButtonBorderRadius: '5px',
  loginIconColor: 'var(--rahalaitos-dark-green)',
  phoneNumberIconColor: 'var(--white)',
};
