import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import SectionWrapper from '../../common/SectionWrapper';
import FormBlock from '../../blocks/FormBlock';
import { getFontFamily, getTitleFontFamily } from '../../../js/utils/sectionHelper';
import style from './style';
import devices from '../../../js/styles/Devices';

const TextWrapper = styled.div`
  && {
    font-family: var(--font-family);
    margin-bottom: 3rem;

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '.';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const Wrapper = styled(SectionWrapper)`
  && {
    text-align: center;
  }
`;

const FormTitle = styled.h2`
  && {
    color: ${(props) => props.color};
    font-size: var(--h2-desktop-font-size);
    text-align: ${style.h2Align};
    font-weight: var(--has-font-weight);
    font-family: var(--font-family);

    @media ${devices.upFromTablet} {
      margin-left: ${style.h2Margin};
    }

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const FormSection = ({
  acf,
}) => (
  <Wrapper
    settings={acf.sectionSettings}
  >
    {acf.title && (
      <FormTitle
        color={acf.sectionSettings.titleColor}
        fontFamily={getTitleFontFamily(acf.sectionSettings.fontFamily)}
      >
        {acf.title}
      </FormTitle>
    )}
    {acf.text && (
      <TextWrapper
        fontFamily={getFontFamily(acf.sectionSettings.fontFamily)}
        dangerouslySetInnerHTML={createMarkup(acf.text)}
      />
    )}
    <FormBlock {...acf.form} color={style.fontColor} />
  </Wrapper>
);

FormSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

export default FormSection;
