import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import SectionWrapper from '../../common/SectionWrapper';
import FormBlock from '../../blocks/FormBlock';
import USPBlock from '../../blocks/USPBlock/USPBlock';

import { brand } from '../../../js/localizations/current-locale';
import devices from '../../../js/styles/Devices';
import style from './style';

const SectionContainer = styled(SectionWrapper)`
  && {
    margin: 0 auto;
    padding-top: 1rem;
    position: relative;
    width: 100%;

    @media ${devices.downFromTablet} {
      margin-top: 0;
    }
    @media ${devices.downFromMobile} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const SectionWrapContainer = styled.div`
  && {
    position: relative;
    background-color: var(--white);
  }
`;

const BackgroundContainer = styled.div`
  && {
    margin: auto;
    position: absolute;
    height: 100%;
    width: 85%;
    max-width: 86rem;
    right: 0;
    left:0;
    top: 0.5rem;
    background-color: var(--white);

    @media ${devices.downFromDesktop} {
      width: 98%;
    }

    @media ${devices.downFromTablet} {
      margin-top: 0;
    }

    @media ${devices.downFromMobile} {
      width: 100%;
      height: 100%;
    }
  }
`;

const Wrapper = styled.div`
  && {
    padding-top: ${(props) => props.topPaddingDesktop};
    display: grid;
    grid-template: auto 1fr / 1fr 1fr;
    width: 100%;
    font-family: var(--font-family);

    @media ${devices.downFromTablet} {
      padding-top: ${(props) => props.topPaddingMobile};
      grid-template-columns: 1fr ;
      grid-template-rows: 1fr ;
    }
  }
`;

const titleStyle = css`
  && {
    font-family: var(--title-font);
    font-size: var(--h1-desktop-font-size);
    text-align: left;
    letter-spacing: var(--has-letter-spacing);

    @media ${devices.downFromTablet} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const headerBlock = css`
  && {
    padding: 0.8rem 0.5rem;
    text-align: center;
    color: ${style.color};
  }
`;

const SectionTitle = styled.h1`
  && {
    ${titleStyle}
    grid-row: 1 / 2;
    grid-column: 1 / 2 ;
    font-weight: var(--has-font-weight);
    color: ${style.titleColor};
    z-index: 1;

    @media ${devices.downFromTablet} {
      ${headerBlock}
      margin-bottom: 0;
      margin-top: 0;
      grid-column: auto;
      grid-row: auto;
      width: 100%;
      font-family: var(--title-font);
      font-size: var(--h1-mobile-font-size);
      border-top-left-radius: 0.9rem;
      border-top-right-radius: 0.9rem;
      background:  ${style.background};
      color: ${style.color};
    }
  }
`;

const SectionContent = styled.div`
  && {
    grid-row: 2 / 3;
    grid-column: 1 / 2 ;
    z-index: 0;
    order: 2;

    @media ${devices.downFromTablet} {
      grid-column: auto;
      grid-row: auto;
      order: 3;
      max-width: 100%;
    }

    @media ${devices.upFromTablet} {
      padding-right: ${style.sectionContPaddingRight};
    }

    @media ${devices.downFromMobile} {
      padding: ${(props) => props.paddingMobile}rem;
      padding-top: ${(props) => props.paddingTop}rem;
      padding-bottom: ${(props) => props.paddingBottom}rem;
    }
  }
`;

const SectionText = styled.div`
  && {
    margin-bottom: 1.5rem;
    margin-right: ${style.marginRightText};
    text-align: left;
    color: ${style.textColor};

    @media ${devices.downFromTablet} {
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: left;
    }

    @media ${devices.downFromMobile} {
      padding: 0 0.5rem;
    }

    a {
      color: var(--link-color);
    }

    p {
      font-size: var(--text-font-size);
    }

    h1 {
      font-family: var(--title-font);
      font-size: var(--h1-desktop-font-size);
      letter-spacing: var(--has-letter-spacing);

      @media ${devices.downFromTablet} {
        font-size: var(--h1-mobile-font-size);
      }
    }

    h2 {
      font-family: var(--sub-font);
      font-size: var(--h2-desktop-font-size);
      font-weight: var(--has-font-weight);
      letter-spacing: var(--has-letter-spacing);

      @media ${devices.downFromTablet} {
        font-size: var(--h2-mobile-font-size);
      }
    }

    h3 {
      font-family: var(--font-family);
      font-size: var(--h3-desktop-font-size);
      font-weight: var(--has-font-weight);
      letter-spacing: var(--has-no-letter-spacing);

      @media ${devices.downFromTablet} {
        font-size: var(--h3-mobile-font-size);
      }
    }

    h4 {
      font-weight: var(--has-font-weight)
    }

    img {
      height: auto;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    ul {
      padding-inline-start: 1rem;
    }

    ul::marker {
      color: ${style.bulletColor};
      text-align: left;
    }

    ul li::before {
      color: ${style.bulletColor};
    }

    li {
      font-size: ${style.liFontSize};
      display: list-item;
    }

    ul li {
      margin: 0.4em 0;
    }

    ol li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    table {
      table-layout: fixed;
      width: 100%;
      display: ${style.tableDisplay};


      @media ${devices.downFromMobile} {
        overflow: auto;
      }
    }

    tbody tr:nth-child(odd) {
      background: ${style.tableRowColorOne};
    }

    tbody tr:nth-child(even) {
      background: ${style.tableRowColorTwo};
    }

    thead {
      border: 1px solid var(--light-grey);
      border-bottom: none;
      border-spacing: 2px;
    }

    tbody {
      border: 1px solid var(--light-grey);
      border-top: none;
      border-spacing: 2px;
      border-color: grey;
    }

    th {
      font-size: .9rem;
      font-weight: normal;
      text-align: center;
      text-align: ${style.tableAlign};
      background-color: ${style.tableHeaderBackgroundColor};
      color: ${style.tableHeaderColor};
      padding: ${style.tablePadding};
    }

    td {
      font-size: .9rem;
      text-align: ${style.tableAlign};
      word-break: break-word;
      padding: 0.5em 0.8em;
    }

    aside {
      margin-top: 1rem;
      border-radius: 14px;
      color: var(--white);
      display: inline-block;
      font-size: 18px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: ${style.asideBackgroundColor};
      padding: 1rem;

      a {
        color:${style.asideLinkColor};
      }
    }
  }
`;

const SectionForm = styled.div`
  && {
    width: 100%;
    margin: 0 auto;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    z-index: 1;
    order: 3;

    @media ${devices.downFromTablet} {
      grid-column: auto;
      grid-row: auto;
    }
  }
`;

const FormContainer = styled.div`
  && {
    @media ${devices.downFromTablet} {
      order: 2;
      padding: ${style.formContainerPadding};
    }
  }
`;

const USPBlockContainer = styled.div`
  && {
    @media ${devices.downFromTablet} {
      ${headerBlock}
      order: 1;
      background: ${style.background};
      padding: 0 0.5rem 0.8rem;
      color: ${style.color};
    }
  }
`;

const FormWithInfoSection = ({ acf, topSection }) => {
  const {
    title,
    usp,
    sectionSettings,
    text,
  } = acf;

  const isSambla = brand === 'sambla';

  return (
    <SectionWrapContainer>
      <SectionContainer
        settings={sectionSettings}
      >
        {isSambla && <BackgroundContainer />}
        <Wrapper
          topPaddingDesktop={topSection ? '3rem' : '2rem'}
          topPaddingMobile={topSection ? '2rem' : '1rem'}
        >
          {title
          && (
          <SectionTitle
            display
          >
            {title}
          </SectionTitle>
          )}
          <SectionForm>
            <FormContainer>
              <FormBlock {...acf.form} color="var(--transparent-dark-grey-90)" />
            </FormContainer>
            {(usp?.items && isSambla) && (
            <USPBlockContainer>
              <USPBlock
                items={usp.items}
                sectionSettings={sectionSettings}
                samblaForm={isSambla}
              />
            </USPBlockContainer>
            )}
          </SectionForm>
          <SectionContent>
            <SectionText
              dangerouslySetInnerHTML={createMarkup(text)}
            />
          </SectionContent>
        </Wrapper>
      </SectionContainer>
    </SectionWrapContainer>
  );
};

FormWithInfoSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
    sectionSettings: SectionWrapper.propTypes.settings,
    usp: PropTypes.shape(),
  }).isRequired,
  topSection: PropTypes.bool,
};

FormWithInfoSection.defaultProps = {
  topSection: false,
};

export default FormWithInfoSection;
