// external
import { nationalId } from '@advisa/common-validators';
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';
import Config from '../../utils/config';

// internal
import { valid, invalid } from '../../utils/validators/resolutions';

let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateNationalId = (input = '') => {
  const config = Config.getValues();
  const minAge = config.RESTRICTIONS.MIN_AGE;
  const maxAge = config.RESTRICTIONS.MAX_AGE;
  const validatedId = nationalId.parse(input, region.toLowerCase());

  if (!/\S+/.test(input)) {
    return invalid(messages.requiredNationalId);
  }

  // Common validation check
  if (validatedId.valid === 'invalid') {
    return invalid(messages.invalidNationalId);
  }

  if (nationalId.age < minAge) {
    return invalid(messages.tooYoungNationalId, { minAge });
  }

  if (nationalId.age > maxAge) {
    return invalid(messages.tooOldNationalId, { maxAge });
  }

  return valid();
};

export default validateNationalId;
