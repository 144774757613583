import { phone } from '@advisa/common-validators';
import { valid, invalid } from '../../utils/validators/resolutions';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validatePhoneNumberNo = (input = '', ...props) => {
  const normalizedInput = normalizePhoneNumber(input);
  const validatedPhone = phone.parse(normalizedInput, region);

  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.phoneNumber'
      ? invalid(messages.phoneNumberRequired)
      : invalid(messages.coApplicantPhoneNumberRequired));
  }

  // Common validation check
  if (validatedPhone.valid === 'invalid') {
    return invalid(messages?.phoneNumberWrongNumber);
  }

  if (/^(47|^0(?!0))/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberWrongBeginning);
  }

  if (/^\+?\d{0,7}$/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberTooShort);
  }

  if (normalizedInput.length > 13) {
    return invalid(messages?.phoneNumberTooLong);
  }

  return valid();
};

export default validatePhoneNumberNo;
