import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';

const Block = styled.div`
  && {
    margin: auto;

    @media ${devices.downFromMobile} {
      margin-top: 2rem;
    }
  }
`;

const Title = styled.h4`
  && {
    text-align: center;

    @media ${devices.upFromTablet}{
      text-align: left;
    }
  }
`;

const Table = styled.table`
  && {
    width: 100%;
    max-width: 20rem;
    font-size: var(--text-font-size);
    margin: auto;
    min-width: 20rem;

    @media ${devices.upFromMobile} {
      margin: 0;
    }
  }
`;

const TableCell = styled.td`
  && {
    padding: 0.2rem 0;

    > {
      &:last-child {
        @media ${devices.upFromMobile} {
          margin-right: 0;
        }
      }
    }
  }
`;

const OpeningHoursBlock = ({
  title,
  calendar,
  squished,
}) => (
  <Block>
    {title
      && (
      <Title
        textAlign={(!squished && devices.desktop) ? 'center' : 'left'}
      >
        {title}
      </Title>
      )}
    <Table>
      <tbody>
        {calendar.map(({ day, time }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr
            key={`opening-hours-${index}`}
          >
            <TableCell>{day}</TableCell>
            <TableCell>{time}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  </Block>
);

OpeningHoursBlock.propTypes = {
  title: PropTypes.string,
  calendar: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
  ).isRequired,
  squished: PropTypes.bool,
};

OpeningHoursBlock.defaultProps = {
  title: null,
  squished: false,
};

export default OpeningHoursBlock;

