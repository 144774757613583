const getTrustpilotMapping = (brand, region) => {
  const trustpilotMappings = {
    SE: {
      advisa: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '5310564d0000640005784d78', ratingValue: '4.7', trustpilotBrand: 'Advisa', trustpilotDomain: 'https://se.trustpilot.com/review/advisa.se',
      },
      sambla: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '55152ede0000ff00057e5303', ratingValue: '4.8', trustpilotBrand: 'Sambla', trustpilotDomain: 'https://se.trustpilot.com/review/sambla.se',
      },
      lanaPengarna: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '55152ede0000ff00057e5303', ratingValue: '4.8', trustpilotBrand: 'Sambla', trustpilotDomain: 'https://se.trustpilot.com/review/sambla.se',
      },
    },
    FI: {
      omalaina: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '5d415c37c38c3300017c39a9', ratingValue: '2.8', trustpilotBrand: 'Omalaina', trustpilotDomain: 'https://fi.trustpilot.com/review/omalaina.fi',
      },
      rahoitu: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '5e1470556a6fea0001d29e92', ratingValue: '4.6', trustpilotBrand: 'Rahoitu', trustpilotDomain: 'https://fi.trustpilot.com/review/rahoitu.fi',
      },
      rahalaitos: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '57e4cee60000ff0005951440', ratingValue: '3.6', trustpilotBrand: 'Rahalaitos', trustpilotDomain: 'https://fi.trustpilot.com/review/rahalaitos.fi',
      },
    },
    NO: {
      sambla: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '5818b0250000ff000596e1d5', ratingValue: '4.8', trustpilotBrand: 'Sambla', trustpilotDomain: 'https://no.trustpilot.com/review/sambla.no',
      },
      digifinans: {
        templateId: '53aa8912dec7e10d38f59f36', businessUnitId: '5e14704c6cea320001a2f32f', ratingValue: '4.6', trustpilotBrand: 'Digifinans', trustpilotDomain: 'https://no.trustpilot.com/review/digifinans.no',
      },
    },
  };

  return trustpilotMappings[region][brand] || {
    templateId: '', businessUnitId: '', ratingValue: '', trustpilotBrand: '', trustpilotDomain: '',
  };
};

export default getTrustpilotMapping;
