import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';

import SectionWrapper from '../../common/SectionWrapper';
import TrustpilotBlock from '../../blocks/TrustpilotBlock';
import { brand, region } from '../../../js/localizations/current-locale';
import getTrustpilotMapping from '../../blocks/TrustpilotBlock/trustpilotMapping';

const Title = styled.span`
  && {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-family: var(--font-family);
    font-size: var(--h1-desktop-font-size);
    font-weight: var(--has-font-weight);
    letter-spacing: var(--has-letter-spacing);
    text-align: left;
    color: var(--black);

    @media ${devices.downFromTablet} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const Subtitle = styled.p`
  && {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: var(--h2-desktop-font-size);

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const Wrapper = styled(TrustpilotBlock)`
  && {
    margin-top: 1rem;
  }
`;

const TrustpilotSection = ({ acf }) => {
  const { ratingValue, trustpilotBrand, trustpilotDomain } = getTrustpilotMapping(brand, region);

  const trustpilotStructuredData = {
    '@context': 'https://schema.org',
    name: acf.trustpilot.productName,
    '@type': 'Product',
    brand: {
      name: trustpilotBrand,
      '@type': 'Brand',
    },
    review: {
      '@type': 'Review',
      author: {
        name: 'Trustpilot',
        '@type': 'Organization',
        sameAs: trustpilotDomain,
      },
      publisher: {
        name: 'Trustpilot',
        '@type': 'Organization',
        sameAs: trustpilotDomain,
      },
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue,
      },
    },
  };

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
    >
      {acf.trustpilot.showRatingSchema === 'enabled' && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(trustpilotStructuredData) }}
        />
      )}
      {acf.title && (
      <Title>
        {acf.title}
      </Title>
      )}
      {acf.subtitle && (
      <Subtitle>
        {acf.subtitle}
      </Subtitle>
      )}
      <Wrapper
        acfTrustpilot={acf.trustpilot}
      />
    </SectionWrapper>
  );
};

TrustpilotSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    trustpilot: PropTypes.shape({
      stars: PropTypes.arrayOf(PropTypes.string).isRequired,
      showRatingSchema: PropTypes.string,
      productName: PropTypes.string,
    }),
    sectionSettings: PropTypes.shape(),
  }).isRequired,
};

export default TrustpilotSection;
