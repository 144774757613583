import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import devices from '../../../js/styles/Devices';

const Wrapper = styled.div`
  && {
    padding-top: 1.5rem;
    text-align: center;
    font-size: .8rem;
    font-weight: normal;
  }
`;

const Input = styled.input`
  && {
    font-family: 'BeVietnamPro', sans-serif;
    width: 90%;
    padding: 0.5rem;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    border-width: 1px;

    &:focus {
      outline: 0;
    }

    @media ${devices.upFromSmallTablet} {
      width: 55%;
    }
  }
`;

const Textarea = styled.textarea`
  && {
    font-family: 'BeVietnamPro', sans-serif;
    width: 90%;
    padding: .5rem;
    border-radius: 4px;

    &:focus {
      outline: 0;
    }

    @media ${devices.upFromSmallTablet} {
      width: 55%;
    }
  }
`;

const Disclaimer = styled.p`
  && {
    font-family: 'BeVietnamPro', sans-serif;
    text-align: center;
    font-size: .7rem;
    margin: 0 2rem 0 2rem;
  }
`;

const Button = styled.button`
  && {
    font-family: 'BeVietnamPro', sans-serif;
    width: 90%;
    background: #008539;
    color: #fff;
    box-shadow: 0 6px 7px -3px rgba(0,0,0,0.2);
    height: 50px;
    border-radius: 4px;
    border: 1px solid #dadada;
    cursor: pointer;

    @media ${devices.upFromSmallTablet} {
      width: 55%;
    }
  }
`;

const StipendiumFormBlock = ({
  namePlaceholder,
  emailPlaceholder,
  schoolPlaceholder,
  messagePlaceholder,
  country,
  reDirectUrl,
  stipendiumDisclaimer,
  buttonLabel,
}) => (
  <Wrapper>
    <form method="POST" action="https://formspree.io/xbzvwebx">
      <Input type="text" name="message" placeholder={namePlaceholder} />
      <Input type="text" name="email" placeholder={emailPlaceholder} />
      <Input type="text" name="message" placeholder={schoolPlaceholder} />
      <Textarea name="message" placeholder={messagePlaceholder} rows="10" />
      <Disclaimer>
        {stipendiumDisclaimer}
      </Disclaimer>
      <input type="hidden" name="_next" value={reDirectUrl} />
      <input type="hidden" name="_language" value={country} />
      <Button type="submit">{buttonLabel}</Button>
    </form>
  </Wrapper>

);

StipendiumFormBlock.propTypes = {
  namePlaceholder: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  schoolPlaceholder: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  country: PropTypes.string,
  reDirectUrl: PropTypes.string,
  stipendiumDisclaimer: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
};

StipendiumFormBlock.defaultProps = {
  namePlaceholder: '',
  emailPlaceholder: '',
  schoolPlaceholder: '',
  messagePlaceholder: '',
  country: '',
  reDirectUrl: '',
  stipendiumDisclaimer: '',
};

export default StipendiumFormBlock;
