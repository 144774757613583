/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import OptionsContext from '../../context/options';
import replaceAcf from './withACFVariables';

const withOptions = (Component) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { initialLang: lang } = props.pageContext;

  const { allWpOptionPages } = useStaticQuery(graphql`
    query {
      allWpOptionPages  {
        edges {
          node {
            ...optionPages
            locale {
              locale
            }
          }
        }
      }
    }
  `);

  const options = useMemo(() => allWpOptionPages.edges.find(
    (edge) => edge.node.locale.locale === lang)?.node || {}, [allWpOptionPages.edges, lang]);

  let newAcf = [];
  const acfGroups = props.data.wpPage?.acf?.acfGroups || props.data.wpPost?.acf?.acfGroups || null;
  if (acfGroups !== null) {
    newAcf = useMemo(() => replaceAcf(
      acfGroups, options), [acfGroups, options]);
  }

  return (
    <OptionsContext.Provider value={options}>
      <Component {...props} acfGroups={newAcf} value={options} />
    </OptionsContext.Provider>
  );
};

export default withOptions;
