import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import devices from '../../../js/styles/Devices';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import localeData from '../../../js/localizations/locale-data';

const ProductBlockWrapper = styled.div`
  && {
    text-align: center;
    margin-top: ${(props) => (props.text ? '0' : '1rem')};
  }
`;

const TextBlock = styled.div`
  && {
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4em 0;

        &::before {
          color: var(--teal);
          content: '\2022';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }
    }

    table {
      border-collapse: collapse;
      max-width: 100%;

      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    tr {
      &:nth-child(odd) {
        background: var(--lightest-grey);
      }
    }

    td {
      padding: 0.5rem 0.8rem;
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

const ImageWrapper = styled.div`
  && {
    position: relative;
    max-width: calc(100% + 30px);
    margin-bottom: 1rem;
    margin-left: -1rem;

    @media ${devices.upFromTablet} {
      width: 100%;
      margin-left: 0;
    }
  }
`;

const Image = styled.img`
  && {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Cost = styled.span`
  && {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, var(--black) 0%, transparent 100%);
    color: var(--white);
    font-size: 1.3rem;
    line-height: 3rem;
    padding: 0 1rem;
  }
`;

const PrefilledApplicationProductBlock = ({
  text,
  queryParams,
}) => {
  const {
    objTitle,
    objImg,
    objCost,
  } = queryParams;

  return (
    <ProductBlockWrapper>
      {objTitle && (
        <h3>{objTitle}</h3>
      )}

      {text && (
        <TextBlock
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      )}

      <ImageWrapper>
        {objImg && (
          <Image
            src={objImg}
            alt={objTitle || ''}
          />
        )}

        {objCost && (
          <Cost>
            <FormattedMessage
              id="prefilledApplication.partnerProduct.price"
              defaultMessage="Price: <strong>{price, number, currency}</strong>"
              description="Text for price on prefilled application partner product"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                price: objCost,
                currency: localeData.CURRENCY,
              }}
            />
          </Cost>
        )}
      </ImageWrapper>
    </ProductBlockWrapper>
  );
};

PrefilledApplicationProductBlock.propTypes = {
  text: PropTypes.string,
  queryParams: PropTypes.shape({
    objTitle: PropTypes.string,
    objImg: PropTypes.string,
    objCost: PropTypes.string,
  }),
};

PrefilledApplicationProductBlock.defaultProps = {
  text: '',
  queryParams: {},
};

export default PrefilledApplicationProductBlock;

export const prefilledApplicationProductBlockQuery = graphql`
  fragment prefilledApplicationProductBlockFields on PrefilledApplicationProduct {
    text
  }
`;
