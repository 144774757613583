// This is a temporary component to handle specific SEO requirement
import { graphql } from 'gatsby';
// This Block follows the structure for Sections and Blocks
// and returns null since props are used in Seo > Helmet element
const HreflangBlock = () => null;

export default HreflangBlock;

export const hreflangBlockQuery = graphql`
  fragment hreflangBlockFields on Hreflang {
    language
    page
  }
`;
