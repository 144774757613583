import React from 'react';
import PropTypes from 'prop-types';

import { useFloatingForm } from '../../../context/floating-form';

const ExtraFormFunctionalityWrapper = ({ children, registerForm }) => {
  const { forwardRef } = useFloatingForm();

  if (!registerForm) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <div ref={forwardRef}>
      {children}
    </div>
  );
};

ExtraFormFunctionalityWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  registerForm: PropTypes.shape(),
};

ExtraFormFunctionalityWrapper.defaultProps = {
  registerForm: {},
};

export default ExtraFormFunctionalityWrapper;
