const replaceACFVariables = (text, variables = {}) => (
  text.replace(/\[acf field=(?:"|&quot;)(\w+).*?(?:"|&quot;)]/g, (_, p1) => {
    const matches = p1.split('_');
    const prop = matches.shift();
    let value = variables[prop];
    while (value != null && matches.length) {
      value = value[matches.shift()];
    }
    return value;
  })
);

export default replaceACFVariables;
