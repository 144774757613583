import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';

import useEffectOnce from '../../../js/hooks/useEffectOnce';
import { getFontFamily, getTitleFontFamily } from '../../../js/utils/sectionHelper';

import SectionWrapper from '../../common/SectionWrapper';

import validateUnsubPhoneNumber from '../../../js/validators/SE/UnsubSMSValidation';
import validatePhoneNumberFi from '../../../js/validators/FI/phoneNumber';
import validatePhoneNumberNo from '../../../js/validators/NO/phoneNumber';
import { useUnsubscribeFormSmsMutation } from '../../../js/store/reducers/api-call';

import { brand, region } from '../../../js/localizations/current-locale';
import sizes from '../../../js/styles/Sizes';
import devices from '../../../js/styles/Devices';
import style from './style';

const Wrapper = styled(SectionWrapper)`
  && {
    padding: 1rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    text-align: center;
    margin-top: 5rem;
  }
`;

const Container = styled.div`
  && {
    background-color: var(--link);
    padding: 2rem;
    margin-top: 2rem;
    max-width: 40rem;
    margin: 2rem auto 0;
  }
`;

const Heading = styled.h1`
  && {
    font-size: var(--h1-desktop-font-size);
    font-family: var(--title-font-family);
    letter-spacing: var(--has-letter-spacing);
    -webkit-font-smoothing: antialiased;

    @media ${devices.downFromTablet} {
      font-size: var(--h1-mobile-font-size);
    }
  }
`;

const Input = styled.input`
  && {
    width: 75%;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.borderColor} !important;
    outline-color: ${(props) => props.borderColor} !important;
    border-radius: ${sizes.defaultBorderRadius};
    padding: 0.75rem;
    text-align: center;
    margin-top: 0.5rem;
    font-family: var(--font-family);

    :focus-visible {
      border-color: ${(props) => props.borderColor} !important;
      outline-color: ${(props) => props.borderColor} !important;
    }
  }
`;

const Description = styled.span`
  && {
    font-family: var(--font-family);
  }
`;

const RedLine = styled.div`
  && {
    margin: 0 auto;
    width: 110px;
    height: 3px;
    background-color: #ff3926;
    margin-bottom: 1rem;
    -webkit-animation: enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation: enter .5s cubic-bezier(.25,.46,.45,.94) both;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
`;

const Button = styled.button`
  && {
    margin: 2em auto 0 auto;
    border: 1px solid var(--border-grey);
    width: 100%;
    font-family: var(--font-family);
    font-size: var(--text-font-size);
    font-weight: var(--has-font-weight);
    border-radius: ${sizes.defaultBorderRadius};
    cursor: pointer;
    color: var(--white);
    background: ${(props) => ((props.disabled) ? style.backgroundDisabled : props.backgroundEnabled)} !important;
    border-color: ${(props) => ((props.disabled) ? style.borderColorDisabled : '')};
    pointer-events: ${(props) => ((props.disabled) ? 'none' : '')};
  }
`;

const UnsubscribeSms = ({
  acf,
}) => {
  const [sms, setSms] = useState();
  const [search, setSearchParam] = useState('');
  const [onUnsubscribeFormSms, {
    isLoading: submitting,
    isSuccess: submitSuccess,
    isError: submitFailed,
  }] = useUnsubscribeFormSmsMutation();

  useEffectOnce(() => {
    setSearchParam(window.location.search);
  });

  let validate;

  switch (region.toUpperCase()) {
    case 'FI':
      validate = validatePhoneNumberFi;
      break;
    case 'NO':
      validate = validatePhoneNumberNo;
      break;
    case 'SE':
      validate = validateUnsubPhoneNumber;
      break;
    default:
      validate = validateUnsubPhoneNumber;
  }

  const queryParams = {
    ...(queryString.parse(search || '')),
  };

  const { cID } = queryParams;

/* eslint-disable */
  const handleClick = (sms, cID) => {
    onUnsubscribeFormSms({sms, cID});
  };
  /* eslint-disable */

  const handleChange = (e) => {
    const updatedSms = e.target.value;
    setSms(updatedSms);
  };

  useEffect(() => {
    if (submitFailed) {
      window.location.href = '/unsubscribe-failed';
    } else if (submitSuccess) {
      window.location.href = '/unsubscribe-success';
    }
  }, [submitSuccess, submitFailed])


  const SubmitButton = () => {
    if (validate(sms)) {
      return (
        <Button
          disabled
          backgroundEnabled={style.backgroundEnabledValidatedSMS}
          fontFamily={getFontFamily(acf.sectionSettings.fontFamily[0])}
        >
            {acf.submitButtonText}
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => handleClick(sms, cID)}
          disabled={submitting}
          backgroundEnabled={style.backgroundEnabled}
          fontFamily={getFontFamily(acf.sectionSettings.fontFamily[0])}
        >
          {acf.submitButtonText}
        </Button>
      )
    }
  }

  return (acf &&
    <Wrapper
      settings={acf.sectionSettings}
    >
      <Container>
        {acf.title && (
          <Heading
          fontFamily={getTitleFontFamily(acf.sectionSettings.fontFamily[0])}
          >
            {acf.title}
          </Heading>
        )}
        {brand === 'sambla' && (
          <RedLine />
        )}
        {acf.description &&
          <Description
            fontFamily={getFontFamily(acf.sectionSettings.fontFamily[0])}
          >
            {acf.description}
          </Description>
        }
        <div
          data-tooltip={acf.tooltip}
          data-tooltip-trigger="hover"
          data-tooltip-width="medium"
          data-tooltip-position="top"
        >
          <Input
            type="text"
            placeholder={acf.inputPlaceholder ? acf.inputPlaceholder : ''}
            onChange={handleChange}
            fontFamily={getFontFamily(acf.sectionSettings.fontFamily[0])}
            borderColor={!validate(sms) ?  'var(--green)': 'var(--off-white)'}
          />
        </div>
        <SubmitButton />
      </Container>
    </Wrapper>
  );
};

UnsubscribeSms.propTypes = {
  onUnsubscribeFormSms: PropTypes.func,
  submitting: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  acf: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    tooltip: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string,
    submitButtonText: PropTypes.string.isRequired,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
};

UnsubscribeSms.defaultProps = {
  onUnsubscribeFormSms: () => {},
  submitting: false,
  submitSuccess: null,
};

export default UnsubscribeSms;
