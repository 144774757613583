// This is a temporary component to handle specific SEO requirement
import React from 'react';
import PropTypes from 'prop-types';

import HreflangBlock from '../../blocks/HreflangBlock';

const HREFLANGSection = ({
  acf,
}) => <HreflangBlock acf={acf} />;

HREFLANGSection.propTypes = {
  acf: PropTypes.shape({
  }).isRequired,
};

export default HREFLANGSection;
